import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { ERROR_MESSAGE, PROGRESS_ERROR_STATE } from './utility/constants';
import UploadStatus from './UploadStatus';
import FilePreview from './FilePreview';
import { UploadFileInfo, RealTimeFileUploadItemProps } from './types';

const RealTimeFileUploadItem = (props: RealTimeFileUploadItemProps) => {
	const { file, chunkSize = 1000000, onUpload, errorMessage = ERROR_MESSAGE.generic, preview } = props;
	const chunkSizeRef = React.useRef(chunkSize);
	const [progress, setProgress] = React.useState(0);

	React.useEffect(() => {
		async function uploadFile(currentFile: File) {
			if (currentFile.size > 0) {
				const fileSize = currentFile.size;
				const totalChunks = fileSize % chunkSizeRef.current === 0 ? fileSize / chunkSizeRef.current : Math.floor(fileSize / chunkSizeRef.current) + 1;
				for (let i = 0; i < totalChunks; i++) {
					const startChunk = chunkSizeRef.current * i;
					const calculatedEndChunk = startChunk + chunkSizeRef.current;
					const endChunk = calculatedEndChunk >= fileSize ? fileSize : calculatedEndChunk;

					const fileInfo: UploadFileInfo = {
						file: currentFile,
						chunk: {
							data: currentFile.slice(startChunk, endChunk),
							start: startChunk,
							end: endChunk - 1,
							currentChunk: i + 1,
							totalChunks
						},
						headers: {
							'Content-Disposition': `attachment; filename="${currentFile.name}"`,
							'Content-Range': `bytes ${startChunk}-${endChunk - 1}/${file.size}`
						}
					};

					await onUpload(fileInfo)
						.then(() => {
							if (fileInfo.chunk.currentChunk === fileInfo.chunk.totalChunks) {
								setProgress(100);
							} else {
								setProgress(Math.floor((i / totalChunks) * 100));
							}
						})
						.catch(() => {
							setProgress(PROGRESS_ERROR_STATE);
						});
				}
			}
		}
		uploadFile(file).catch(() => {
			/* ignore */
		});
	}, [file, chunkSizeRef, onUpload]);

	return (
		<ListGroup.Item key={file.name}>
			<div className="clearfix">
				{preview ? (
					<div className="me-2 text-center float-start" style={{ width: '100px' }}>
						<FilePreview file={file} />
					</div>
				) : null}
				<span>{file.name}</span>
				<UploadStatus progress={progress} errorMessage={errorMessage} />
			</div>
		</ListGroup.Item>
	);
};
RealTimeFileUploadItem.displayName = 'RealTimeFileUploadItem';

export default RealTimeFileUploadItem;
