import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import * as services from '../services';
import { Translation, TranslationApi } from '../utility/types';

const T10S_DEFAULT_STATE = services.t10s();

export const TranslationContext = React.createContext<TranslationApi | undefined>(undefined);
TranslationContext.displayName = 'TranslationContext';

export type TranslationProviderProps = { translations?: Translation };

const TranslationProvider = ({ translations, children }: PropsWithChildren<TranslationProviderProps>) => {
	const latest = useRef(translations);
	const [t10s, setT10s] = useState<TranslationApi>(T10S_DEFAULT_STATE);

	useEffect(() => {
		if (typeof latest.current === 'undefined') {
			// allows us to be more flexible in using components that might be wrapped with translations
			return;
		}

		services.api
			.get(latest.current)
			.then(response => {
				const contentType = response.headers['content-type'];
				if (contentType && contentType.indexOf('application/json') !== -1) {
					const data = response.data as { t10s?: Record<string, string> };
					setT10s(services.t10s(data.t10s));
				}
			})
			.catch(() => {
				// ignore
			});
	}, [latest]);

	const { Provider } = TranslationContext;

	return <Provider value={t10s}>{children}</Provider>;
};

export default TranslationProvider;
