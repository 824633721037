import { useCallback } from 'react';
import useFormGroupValidation from './useFormGroupValidation';
import { UseFieldsetValidationProps, UseFormGroupValidationProps } from '../types/hooks';

export const useFieldsetValidation = <TFieldValue = string, URef extends HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement = HTMLInputElement>(
	props: UseFieldsetValidationProps<TFieldValue, URef>
) => {
	const { getGroupProps, getLabelProps, getInputProps, getHelpblockProps, inputRef, validation } = useFormGroupValidation(
		props as UseFormGroupValidationProps<TFieldValue, URef>
	);

	const getFieldsetProps = useCallback(
		(overrideProps = {}) => {
			const groupProps = getGroupProps(overrideProps);
			const { 'aria-describedby': ariaDescribedBy } = getInputProps();
			return {
				as: 'fieldset',
				'aria-describedby': ariaDescribedBy,
				...groupProps
			};
		},
		[getGroupProps, getInputProps]
	);

	const getLegendProps = useCallback(
		(overrideProps = {}) => {
			const labelProps = getLabelProps(overrideProps);
			return {
				as: 'legend',
				className: validation.isInvalid ? 'is-invalid' : null,
				...labelProps
			};
		},
		[getLabelProps, validation]
	);

	return {
		getGroupProps: getFieldsetProps,
		getLabelProps: getLegendProps,
		getHelpblockProps,
		inputRef,
		validation
	};
};
