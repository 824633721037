import { DatatableProps } from '../types';

interface ProcessPropsData {
	enabled: boolean;
	props: Record<string, unknown>;
}

type TupleOfSameLengthArrays = [Array<number>, Array<string | number>];
interface ProcessLengthMenuPropsData {
	menuOptions: TupleOfSameLengthArrays;
	props: Record<string, unknown>;
	pageSize: number;
}

export function processGlobalFilterProps(initialProp?: Record<string, unknown> | boolean): ProcessPropsData {
	const enabled = !!(initialProp === undefined || initialProp);
	const props = initialProp && typeof initialProp === 'object' ? initialProp : {};

	return {
		enabled,
		props
	};
}

export function processPaginationProps(initialProp?: Record<string, unknown> | boolean): ProcessPropsData {
	const enabled = !!(initialProp === undefined || initialProp);
	const props = initialProp && typeof initialProp === 'object' ? initialProp : {};

	return {
		enabled,
		props
	};
}

export const defaultMenuOptions: TupleOfSameLengthArrays = [
	[25, 50, 75, 100, -1],
	[25, 50, 75, 100, 'All']
];

export function processLengthMenuProps(initialProp?: DatatableProps['lengthMenu'], hiddenPagination = false): ProcessLengthMenuPropsData {
	const initialPropIsObject = initialProp && typeof initialProp === 'object' && !Array.isArray(initialProp);
	const initialPropIsArray = Array.isArray(initialProp);

	if (hiddenPagination) {
		return {
			menuOptions: [[-1], ['all']], // force all rows to display
			pageSize: -1,
			props: {}
		};
	}

	if (initialPropIsObject) {
		const { menuOptions: menuOptionsFromProps, pageSize: pageSizeFromProps, ...props } = initialProp;

		const menuOptions = menuOptionsFromProps ?? defaultMenuOptions;
		const pageSize = pageSizeFromProps ?? menuOptions[0][0];

		return {
			menuOptions,
			pageSize,
			props
		};
	}

	return {
		menuOptions: initialPropIsArray ? initialProp : defaultMenuOptions,
		pageSize: (initialPropIsArray ? initialProp : defaultMenuOptions)[0][0],
		props: {}
	};
}
