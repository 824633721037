import { RefObject, useEffect, useState } from 'react';

const intersectionOptions: IntersectionObserverInit = {
	root: null,
	rootMargin: '0px',
	threshold: [0, 0.5, 1]
};

export const useIntersectionObserver = (ref: RefObject<Element>): boolean => {
	const target = ref.current;

	const [isIntersecting, setIsIntersecting] = useState<boolean>(false);

	useEffect(() => {
		if (!target) return;

		const handleIntersect: IntersectionObserverCallback = (entries, observer) => {
			entries.forEach(entry => {
				if ((entry.isIntersecting && !isIntersecting) || (!entry.isIntersecting && isIntersecting)) {
					setIsIntersecting(entry.isIntersecting);
				}
			});
		};

		const observer = new IntersectionObserver(handleIntersect, intersectionOptions);
		observer.observe(target);

		return () => {
			observer.disconnect();
		};
	}, [isIntersecting, target]);

	return isIntersecting;
};
