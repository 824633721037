import { useCallback, useEffect, useState } from 'react';
import { UseKeyboardNavigationProps } from '../types';
import { Keys } from '../../../../helpers/keyboardKeys';

export const useKeyboardNavigation = ({ itemCount, initialSelectedIndex, onKeyDown }: UseKeyboardNavigationProps) => {
	const [selectedIndex, setSelectedIndex] = useState<number>(initialSelectedIndex);
	const [lastKnownIndex, setLastKnownIndex] = useState<number | null>(null);
	const [lastItemReached, setLastItemReached] = useState<boolean>(false);

	const handleKeyDown = useCallback(
		(event: KeyboardEvent) => {
			let newIndex: number | null = null;
			if (event.key === Keys.ArrowDown.key) {
				if (selectedIndex === -1) {
					// Start from lastKnownIndex if it exists, otherwise start from the top
					newIndex = lastKnownIndex !== null ? lastKnownIndex : 0;
				} else {
					newIndex = Math.min(selectedIndex + 1, itemCount - 1);
				}
			} else if (event.key === Keys.ArrowUp.key) {
				if (selectedIndex === -1) {
					// Start from lastKnownIndex if it exists, otherwise start from the bottom
					newIndex = lastKnownIndex !== null ? lastKnownIndex : itemCount - 1;
				} else {
					newIndex = Math.max(selectedIndex - 1, 0);
				}
			}
			if (newIndex !== null) {
				setLastItemReached(newIndex >= itemCount - 1);
				setSelectedIndex(newIndex);
				onKeyDown?.(newIndex);
			}
		},
		[itemCount, onKeyDown, selectedIndex, lastKnownIndex]
	);

	useEffect(() => {
		if (selectedIndex !== -1) {
			setLastKnownIndex(selectedIndex);
		}
	}, [selectedIndex]);

	useEffect(() => {
		window.addEventListener('keydown', handleKeyDown);
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, [handleKeyDown]);

	return {
		selectedIndex,
		lastItemReached,
		updateSelectedIndex: setSelectedIndex
	};
};
