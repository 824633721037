import PropTypes from 'prop-types';
import { ArgTypes } from '@storybook/types';
import { FormGroupProps } from '../components/forms/form-group/types';
import { FromControlStaticProps } from '../components/forms/form-control/types';
import { FieldProps } from '../components';

export const fieldDefaultProps = {
	required: false,
	disabled: false,
	dirty: false,
	readOnly: false
};

export const staticFormControlTypes = {
	tooltip: '',
	label: 'Label',
	helptext: '',
	value: 'Value',
	inline: false
};

export const fieldPropTypes = {
	id: PropTypes.string,
	name: PropTypes.string,
	required: PropTypes.bool,
	disabled: PropTypes.bool,
	readOnly: PropTypes.bool,
	tooltip: PropTypes.string,
	label: PropTypes.node,
	helptext: PropTypes.string,
	dirty: PropTypes.bool
};

export const getStoryBookFormGroupArgTypes = (): ArgTypes<FormGroupProps> => {
	return {
		as: {
			description: 'You can use a custom element type for this component.',
			table: {
				type: {
					summary: 'elementType'
				}
			},
			control: {
				type: 'text'
			}
		},
		controlId: {
			description: 'Sets id on <FormControl> and htmlFor on <Label>.',
			table: {
				type: {
					summary: 'string'
				}
			},
			control: {
				type: 'text'
			}
		}
	};
};

export const getStorybookStaticFormControlTypes = (): ArgTypes<FromControlStaticProps> => {
	return {
		tooltip: {
			description: 'tooltip text',
			type: { name: 'string' },
			table: {
				type: {
					summary: 'string'
				}
			},
			control: { type: 'text' }
		},
		label: {
			description: 'static label',
			table: {
				type: {
					summary: 'node'
				}
			},
			control: { type: 'text' }
		},
		value: {
			description: 'static value',
			table: {
				type: {
					summary: 'node'
				}
			},
			control: { type: 'text' }
		},
		helptext: {
			description: 'help text',
			type: { name: 'string' },
			table: {
				type: {
					summary: 'string'
				}
			},
			control: { type: 'text' }
		},
		inline: {
			description: 'indicates if the form label and value should display inline',
			type: { name: 'boolean' },
			table: {
				type: {
					summary: 'boolean'
				}
			},
			control: { type: 'boolean' }
		},
		children: {
			description: 'alternative static value',
			table: {
				type: {
					summary: 'node'
				}
			}
		}
	};
};

export const getStorybookFieldArgTypes = (): ArgTypes<FieldProps & React.ComponentProps<'input'>> => {
	return {
		id: {
			description: 'HTML id attribute.  Required for Accessibility',
			type: { name: 'string' },
			table: {
				type: {
					summary: 'string'
				}
			},
			control: { type: 'text' }
		},
		name: {
			description: 'HTML name attribute.  Required for validation',
			type: { name: 'string' },
			table: {
				type: {
					summary: 'string'
				}
			},
			control: { type: 'text' }
		},
		groupClassName: {
			description: 'ClassName of group.',
			type: { name: 'string' },
			table: {
				type: {
					summary: 'string'
				}
			},
			control: { type: 'text' }
		},
		required: {
			description: 'indicates if field is required',
			type: { name: 'boolean' },
			table: {
				type: {
					summary: 'boolean'
				},
				defaultValue: { summary: fieldDefaultProps.required }
			},
			control: { type: 'boolean' }
		},
		disabled: {
			description: 'indicates if field is disabled',
			type: { name: 'boolean' },
			table: {
				type: {
					summary: 'boolean'
				},
				defaultValue: { summary: fieldDefaultProps.disabled }
			},
			control: { type: 'boolean' }
		},
		placeholder: {
			description: 'placeholder text',
			type: { name: 'string' },
			table: {
				type: {
					summary: 'string'
				}
			},
			control: { type: 'text' }
		},
		readOnly: {
			description: 'indicates if field is readonly',
			type: { name: 'boolean' },
			table: {
				type: {
					summary: 'boolean'
				},
				defaultValue: { summary: fieldDefaultProps.readOnly }
			},
			control: { type: 'boolean' }
		},
		tooltip: {
			description: 'tooltip text',
			type: { name: 'string' },
			table: {
				type: {
					summary: 'string'
				}
			},
			control: { type: 'text' }
		},
		label: {
			description: 'input label',
			table: {
				type: {
					summary: 'node'
				}
			},
			control: { type: 'text' }
		},
		helptext: {
			description: 'help text',
			type: { name: 'string' },
			table: {
				type: {
					summary: 'string'
				}
			},
			control: { type: 'text' }
		},
		dirty: {
			description: "true/false indicator to determine if the input has changed outside of it's initial rendering",
			type: { name: 'boolean' },
			table: {
				type: {
					summary: 'boolean'
				},
				defaultValue: { summary: fieldDefaultProps.dirty }
			},
			control: { type: 'boolean' }
		}
	};
};
