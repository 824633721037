import React, { useState, useEffect } from 'react';

import { AlertProps } from './types';
import { useAlert } from './hooks';
import { useElementIds } from '../../helpers/hooks/useElementIds';

/**
 * <em>only shows when there are message(s) available.</em>
 */

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
	(
		{
			heading,
			description,
			messages = [],
			iconVariant = false,
			condensed = false,
			variant = 'primary',
			className,
			dismissible,
			closeLabel = 'Close alert',
			closeVariant,
			onClose,
			id,
			...props
		},
		ref
	) => {
		const [showing, setShowing] = useState(messages.length > 0);

		const { id: innerId } = useElementIds({ prefix: 'alert', id });

		const alertClasses = [
			className,
			condensed && 'alert-condensed',
			variant && `alert-${variant}`,
			showing && 'show',
			dismissible && 'alert-dismissible',
			'fade alert'
		]
			.filter(Boolean)
			.join(' ');

		const { iconProps, icon: AlertIcon } = useAlert({ variant, condensed });

		const flexClasses = [iconVariant && 'd-flex'].filter(Boolean).join('');
		const listClasses = [messages.length === 1 && 'list-unstyled'].filter(Boolean).join(' ');
		const btnClasses = [closeVariant && `btn-close-${closeVariant}`, 'btn-close'].filter(Boolean).join(' ');

		const handleClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			setShowing(false);
			if (onClose) {
				onClose(e);
			}
		};

		useEffect(() => {
			const hasMessages = messages.length > 0;
			if (showing && !hasMessages) {
				setShowing(false);
			} else if (!showing && hasMessages && !dismissible) {
				setShowing(true);
			}
		}, [showing, messages, dismissible]);

		if (!showing) {
			return null;
		}

		return (
			<div id={innerId} role="alert" ref={ref} className={alertClasses} tabIndex={-1} {...props}>
				<div className={flexClasses}>
					{AlertIcon && (
						<div className="alert-icon">
							<AlertIcon {...iconProps} />
						</div>
					)}
					<div className="alert-content">
						{!condensed && <h2 className="alert-title">{heading}</h2>}
						{description && <p>{description}</p>}
						<ul className={listClasses}>
							{messages.map((message, index) => (
								<li key={index}>{message}</li>
							))}
						</ul>
						{props.children}
					</div>
					{dismissible && <button onClick={handleClose} className={btnClasses} aria-label={closeLabel}></button>}
				</div>
			</div>
		);
	}
);
Alert.displayName = 'Alert';

export default Alert;
