import React from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import ReadView from './ReadView';
import ActionButtons from './ActionButtons';
import { useInlineEdit } from './hooks/useInlineEdit';
import { Helpblock, Label } from '../utility';
import { cloneChildrenWithProps, InlineHelper } from '../../helpers';
import { REQUIRED_FIELD_MESSAGE } from '../forms/shared/FormGroupValidation';
import { ActionButtonsProps, InlineEditProps } from './types';

import { FormControlFeedback } from '../forms/form-control';

const InlineEdit = (inProps: InlineEditProps) => {
	const {
		hideActionButtons = false,
		overlay = false,
		inline = true,
		requiredFieldMessage = REQUIRED_FIELD_MESSAGE,
		suppressInputValidationMessage = true,

		customReadView,
		children,
		...props
	} = inProps;

	const useInlineEditProps = useInlineEdit({
		inline,
		overlay,
		hideActionButtons,
		requiredFieldMessage,
		suppressInputValidationMessage,
		...props
	});

	const { isEdit, isInvalid, getInlineEditProps, getFieldProps, getReadViewProps, getActionButtonProps } = useInlineEditProps;
	const inlineEditProps = getInlineEditProps();
	const fieldProps = getFieldProps();
	const readViewProps = getReadViewProps();

	const isRequired = props.disabled ? false : props.required;
	const showValidationMessage = isRequired || (fieldProps?.validators?.length ?? 0) > 0;

	const renderActionButtons = (actionButtonProps?: ActionButtonsProps): JSX.Element | null => {
		return hideActionButtons ? null : <ActionButtons {...getActionButtonProps(actionButtonProps)} />;
	};

	const renderEditView = () => {
		let child;
		if (typeof children === 'function') {
			child = children(useInlineEditProps);
		} else {
			child = cloneChildrenWithProps(children, fieldProps);
		}
		const invalidClass = isInvalid ? 'is-invalid' : undefined;
		return overlay ? (
			<Overlay target={readViewProps.ref?.current} show={true} placement="right">
				<Popover id={`${fieldProps.id}-popover`}>
					<Popover.Body>
						{child}
						{renderActionButtons({ style: { position: 'relative' } })}
					</Popover.Body>
				</Popover>
			</Overlay>
		) : (
			<div style={{ position: 'relative' }} className={invalidClass}>
				{child}
				{renderActionButtons()}
			</div>
		);
	};

	const renderReadView = () => {
		return customReadView ? customReadView(useInlineEditProps) : <ReadView {...readViewProps} isInvalid={isInvalid} />;
	};

	return (
		<div ref={inlineEditProps.ref} {...inlineEditProps.containerProps}>
			<Label {...inlineEditProps.labelProps} />
			<InlineHelper className="editable-input" inline={inline}>
				{(!isEdit || overlay) && renderReadView()}

				{isEdit && renderEditView()}

				<Helpblock {...inlineEditProps.helpBlockProps} />

				{showValidationMessage ? (
					<FormControlFeedback type="invalid">{`${props.label || props['aria-label'] || ''} ${requiredFieldMessage}`}</FormControlFeedback>
				) : null}
			</InlineHelper>
		</div>
	);
};

InlineEdit.ActionButtons = ActionButtons;
InlineEdit.ReadView = ReadView;

export default InlineEdit;
