import React, { useEffect, useState, useCallback, useRef } from 'react';
import { applyEventListener, formatDate, useCombinedRefs, Validation } from '../../helpers';
import { IconBack, iconMap, IconNext } from '@optic-delight/icons';
import ReactDatePicker from 'react-datepicker';
import { Button } from '../button';
import Input from './Input';
import { DatePickerHotKeys } from '../../helpers/keyboardKeys';
import { useFormContext } from './form-element';
import { useControlledFocusOnError } from './shared/hooks/useControlledFocusOnError';
import { CustomInputProps, DatePickerAddOnProps, DatePickerProps, ParseDateProps } from './types';

function getDate(offsetDays = 0) {
	const aDate = new Date(Date.now());
	aDate.setDate(aDate.getDate() + offsetDays);
	return aDate;
}

function addMonths(date = getDate(), offsetMonths = 0) {
	const aDate = new Date(date.getTime());
	aDate.setMonth(aDate.getMonth() + offsetMonths);
	return aDate;
}

export function parseDate(inputDate: ParseDateProps) {
	if (!inputDate) {
		return undefined;
	}

	let year: number, month: number, day: number;

	if (typeof inputDate === 'string') {
		const parts = inputDate.split('/');
		year = parseInt(parts[2]);
		month = parseInt(parts[0]) - 1;
		day = parseInt(parts[1]);
	} else if (typeof inputDate === 'number') {
		const dateString = inputDate.toString();

		if (dateString.length !== 8) return undefined; // invalid number format for date

		year = parseInt(dateString.substring(4, 8));
		month = parseInt(dateString.substring(0, 2));
		day = parseInt(dateString.substring(2, 4));
	} else {
		return undefined; // invalid input type
	}

	const date = new Date(year, month, day);

	return isNaN(date.getTime()) ? undefined : date;
}

const DatePickerAddOn = ({ embedded, addOns }: DatePickerAddOnProps) => {
	if (!embedded && addOns) {
		const addOnsArray = Array.isArray(addOns) ? addOns : [addOns];
		return (
			<>
				{addOnsArray.map((addOn, index) => (
					<span key={index} className="input-group-text">
						{addOn}
					</span>
				))}
			</>
		);
	}

	return null;
};

const CustomInput = React.forwardRef<HTMLButtonElement, CustomInputProps>(
	({ embedded, button, startDate, buttonTitle, fieldName, className, ...props }, ref) => {
		const { ref: controlRef } = useControlledFocusOnError<HTMLButtonElement>({ name: fieldName });
		const combinedRef = useCombinedRefs<HTMLButtonElement>(ref, controlRef);

		if (!embedded && button) {
			return (
				<Button ref={combinedRef} className={className} iconDirection="append" icon={iconMap['calendar-grid'].iconName} {...props}>
					<span className="me-2">{startDate ? (formatDate(startDate) as string) : buttonTitle}</span>
				</Button>
			);
		}

		return (
			<Button
				ref={ref}
				icon={iconMap['calendar-grid'].iconName}
				className={[embedded && 'input-group-text', className].filter(Boolean).join(' ')}
				style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
				{...props}>
				<span className="visually-hidden">{`choose date${startDate ? ', selected date is ' + formatDate(startDate, { dateStyle: 'full' }) : ''}`}</span>
			</Button>
		);
	}
);

const DatePicker = React.forwardRef<HTMLInputElement, DatePickerProps>(
	(
		{
			id,
			name,
			label,
			button,
			block,
			tooltip,
			helptext = !button ? 'MM/DD/YYYY' : undefined,
			hideHelpblock,
			defaultValue,
			value,
			onChange,
			onDatePickerChange,
			required = false,
			dirty = false,
			offset = [-100, 0],
			validators = [Validation.date],
			groupClassName = 'mb-3',
			'aria-label': ariaLabel,
			inline,
			buttonTitle = 'Select date',
			'data-testid': dataTestId,
			embedded,
			addOnPrepend,
			addOnAppend,
			disabled = false,
			readOnly = false,
			placeholder,
			excludeDates: excludeDateString,
			minDate: minDateString,
			...props
		},
		ref
	) => {
		const inputHiddenClass = [button && 'd-none'].filter(Boolean).join('');
		let excludeDates: Date[] = [];
		let minDate: Date | undefined = undefined;

		if (excludeDateString) {
			let parsedExcludeDates: (Date | undefined)[] = [];

			if (Array.isArray(excludeDateString)) {
				parsedExcludeDates = excludeDateString.map(date => parseDate(date));
			} else {
				parsedExcludeDates = [parseDate(excludeDateString)];
			}
			excludeDates = parsedExcludeDates.filter(date => date !== undefined) as Date[];
		}

		if (minDateString) {
			minDate = parseDate(minDateString);
		}

		const [startDate, setStartDate] = useState(parseDate((value as ParseDateProps) || (defaultValue as ParseDateProps)));
		const inputRef = useCombinedRefs(useRef(), ref) as unknown as React.MutableRefObject<HTMLInputElement>;
		const formContext = useFormContext();

		const setDate = useCallback(
			(date: Date) => {
				const dateWithLeadingZeros = ('0' + (date.getMonth() + 1)).slice(-2) + '/' + ('0' + date.getDate()).slice(-2) + '/' + date.getFullYear();
				setStartDate(date);
				inputRef.current.value = dateWithLeadingZeros;
				applyEventListener(onDatePickerChange, [inputRef.current]);
			},
			[inputRef, onDatePickerChange]
		);

		const handleChange = useCallback(
			(event: React.ChangeEvent<HTMLInputElement>) => {
				const newDate = parseDate(event.target.value);
				setStartDate(newDate || undefined);
				inputRef.current.value = event.target.value;

				applyEventListener(onChange, [event]);
			},
			[inputRef, onChange]
		);

		const handleBlur = useCallback(
			(event: React.FocusEvent<HTMLInputElement>) => {
				const targetValue = event.target.value;

				if (targetValue === DatePickerHotKeys.today) {
					setDate(getDate());
					event.target.value = inputRef.current.value;
					applyEventListener(onChange, [event]);
				} else if (targetValue === DatePickerHotKeys.previousDay) {
					setDate(getDate(-1));
					event.target.value = inputRef.current.value;
					applyEventListener(onChange, [event]);
				} else if (targetValue === DatePickerHotKeys.nextDay) {
					setDate(getDate(1));
					event.target.value = inputRef.current.value;
					applyEventListener(onChange, [event]);
				} else {
					event.target.value = formatDate(targetValue) as string;
					handleChange(event);
				}

				applyEventListener(props.onBlur, [event]);
			},
			[setDate, inputRef, props.onBlur, onChange, handleChange]
		);
		const isDisabled = formContext?.formState?.isDisabled || disabled;

		// It is necessary so that any change of the controlled datepicker, will change its value
		useEffect(() => {
			if (!defaultValue) {
				setStartDate(parseDate(value as ParseDateProps));
			}
		}, [value, defaultValue]);

		const updatableFormValue = formContext.watch && formContext?.watch(name as string);
		useEffect(() => {
			if (updatableFormValue && typeof updatableFormValue === 'string') {
				setStartDate(parseDate(updatableFormValue));
			}
		}, [updatableFormValue]);

		const isHelpblockHidden = hideHelpblock || !helptext?.length;

		const component = (
			<Input
				ref={inputRef}
				type={button ? 'hidden' : undefined}
				groupClassName={groupClassName}
				id={id}
				name={name}
				label={label}
				aria-label={ariaLabel}
				aria-hidden={button}
				tooltip={tooltip}
				helptext={isHelpblockHidden ? undefined : helptext}
				value={value}
				defaultValue={defaultValue}
				onBlur={handleBlur}
				onChange={handleChange}
				required={required}
				disabled={isDisabled}
				dirty={dirty}
				validators={validators}
				maxLength={10}
				size="sm"
				className={inputHiddenClass}
				data-testid={dataTestId}
				inline={inline}
				embedded={embedded}
				readOnly={readOnly}
				addOnPrepend={embedded ? addOnPrepend : undefined}
				placeholder={placeholder}
				addOnAppend={
					<>
						<DatePickerAddOn embedded={embedded} addOns={addOnPrepend} />
						<ReactDatePicker
							id={id + '_picker'}
							selected={startDate}
							onChange={date => {
								if (date) {
									setDate(date);
								}

								if (formContext?.setValue) {
									formContext.setValue(inputRef.current.name, inputRef.current.value, { shouldValidate: true });
								}
							}}
							onChangeRaw={e => {
								if (!e.target.value) {
									return;
								}
								handleChange(e);
							}}
							preventOpenOnFocus
							dateFormat="MM/dd/yyyy"
							excludeDates={excludeDates}
							minDate={minDate}
							disabled={isDisabled || readOnly}
							customInput={<CustomInput embedded={embedded} button={button} startDate={startDate} buttonTitle={buttonTitle} fieldName={name} />}
							popperPlacement="bottom"
							popperModifiers={[
								{
									name: 'offset',
									enabled: true,
									options: {
										offset: offset
									}
								}
							]}
							renderCustomHeader={({ date, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => {
								const monthYearFormat: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long' };
								return (
									<div className="clearfix">
										<button
											type="button"
											className="btn btn-link float-start previous"
											onClick={decreaseMonth}
											disabled={prevMonthButtonDisabled}
											aria-label={`Previous Month, ${formatDate(addMonths(date, -1), monthYearFormat)}`}>
											<IconBack />
										</button>
										<span className="current-month">{formatDate(date, monthYearFormat) as string}</span>
										<button
											type="button"
											className="btn btn-link float-end next"
											onClick={increaseMonth}
											disabled={nextMonthButtonDisabled}
											aria-label={`Next Month, ${formatDate(addMonths(date, 1), monthYearFormat)}`}>
											<IconNext />
										</button>
									</div>
								);
							}}
							readOnly={readOnly}
							{...props}
						/>
						<DatePickerAddOn embedded={embedded} addOns={addOnAppend} />
					</>
				}
			/>
		);

		return button && block ? <div className="d-grid">{component}</div> : component;
	}
);

CustomInput.displayName = 'CustomInput';
DatePicker.displayName = 'DatePicker';

export default DatePicker;
