/*
 * do NOT add baseUrl as part of the instance defaults.  axios combines baseUrl + url from client.post() with a slash delimiter
 * example "bad" url: ./BenefitSolverView/?page_name=foo
 */
export const BASE_URL = './BenefitSolverView';

export const CSRF_FIELD_NAME = 'CSRF';
export const PAGE_NAME_FIELD_NAME = 'page_name';
export const CSRF_HEADER_NAME = 'X-CSRF';
export const CONTENT_TYPE_HEADER_NAME = 'Content-Type';
export const CLIENT_TIMEOUT = 15000;

export const HTTP_INSTANCE_DEFAULTS = {
	baseURL: `${window.location.origin}/benefits`,
	timeout: CLIENT_TIMEOUT,
	xsrfHeaderName: CSRF_HEADER_NAME,
	headers: {
		[CONTENT_TYPE_HEADER_NAME]: 'application/x-www-form-urlencoded',
		Accept: 'application/json'
	}
};
