import React from 'react';
import { ListOptionProps } from './types';

const OptionItem = React.forwardRef<HTMLLIElement, React.PropsWithChildren<ListOptionProps>>((props: React.PropsWithChildren<ListOptionProps>, ref) => {
	const {
		as: ElComponent = 'li',
		item,
		style,
		highlighted,
		itemProps,
		selected,
		checkbox,
		indeterminate,
		className,
		children,
		isCollapsed,
		leftPaddingBase = 1,
		onGroupToggleClick,
		textTruncation = true,
		...otherInProps
	} = props;

	const level = item?.level ?? 0;

	const itemClasses: string = [
		...(item?.isGroup
			? ['accordion-button gap-1', isCollapsed && 'collapsed', level > 0 && 'nested-group', indeterminate && 'dropdown-item-checkbox-indeterminate']
			: ['dropdown-value']),

		'dropdown-item d-flex align-items-center flex-shrink-0',
		highlighted && 'active',
		selected && 'dropdown-item-selected',
		checkbox !== undefined && 'dropdown-item-checkbox',
		className
	]
		.filter(Boolean)
		.join(' ');

	const itemStyles = React.useMemo((): React.CSSProperties => {
		const nestingOffset = level * 2;
		return {
			...style,
			paddingLeft: `${leftPaddingBase + nestingOffset}rem`
		};
	}, [style, level, leftPaddingBase]);

	const allItemProps = {
		...itemProps,
		title: item?.label,
		className: itemClasses,
		style: itemStyles,
		...otherInProps,
		onClick: item
			? (event: React.MouseEvent<HTMLElement>) => {
					onGroupToggleClick?.(event, item, false);
					itemProps?.onClick?.(event);
			  }
			: undefined,
		'aria-selected': selected
	};

	const toggleClickHandler = {
		onClick: item ? (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => onGroupToggleClick?.(event, item, true) : undefined
	};

	return (
		<ElComponent {...allItemProps} ref={ref}>
			<div className={textTruncation ? 'text-truncate' : 'text-wrap'}>{item?.label ?? children}</div>

			{item?.isGroup ? (
				<>
					({item.childrenCount})
					<div className="dropdown-item-group-toggle position-absolute h-100 end-0 top-0" data-testid="toggle" {...toggleClickHandler} />
				</>
			) : null}
		</ElComponent>
	);
});
OptionItem.displayName = 'OptionItem';

export default OptionItem;
