import React, { Children, useEffect, useRef, useState } from 'react';
import { fieldHasErrors } from '../../../../helpers';
import { UseCheckGroupValidationProps } from '../../types';

export const useCheckGroupValidation = ({ dirty, required, children, name, formState }: UseCheckGroupValidationProps) => {
	const [isInvalid, setIsInvalid] = useState(dirty && required);

	const firstUpdate = useRef(dirty);
	useEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false;
			return;
		}

		let allChildrenValid = true;
		Children.forEach(children, child => {
			if (React.isValidElement(child)) {
				const childName = name || (child.props && child.props.name);
				allChildrenValid = allChildrenValid && !fieldHasErrors(childName, formState?.errors);
			}
		});

		setIsInvalid(!allChildrenValid);
	}, [formState, children, name]);

	return [isInvalid, setIsInvalid] as const;
};
