import { iconPickerActionTypes } from './iconPickerActions';
import { IconPickerActionProps, IconPickerStateProps } from './types';

export function iconPickerReducer(state: IconPickerStateProps, { type, icon, ...action }: Partial<IconPickerActionProps>) {
	switch (type) {
		case iconPickerActionTypes.show:
			return {
				...state,
				show: true
			};
		case iconPickerActionTypes.hide: {
			return {
				...state,
				...action,
				show: false
			};
		}
		case iconPickerActionTypes.select:
			return {
				...state,
				...action,
				show: false,
				selectedIcon: icon
			};
		case iconPickerActionTypes.filter:
			return {
				...state,
				...action,
				currentPage: 1
			};
		case iconPickerActionTypes.navigate: {
			if (action.offset) {
				const nextPage = state.currentPage + action.offset;
				return {
					...state,
					currentPage: nextPage < 1 ? 1 : nextPage > state.totalPages ? state.totalPages : nextPage
				};
			}
			return state;
		}
		default:
			throw new Error(`Unhandled action type: ${type}`);
	}
}
