import { useMultipleSelection, useSelect } from 'downshift';
import { IGroupedListOption, MultiSelectProps, UseSearchableItemsListData } from '../types';
import { selectInputReducerUtils, updateSelectReducerChanges } from '../utility';
import { Dispatch, RefObject, SetStateAction } from 'react';
import { GetValidationData } from '../../shared/types/hooks';

interface UseDownshiftMultiSelectProps<TFieldValue extends number[] | string[]> {
	id?: string;
	isOpen: boolean;
	getSelectedItems: () => IGroupedListOption[];
	selectProps: MultiSelectProps<TFieldValue>;
	searchableListProps: UseSearchableItemsListData;
	downshiftMenuRef: RefObject<HTMLUListElement>;
	validation: GetValidationData<TFieldValue>;
	inputRef: RefObject<HTMLInputElement>;
	setIsDropdownOpen: Dispatch<SetStateAction<boolean>>;
}

export const useDownshiftMultiSelect = <TFieldValue extends number[] | string[]>(props: UseDownshiftMultiSelectProps<TFieldValue>) => {
	const { getSelectedItems, selectProps, searchableListProps, downshiftMenuRef, validation, inputRef, setIsDropdownOpen } = props;
	const { searchRef, setSearchValue, searchValue } = searchableListProps;

	const downshiftMultiSelectProps = useMultipleSelection<string | number>({
		[typeof selectProps.value !== 'undefined' ? 'selectedItems' : 'initialSelectedItems']: searchableListProps.initialSelectedValue as (string | number)[]
	});

	const downshiftProps = useSelect<IGroupedListOption>({
		id: props.id,
		items: searchableListProps.filteredItemsToDisplay || [],
		selectedItem: null,
		isOpen: props.isOpen,
		stateReducer: (state, actionAndChanges) => {
			const { changes, type } = actionAndChanges;

			switch (type) {
				case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
					// without this code downshift don't let us put spaces in our search input,
					// but we use it only if no one item is currently highlighted
					if (state.highlightedIndex === -1 && document.activeElement?.tagName !== 'BUTTON') {
						if (searchRef.current !== document.activeElement) {
							searchRef.current?.focus();
						}
						setSearchValue(searchValue + ' ');
					}

					if (document.activeElement?.tagName === 'BUTTON') {
						downshiftMenuRef.current?.focus();
					}

					return updateSelectReducerChanges(changes, true, state.highlightedIndex);

				case useSelect.stateChangeTypes.MenuKeyDownEnter:
				case useSelect.stateChangeTypes.ItemClick:
					// When the user clicks to button in the list options, the list of options will be focused to make keyboard navigation accessible.
					if (document.activeElement?.tagName === 'BUTTON') {
						downshiftMenuRef.current?.focus();
					}
					return updateSelectReducerChanges(changes, true, state.highlightedIndex);

				case useSelect.stateChangeTypes.MenuKeyDownEscape:
					setIsDropdownOpen(false);
					return selectInputReducerUtils.menuKeydownEscape(changes, searchValue, setSearchValue);

				case useSelect.stateChangeTypes.MenuBlur: {
					if (selectProps.paginationOptions) {
						// When Select has pagination, we don't want to clear searchValue
						return selectInputReducerUtils.menuBlur(changes, searchRef);
					}
					return selectInputReducerUtils.menuBlur(changes, searchRef, setSearchValue);
				}

				case useSelect.stateChangeTypes.ToggleButtonKeyDownCharacter:
					return selectInputReducerUtils.toggleButtonKeydownCharacter(changes, setSearchValue);

				case useSelect.stateChangeTypes.MenuKeyDownCharacter:
					return selectInputReducerUtils.menuKeydownCharacter(changes, searchRef);

				case useSelect.stateChangeTypes.ToggleButtonClick:
					setIsDropdownOpen(prevState => !prevState);
					break;

				case useSelect.stateChangeTypes.MenuKeyDownArrowUp:
				case useSelect.stateChangeTypes.MenuKeyDownArrowDown:
					if (document.activeElement?.tagName === 'BUTTON') {
						(document.activeElement as HTMLButtonElement).blur();
						downshiftMenuRef.current?.focus();
					}
					break;

				case useSelect.stateChangeTypes.ToggleButtonKeyDownArrowUp:
				case useSelect.stateChangeTypes.ToggleButtonKeyDownArrowDown:
					setIsDropdownOpen(true);
			}

			return changes;
		},
		onSelectedItemChange: ({ selectedItem }) => {
			if (selectedItem?.value === undefined || selectedItem.isGroup) return;

			let selectedValues: IGroupedListOption[];
			const hashedValues = Object.fromEntries(downshiftMultiSelectProps.selectedItems.map(item => [item, true]));

			const newFilteredItems = getSelectedItems();

			if (hashedValues[selectedItem.value]) {
				downshiftMultiSelectProps.removeSelectedItem(selectedItem.value);
				selectedValues = newFilteredItems.filter(item => item.value !== selectedItem.value);
			} else {
				downshiftMultiSelectProps.addSelectedItem(selectedItem.value);
				selectedValues = [...newFilteredItems, selectedItem];
			}

			validation.setIsInvalid(validation.isRequired && selectedValues.length === 0);

			const element = inputRef?.current;

			const setHiddenInputValue = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')?.set;
			setHiddenInputValue?.call(element, selectedValues.map(item => item.value).join(','));

			const event = new Event('input', { bubbles: true });
			element?.dispatchEvent(event);
		}
	});

	return {
		downshiftProps,
		downshiftMultiSelectProps
	};
};
