import React from 'react';
import NavTab from './NavTab';
import { Nav } from '../navigation';
import { NavProps } from '../navigation/types';

const NavTabs = (props: NavProps) => {
	return <Nav className="nav-tabs" {...props} />;
};

NavTabs.Tab = NavTab;
export default NavTabs;
