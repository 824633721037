import React from 'react';
import DropdownItem from 'react-bootstrap/DropdownItem';
import useNavigation from '../hooks/useNavigation';
import { useActiveItem } from '../hooks/useActiveItem';
import { useElementIds } from '../../../helpers/hooks/useElementIds';
import { NavigationDropdownItemProps } from '../types';

export function NavigationDropdownItem<As extends React.ElementType>({
	as: Component,
	eventKey,
	onClick,
	href,
	id,
	...props
}: NavigationDropdownItemProps<As>) {
	const { onSelect, activeKey, setActiveContent } = useNavigation();
	const { isActive, getLinkProps, eventKey: itemKey } = useActiveItem({ eventKey, href, activeKey, setActiveContent });
	const { id: innerId } = useElementIds({ prefix: 'navigation-dropdown-item', id });

	const isRouteNavigation = Object.prototype.hasOwnProperty.call(props, 'to');

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		onSelect(itemKey, event);
		onClick?.(event);
	};

	return (
		<li>
			<DropdownItem
				as={Component}
				id={innerId}
				className={!isRouteNavigation && isActive() ? 'active' : ''}
				onClick={handleClick}
				{...getLinkProps(props)}
			/>
		</li>
	);
}
