import React from 'react';
import { Dropdown } from 'react-bootstrap';
import DrillDownToggle from './DrillDownToggle';
import DrillDownMenu from './DrillDownMenu';
import DrillDownSelectedItems from './DrillDownSelectedItems';
import { defaultUseDrillDownProps } from './utility';
import { useElementIds } from '../../helpers/hooks/useElementIds';
import { DrillDownProps } from './types';

const DrillDown = ({
	id,
	className = defaultUseDrillDownProps.className,
	show = defaultUseDrillDownProps.show,
	children = null,
	selectedItems,
	...props
}: DrillDownProps) => {
	const { id: innerId } = useElementIds({ prefix: 'drill-down', id });

	return (
		<Dropdown id={innerId} className={className} show={show} {...props}>
			{children}
		</Dropdown>
	);
};
DrillDown.displayName = 'DrillDown';

DrillDown.SelectedItems = DrillDownSelectedItems;
DrillDown.Toggle = DrillDownToggle;
DrillDown.Menu = DrillDownMenu;

export default DrillDown;
