import { useCallback, useMemo, useReducer } from 'react';
import { IconName, iconPrefix } from '@optic-delight/icons';
import { iconPickerActionTypes } from '../iconPickerActions';
import { iconPickerReducer } from '../iconPickerReducer';
import { callAll } from '../../../helpers';
import { useLatestRef } from '../../../helpers/hooks/useLatestRef';
import { useElementIds } from '../../../helpers/hooks/useElementIds';
import { IconProp, IconPickerActionProps, IconPickerStateProps, UseIconPickerProps, GetOverlayProps } from '../types';

const determinePage = (icons: IconProp[], offset: number, selectedIcon?: IconProp) => {
	const selectedIndex = icons.findIndex(icon => icon.iconClass === selectedIcon?.iconClass);
	const selectedItemPage = Math.ceil((selectedIndex + 1) / offset);
	return selectedItemPage < 1 ? 1 : selectedItemPage;
};

const determineTotalPages = (size: number, offset: number) => {
	const totalPages = Math.ceil(size / offset);
	return totalPages === 0 ? 1 : totalPages;
};

export const parseFontAwesomeIcons = (css: string) => {
	return (
		css.match(/((?:\.fa-[a-z-]+::before,?\s*)+){\s*content:\s*".{1,5}";?\s*}/g)?.reduce((accumulator: IconProp[], selector) => {
			// in cases where multiple classes resolve to the same icon, just take the first one
			const iconClass = selector.split(':')[0].replace('.', '');
			accumulator.push({
				name: iconClass.replace(`${iconPrefix}-`, '') as IconName,
				iconClass
			});
			return accumulator;
		}, []) || []
	);
};

const useIconPicker = ({ css, offset = 20, reducer = iconPickerReducer }: UseIconPickerProps) => {
	const initialIcons = useMemo(() => {
		return parseFontAwesomeIcons(css);
	}, [css]);

	const [state, dispatch] = useReducer<React.Reducer<IconPickerStateProps, Partial<IconPickerActionProps>>>(
		reducer,
		{
			icons: initialIcons,
			currentPage: 1,
			totalPages: determineTotalPages(initialIcons.length, offset)
		},
		undefined
	);

	const latest = useLatestRef({ state, offset });
	const elementIds = useElementIds({ prefix: 'icon-picker-container' });

	const hideMenu = useCallback(() => {
		if (!latest.current.state.show === false) {
			dispatch({
				type: iconPickerActionTypes.hide,
				icons: initialIcons,
				currentPage: determinePage(initialIcons, latest.current.offset, latest.current.state.selectedIcon),
				totalPages: determineTotalPages(initialIcons.length, latest.current.offset)
			});
		}
	}, [initialIcons, latest]);

	const toggleMenu = () => {
		if (!latest.current.state.show === false) {
			hideMenu();
		} else {
			dispatch({
				type: iconPickerActionTypes.show
			});
		}
	};

	const filter = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const searchValue = event.target.value.toLowerCase();
			const filteredIcons = initialIcons.filter(icon => icon.name?.includes(searchValue) || icon.iconClass?.includes(searchValue));
			dispatch({
				type: iconPickerActionTypes.filter,
				icons: filteredIcons,
				totalPages: determineTotalPages(filteredIcons.length, latest.current.offset)
			});
		},
		[initialIcons, latest]
	);

	const selectIcon = useCallback(
		(icon: IconProp) => {
			dispatch({
				type: iconPickerActionTypes.select,
				icon,
				icons: initialIcons,
				currentPage: determinePage(initialIcons, latest.current.offset, icon),
				totalPages: determineTotalPages(initialIcons.length, latest.current.offset)
			});
		},
		[initialIcons, latest]
	);

	const navigate = (navigationOffset: number) => {
		dispatch({
			type: iconPickerActionTypes.navigate,
			offset: navigationOffset
		});
	};

	const getOverlayProps = useCallback(
		({ onHide, onFilter, onPreviousPage, onNextPage, onSelectIcon, ...overrideProps }: GetOverlayProps) => {
			return {
				...latest.current.state,
				id: elementIds.id,
				offset,
				icons: latest.current.state.icons,
				onHide: callAll(onHide, hideMenu),
				onFilter: callAll(onFilter, filter),
				onPreviousPage: callAll(onPreviousPage, () => navigate(-1)),
				onNextPage: callAll(onNextPage, () => navigate(1)),
				onSelectIcon: callAll(onSelectIcon, (e, icon: IconProp) => selectIcon(icon)),
				...overrideProps
			};
		},
		[filter, hideMenu, offset, elementIds, selectIcon, latest]
	);

	return {
		offset: latest.current.offset,
		toggleMenu,
		getOverlayProps
	};
};

export default useIconPicker;
