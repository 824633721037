import React from 'react';

import { staticFormControlTypes } from '../../../props/fieldProps';

import { InlineHelper } from '../../../helpers';
import { Label } from '../../utility';
import Helpblock from '../../utility/Helpblock';
import { FromControlStaticProps } from './types';

const FormControlStatic = ({
	value,
	label,
	tooltip,
	helptext,
	inline,
	'data-testid': dataTestId = 'form-control-static',
	children,
	...props
}: FromControlStaticProps) => (
	<div data-testid={dataTestId} className="row gx-2 mb-3" {...props}>
		<Label tooltip={tooltip?.toString()} className="col-auto">
			{label}
		</Label>
		<InlineHelper inline={inline}>
			<div data-testid={`${dataTestId}-value`}>{children ? children : value}</div>
			<Helpblock id="helpblockId" data-testid="helpblock-wrapper">
				{helptext}
			</Helpblock>
		</InlineHelper>
	</div>
);
FormControlStatic.displayName = 'FormControlStatic';

FormControlStatic.defaultProps = {
	...staticFormControlTypes
};

export default FormControlStatic;
