import React from 'react';
import { NavItemProps } from './types';
import { useElementIds } from '../../helpers/hooks/useElementIds';

export const NavItem = <As extends React.ElementType = 'div'>(props: NavItemProps<As>) => {
	const { as: Component = 'div', id, ...otherProps } = props;
	const { id: innerId } = useElementIds({ prefix: 'nav-item', id });

	return (
		<Component id={innerId} {...otherProps} className="nav-item">
			{props.children}
		</Component>
	);
};
