import React from 'react';
import { IconSuccess } from '@optic-delight/icons';
import useModal from './hooks/useModal';

const ModalSuccessHeader = (props: React.PropsWithChildren): JSX.Element => {
	const { icon } = useModal();
	const ModalIcon = icon ?? IconSuccess;

	return (
		<div className="text-center">
			<ModalIcon data-testid="modal-success-icon-test" size="4x" />
			{props.children}
		</div>
	);
};

ModalSuccessHeader.displayName = 'ModalSuccessHeader';

export default ModalSuccessHeader;
