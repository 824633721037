import { IGroupedListOption } from './types';
import { UseSelectState } from 'downshift';

export function isSameGroupByParentsList(group: IGroupedListOption, parentsList: string[]) {
	if (group.parents?.length !== parentsList.length - 1) return false;
	for (let i = 0; i < parentsList.length - 1; i++) {
		if (group.parents[i] !== parentsList[i]) return false;
	}
	return group.label === parentsList[parentsList.length - 1];
}

export function isExactChild(item: IGroupedListOption, group: IGroupedListOption): boolean {
	return isExactChildArray(item.parents ?? [], group.parents ?? [], group.label);
}

export function isChild(item: IGroupedListOption, group: IGroupedListOption): boolean {
	return isChildArray(item.parents ?? [], group.parents ?? [], group.label);
}

export function isExactChildArray(itemParents: string[], groupParents: string[], groupLabel: string): boolean {
	// group has on parent less than the item
	const lengthMatched = itemParents.length === groupParents.length + 1;
	return lengthMatched && isChildArray(itemParents, groupParents, groupLabel);
}

export function isChildArray(itemParents: string[], groupParents: string[], groupLabel: string): boolean {
	const targetParens = [...groupParents, groupLabel];

	for (let i = 0; i < targetParens.length; i++) {
		if (itemParents[i] !== targetParens[i]) return false;
	}
	return true;
}

export const updateSelectReducerChanges = (changes: Partial<UseSelectState<IGroupedListOption>>, isOpened: boolean, index: number) => ({
	...changes,
	isOpen: isOpened,
	highlightedIndex: index
});

export const selectInputReducerUtils = {
	toggleButtonKeydownCharacter(
		changes: Partial<UseSelectState<IGroupedListOption>>,
		setSearchValue: React.Dispatch<string>
	): Partial<UseSelectState<IGroupedListOption>> {
		// clear search input on menu toggle
		setSearchValue(changes.inputValue || '');
		return updateSelectReducerChanges(changes, true, -1);
	},

	menuBlur(
		changes: Partial<UseSelectState<IGroupedListOption>>,
		searchRef: React.MutableRefObject<HTMLInputElement | undefined>,
		setSearchValue?: React.Dispatch<string>
	): Partial<UseSelectState<IGroupedListOption>> {
		// focusing the searchInput triggers downshift blur. so we have to catch it and set select menu to
		// remain visible if searchInput is focused
		if (searchRef.current === document.activeElement || (document.activeElement && document.activeElement.ariaLabel === 'clear')) {
			if (searchRef.current !== document.activeElement) {
				setSearchValue?.('');
			}
			return updateSelectReducerChanges(changes, true, -1);
		}
		setSearchValue?.('');
		return changes;
	},

	menuKeydownEscape(
		changes: Partial<UseSelectState<IGroupedListOption>>,
		searchValue: string,
		setSearchValue: React.Dispatch<string>
	): Partial<UseSelectState<IGroupedListOption>> {
		// if there is a value in search input, then we use Escape button to clear its value
		if (searchValue) {
			setSearchValue('');
			return updateSelectReducerChanges(changes, true, 0);
		}

		return changes;
	},

	menuKeydownCharacter(
		changes: Partial<UseSelectState<IGroupedListOption>>,
		searchRef: React.MutableRefObject<HTMLInputElement | undefined>
	): Partial<UseSelectState<IGroupedListOption>> {
		// if we start typing, but search input is not focused, then we focus it
		// and remove highlighting on items
		if (searchRef.current !== document.activeElement) {
			searchRef.current?.focus();
		}

		return updateSelectReducerChanges(changes, true, -1);
	}
};

export function isInHiddenGroup(item: IGroupedListOption, hiddenGroups: string[][]) {
	return hiddenGroups.some(groupParents => {
		const hiddenParents = groupParents.length > 1 ? groupParents.slice(0, groupParents.length - 1) : [];
		const hiddenLabel = groupParents[groupParents.length - 1];
		return isChildArray(item.parents ?? [], hiddenParents, hiddenLabel);
	});
}

export function getSelectMenuHeight(itemsCount: number): string {
	return `${Math.min(16, itemsCount * 2.125)}rem`;
}
