import React from 'react';
import { Icon } from '@optic-delight/icons';
import { NavProgressItemProps } from './types';

/**
 * Used in conjunction with `NavProgress`
 */

const NavProgressItem = <As extends React.ElementType>({
	as: Component = 'a',
	eventKey,
	children,
	complete = false,
	active = false,
	className,
	...props
}: NavProgressItemProps<As>) => {
	const classes = ['progress-dot-step', active && 'current', !active && complete && 'complete', className].filter(Boolean).join(' ');

	return (
		<Component key={eventKey} className={classes} {...props}>
			<div className="progress-dot-label">{children}</div>
			<div className="progress-dot-icon">{complete && <Icon size="sm" icon="check" />}</div>
		</Component>
	);
};

NavProgressItem.displayName = 'NavProgressItem';

export default NavProgressItem;
