import React, { Children, useCallback } from 'react';
import { Dropdown } from 'react-bootstrap';
import { NavigationDropdownMenu } from './NavigationDropdownMenu';
import { NavigationDropdownToggle } from './NavigationDropdownToggle';
import useNavigation from '../hooks/useNavigation';
import { useElementIds } from '../../../helpers/hooks/useElementIds';
import { EventKey, NavigationDropdownProps } from '../types';
import { DropdownItemProps } from 'react-bootstrap/DropdownItem';

export function NavigationDropdown({ title, align = 'end', id, ...props }: NavigationDropdownProps) {
	const [show, setShow] = React.useState(false);
	const { show: navbarShow, activeKey } = useNavigation();
	const { id: innerId } = useElementIds({ prefix: 'navgiation-dropdown', id });

	const dropdownItemSelected = useCallback(() => {
		const eventKeys: (EventKey | undefined)[] = [];

		Children.forEach(props.children, child => {
			if (React.isValidElement(child)) {
				const { props } = child as React.ReactElement<DropdownItemProps>;
				const itemKey = props.eventKey ? props.eventKey : props.href;
				eventKeys.push(itemKey);
			}
		});

		return eventKeys.includes(activeKey);
	}, [activeKey, props.children]);

	React.useEffect(() => {
		if (!navbarShow) {
			// ensure dropdowns are closed when main nav is closed
			setShow(false);
		}
	}, [navbarShow]);

	const classes = [dropdownItemSelected() && 'active'].filter(Boolean).join(' ');

	return (
		<Dropdown id={innerId} as="li" className="nav-item" show={show} onToggle={nextShow => setShow(nextShow)}>
			<Dropdown.Toggle as={NavigationDropdownToggle} className={classes}>
				{title}
			</Dropdown.Toggle>
			<Dropdown.Menu as={NavigationDropdownMenu} align={align} {...props} />
		</Dropdown>
	);
}
