import axios, { AxiosInstance, AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';
import { generateUUID, MessageType, postMessage, REQUEST_UUID_FIELD_NAME } from '../../utility';

export function createAxiosInstance(config: AxiosRequestConfig): AxiosInstance {
	const axiosInstance = axios.create(config);
	axiosInstance.interceptors.request.use(
		requestConfig => {
			addDataToContextIfNotExists(requestConfig, REQUEST_UUID_FIELD_NAME, generateUUID());

			postMessage(MessageType.KEEP_ALIVE);
			return requestConfig;
		},
		error => Promise.reject(error)
	);

	return axiosInstance;
}

export function addDataToContextIfNotExists(requestConfig: InternalAxiosRequestConfig, key: string, value: string | number) {
	if (!requestConfig.method || requestConfig.method.toLowerCase() === 'get') {
		requestConfig.params = requestConfig.params ?? {};
		if (!requestConfig.params[key]) {
			requestConfig.params[key] = value;
		}
	} else if (typeof requestConfig.data === 'string') {
		if (!requestConfig.data.includes(key)) {
			requestConfig.data += `${requestConfig.data.length > 0 ? '&' : ''}${key}=${value}`;
		}
	} else if (requestConfig.data instanceof FormData) {
		if (!requestConfig.data.has(key)) {
			requestConfig.data.append(key, value.toString() || '');
		}
	} else {
		requestConfig.data = requestConfig.data ?? {};
		if (!requestConfig.data[key]) {
			requestConfig.data[key] = value;
		}
	}

	return requestConfig;
}
