export const APPLICATION_ROOT = 'bsc-react-root';
export const DEFAULT_QUEUE_INTERVAL = 10000;

export const MessageType = {
	KEEP_ALIVE: 'keepAlive',
	ROUTE_CHANGE: 'analyticsRouteChange'
};

export const Company = {
	System: 0,
	SuperTest: 7426
};

export const REQUEST_UUID_FIELD_NAME = 'requestUUID';

export const SupportedLanguages = {
	ENGLISH: {
		key: 1000,
		code: 'en'
	},
	FRENCH: {
		key: 1002,
		code: 'fr'
	},
	SPANISH: {
		key: 1001,
		code: 'sp'
	}
} as const;
