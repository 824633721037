import React, { Children, isValidElement, cloneElement } from 'react';
import { InlineHelperProps } from './types';

/**
 * Clones children components and shallowly merge the given props.
 * See https://reactjs.org/docs/react-api.html#cloneelement for more info about cloneElement
 *
 * Example Usage:
 * const childrenWithProps = cloneChildrenWithProps(props.children, {
 *     property: newValue,
 *     property2: newValue2,
 *     ...
 * });
 */
export function cloneChildrenWithProps<Props extends Partial<unknown> & React.Attributes>(children: React.ReactNode, props: Props) {
	return Children.map(children, child => {
		// Checking isValidElement is the safe way and avoids a TS error too.
		if (isValidElement(child)) {
			return cloneElement(child, props);
		}

		return child;
	});
}

/**
 * custom hook for combined list of refs
 */
export function useCombinedRefs<Ref>(...refs: Array<React.MutableRefObject<Ref> | React.LegacyRef<Ref> | undefined>) {
	const targetRef = React.useRef<Ref>(null);

	React.useEffect(() => {
		refs.forEach(ref => {
			if (!ref) {
				return;
			}

			if (typeof ref === 'function') {
				ref(targetRef.current);
			} else {
				(ref as React.MutableRefObject<Ref | null>).current = targetRef.current;
			}
		});
	}, [refs]);

	return targetRef;
}

export function handleRefs<Ref>(...refs: Array<React.MutableRefObject<Ref> | React.LegacyRef<Ref> | undefined>): React.RefCallback<Ref> {
	return (node: Ref) => {
		refs.forEach(ref => {
			if (typeof ref === 'function') {
				ref(node);
			} else if (ref) {
				(ref as React.MutableRefObject<Ref | null>).current = node;
			}
		});
	};
}

export function applyEventListener<Args extends unknown[], T extends (...args: Args) => void>(this: void, eventListener: T | undefined, args: Args) {
	if (eventListener) {
		eventListener.apply(this, args);
	}
}

export const inlineHelperTestId = process.env.NODE_ENV === 'test' ? 'inline-input-wrapper' : undefined;

export function InlineHelper({ inline, className, children }: InlineHelperProps): JSX.Element {
	if (inline || className) {
		return (
			<div className={[className, inline && 'col'].filter(Boolean).join(' ')} data-testid={inline ? inlineHelperTestId : undefined}>
				{children}
			</div>
		);
	}

	return <>{children}</>;
}

/**
 * generic HOC to conditionally render a component based off some function results
 */
/**
 *export function withConditionalRender(shouldRenderFn, Component) {
 *
 *	return function ConditionalComponent(props) {
 *		return shouldRenderFn(props) ? <Component {...props}>{ props.children }</Component> : null;
 *	};
 *}
 */
