import React, { useEffect } from 'react';
import { measureElement, useVirtualizer, Virtualizer } from '@tanstack/react-virtual';
import { useIntersectionObserver, useKeyboardNavigation } from '../forms/select/hooks';
import { Button } from '../button';
import { MeasurementsCacheRefProps, VirtualListWrapperProps } from './types';
import { Keys } from '../../helpers/keyboardKeys';
import { OptionsListLoader } from '../forms/select/OptionsListLoader';
import { paginationManualLoadTestId } from '../forms/select/constants';

const VirtualListWrapper = ({
	count,
	estimateSize,
	itemRender,
	textTruncation = true,
	paginationOptions,
	loading,
	loadingPlaceholder = 'Loading...',
	paginateButtonPlaceholder = 'Show More',
	highlightedIndex,
	updateHighlightedIndex,
	menuProps
}: VirtualListWrapperProps) => {
	const scrollElement = React.useRef<HTMLDivElement>(null);
	const measurementsCache = React.useRef<MeasurementsCacheRefProps>({});
	const lastItemRef = React.useRef<HTMLDivElement>(null);
	const loadMoreButtonRef = React.useRef<HTMLButtonElement>(null);
	const loadMoreLazyRef = React.useRef<HTMLDivElement>(null);

	const isIntersecting = useIntersectionObserver(lastItemRef);

	const measureElementMaxSize = React.useCallback(
		(element: HTMLLIElement, entry: ResizeObserverEntry | undefined, instance: Virtualizer<HTMLDivElement, HTMLLIElement>) => {
			const index = instance.indexFromElement(element);

			const prevSize = instance.measurementsCache[index].size;
			const size = measureElement(element, entry, instance);
			const newSize = Math.max(prevSize, size);
			if (!measurementsCache.current[index] || newSize > measurementsCache.current[index]) {
				measurementsCache.current[index] = newSize;
			}

			return newSize;
		},
		[]
	);

	const rowVirtualizer = useVirtualizer<HTMLDivElement, HTMLLIElement>({
		count: count,
		getScrollElement: () => scrollElement.current,
		estimateSize,

		// 30 is just for testing,to render more items
		// 15 is for runtime, less than for testing, but little more than number of items to fit in dropdown area
		overscan: process.env.NODE_ENV === 'test' ? 30 : 15,

		// if text is not truncated the element size is measured.
		...(!textTruncation && { measureElement: measureElementMaxSize })
	});

	const { updateSelectedIndex, lastItemReached } = useKeyboardNavigation({
		itemCount: count,
		initialSelectedIndex: -1, // initially no item is selected/focused.
		onKeyDown: index => {
			if (highlightedIndex !== index) {
				updateHighlightedIndex?.(index);
			}
			rowVirtualizer.scrollToIndex(index);
		}
	});

	const optionItemRef = React.useMemo(
		() => (node: HTMLLIElement | null) => {
			rowVirtualizer.measureElement(node);
		},
		[rowVirtualizer]
	);

	const onClickLoadMore = () => {
		paginationOptions?.nextPage();
	};

	const onKeyDownLoadMore = (event: React.KeyboardEvent<HTMLButtonElement>) => {
		if (event.code === Keys.Space.code || event.code === Keys.Enter.code) {
			paginationOptions?.nextPage();
		}
	};

	useEffect(() => {
		if (isIntersecting && !paginationOptions?.searching && !loading) {
			paginationOptions?.nextPage();
		}
	}, [isIntersecting, loading, paginationOptions]);

	useEffect(() => {
		if (highlightedIndex !== undefined) {
			// update selected/focused item on hover
			updateSelectedIndex(highlightedIndex);
		}
	}, [highlightedIndex, updateSelectedIndex]);

	useEffect(() => {
		if (lastItemReached || isIntersecting) {
			loadMoreButtonRef.current?.scrollIntoView();
			loadMoreLazyRef.current?.scrollIntoView();
		}
	}, [lastItemReached, isIntersecting, rowVirtualizer, loading]);

	const lastPage = paginationOptions?.currentPage === paginationOptions?.totalPages;
	const loadMoreButtonVisible = paginationOptions?.type === 'button' && !lastPage;
	const loadMoreLazyVisible = paginationOptions?.type === 'lazyload' && loading && !lastPage;
	const intersectingElementVisible = paginationOptions && !loading && count >= paginationOptions?.size && !lastPage && paginationOptions?.type === 'lazyload';

	const totalItemsHeight = rowVirtualizer.getTotalSize();

	const listClassNames = [menuProps?.className, 'w-100 mb-0 position-relative'].filter(Boolean).join(' ');

	return (
		<div className="w-100 h-100 overflow-auto position-relative" ref={scrollElement}>
			<ul className={listClassNames} style={{ height: totalItemsHeight }} {...menuProps}>
				{rowVirtualizer.getVirtualItems().map(virtualItem => itemRender(virtualItem, optionItemRef))}
			</ul>

			{loadMoreButtonVisible && (
				<Button
					ref={loadMoreButtonRef}
					tabIndex={0}
					className={`w-100 position-relative ${loading ? 'justify-content-center' : ''}`}
					onClick={onClickLoadMore}
					onKeyDown={onKeyDownLoadMore}
					loading={loading}
					data-testid={paginationManualLoadTestId}>
					{loading ? loadingPlaceholder : paginateButtonPlaceholder}
				</Button>
			)}

			{intersectingElementVisible && <div id="intersecting-element" ref={lastItemRef}></div>}

			{loadMoreLazyVisible && <OptionsListLoader loadingPlaceholder={loadingPlaceholder} ref={loadMoreLazyRef} />}
		</div>
	);
};
VirtualListWrapper.displayName = 'VirtualListWrapper';

export default VirtualListWrapper;
