import React, { MouseEvent } from 'react';
import SelectedItemButton from '../../utility/SelectedItemButton';
import { MultiSelectSelectedItemsProps } from './types';

const MultiSelectSelectedItems = (props: MultiSelectSelectedItemsProps) => {
	const { selectedItems = [], variant = 'neutral', size = 'sm', onClick, ...otherProps } = props;

	return selectedItems.length > 0 ? (
		<div className="d-flex flex-wrap gap-2 mb-2">
			{selectedItems.map(item => (
				<React.Fragment key={item.value}>
					<SelectedItemButton onClick={(event: MouseEvent<HTMLButtonElement>) => onClick?.(event, item)} {...{ ...otherProps, variant, size }}>
						{item.label}
					</SelectedItemButton>
				</React.Fragment>
			))}
		</div>
	) : null;
};
MultiSelectSelectedItems.displayName = 'MultiSelectSelectedItems';

export default MultiSelectSelectedItems;
