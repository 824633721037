import React, { ChangeEvent, DataHTMLAttributes, ForwardedRef, HTMLProps, ReactElement } from 'react';
import { IGroupedListOption, MultiSelectProps } from './types';
import FormGroupValidation from '../shared/FormGroupValidation';
import useFormGroupValidation from '../shared/hooks/useFormGroupValidation';
import { inputDefaultProps } from '../../../props/inputProps';
import { noop } from '../../../helpers';
import MultiSelectSelectedItems from './MultiSelectSelectedItems';
import OptionsList from './OptionsList';
import { useSearchableItemsList } from './hooks';
import Search from '../search/Search';
import { getSelectMenuHeight } from './utility';
import { IconLoading } from '@optic-delight/icons';
import { useControlledFocusOnError } from '../shared/hooks/useControlledFocusOnError';
import { useFlippedDropdownMenu } from '../../../helpers/hooks/useFlippedDropdownMenu';
import { OptionsListLoader } from './OptionsListLoader';
import { useDownshiftMultiSelect } from './hooks/useDownshiftPropsForMultiSelect';

export const MultiSelectDefaultPlaceholder = 'Select...';
export const MultiSelectLoadingPlaceholder = 'Loading...';
export const MultiSelectSearchDefaultPlaceholder = 'Search...';
export const MultiSelectNotFoundPlaceholder = 'Nothing found';

/**
 * see [Input](/docs/framework-forms-input--default) component for complete documentation on the underlying implementation
 */
const MultiSelect = React.forwardRef(
	<TFieldValue extends number[] | string[]>(props: MultiSelectProps<TFieldValue>, ref: ForwardedRef<HTMLInputElement>): ReactElement => {
		const {
			hideSelected = false,
			placeholder = MultiSelectDefaultPlaceholder,
			loadingPlaceholder = MultiSelectLoadingPlaceholder,
			searchPlaceholder = MultiSelectSearchDefaultPlaceholder,
			notFoundPlaceholder = MultiSelectNotFoundPlaceholder,
			compareType,
			compareBy,
			onChange,
			requiredFieldMessage,
			suppressValidationMessage,
			'data-testid': dataTestId,
			value,
			defaultValue,
			items = React.useMemo(() => [], []),
			size,
			disabled,
			readOnly,
			loading = false,
			searchable = false,
			onBlur: onBlurHandler,
			textTruncation = true,
			paginationOptions,
			...selectProps
		} = props;
		const downshiftMenuRef = React.useRef<HTMLUListElement>(null);
		const [isDropdownOpen, setIsDropdownOpen] = React.useState<boolean>(false);

		const searchableItemsListData = useSearchableItemsList({
			items,
			searchable,
			paginationOptions,
			...{ compareType, compareBy, value, defaultValue, ...selectProps },
			multiple: true
		});
		const {
			filteredItems,
			filteredItemsToDisplay,
			searchValue,
			isSearchVisible,
			searchRef,
			getGroupItems,
			hiddenGroups,
			collapseGroup,
			getItemByValue,
			onSearchInputChange,
			onClearSearchInput
		} = searchableItemsListData;

		const { getGroupProps, getLabelProps, getHelpblockProps, getInputProps, validation, inputRef } = useFormGroupValidation<TFieldValue, HTMLInputElement>({
			id: props.id,
			ref,
			disabled: disabled || loading,
			readOnly,
			...selectProps
		});

		const { controlId, ...groupProps } = getGroupProps();

		const { downshiftProps, downshiftMultiSelectProps } = useDownshiftMultiSelect<TFieldValue>({
			id: controlId,
			isOpen: isDropdownOpen,
			getSelectedItems,
			selectProps: { value, ...selectProps },
			searchableListProps: searchableItemsListData,
			downshiftMenuRef,
			inputRef,
			validation,
			setIsDropdownOpen
		});

		function getSelectedItems(): IGroupedListOption[] {
			return downshiftMultiSelectProps.selectedItems.map(getItemByValue).filter(Boolean) as IGroupedListOption[]; // type cast as filter thinks it can return undefined, but we filtered it
		}

		function toggleGroup(group: IGroupedListOption) {
			const toggledItems = getGroupItems(group, filteredItems);
			const hashedSelected = Object.fromEntries(downshiftMultiSelectProps.selectedItems.map(item => [item, true]));
			const allClear = toggledItems.every(item => !hashedSelected[item.value ?? '']);

			if (allClear) {
				toggledItems.forEach(item => {
					downshiftMultiSelectProps.addSelectedItem(item.value ?? '');
				});
			} else {
				toggledItems.forEach(item => {
					if (!hashedSelected[item.value ?? '']) return;
					downshiftMultiSelectProps.removeSelectedItem(item.value ?? '');
				});
			}
		}

		const updateHighlightedIndex = (index: number) => {
			downshiftProps.setHighlightedIndex(index);
		};

		const onSelectWrapperBlur = (event: React.FocusEvent<HTMLDivElement>) => {
			if (!event.relatedTarget) {
				// Focus is moving outside the document/container
				setIsDropdownOpen(false);
			}
		};

		const { ref: buttonRef } = useControlledFocusOnError<HTMLButtonElement>({ name: selectProps.name });
		const downshiftInputProps = downshiftProps.getToggleButtonProps(
			downshiftMultiSelectProps.getDropdownProps({ ref: buttonRef, preventKeyAction: downshiftProps.isOpen })
		);
		const downshiftLabelProps = downshiftProps.getLabelProps();

		// label props
		const baseLabelProps = getLabelProps({ htmlFor: downshiftInputProps.id });
		const labelProps = { ...downshiftLabelProps, ...baseLabelProps };

		// menu props
		const isNothingFoundShown = isSearchVisible && filteredItemsToDisplay.length === 0 && !loading;
		const downshiftMenuProps = downshiftProps.getMenuProps({
			ref: downshiftMenuRef,
			'aria-labelledby': labelProps.id,
			tabIndex: 0
		});

		const menuWrapperProps: HTMLProps<HTMLDivElement> & DataHTMLAttributes<HTMLDivElement> = {
			className: 'dropdown-menu-items',
			style: {
				height: getSelectMenuHeight(isNothingFoundShown || paginationOptions?.searching ? 1 : filteredItemsToDisplay.length)
			}
		};

		// input props
		const getInputValue = () => {
			let inputValue = placeholder;
			if (loading) {
				inputValue = loadingPlaceholder;
			}
			const selectedItemsLength = downshiftMultiSelectProps.selectedItems?.length;
			const hasSelectedItems = selectedItemsLength > 0;
			const hasOneSelectedItem = selectedItemsLength === 1;

			if (hasSelectedItems) {
				inputValue = hasOneSelectedItem
					? getItemByValue(downshiftMultiSelectProps.selectedItems[0] || -1)?.label || ''
					: `{ ${selectedItemsLength} selected }`;
			}

			return inputValue;
		};
		const inputValue = getInputValue();

		const baseInputProps = getInputProps({
			id: downshiftInputProps.id,
			value: inputValue,
			'aria-labelledby': labelProps.id,
			'aria-owns': downshiftMenuProps.id,
			className: ['form-select', validation.isInvalid && 'is-invalid', loading && 'loading'].filter(Boolean).join(' ')
		});

		const {
			// onBlur from getInputProps returns a string when multiselect closes via toggle, to prevent this we destructure onBlur
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			onBlur: onInputBlur = onBlurHandler,
			'aria-required': ariaRequired = undefined,
			disabled: toggleBtnDisabled = undefined,
			...inputProps
		} = { ...downshiftInputProps, ...baseInputProps };

		// helpblock props
		const helpblockProps = getHelpblockProps({
			id: `${controlId}_helptext`
		});

		const { setValue = noop } = validation.formContext;
		const { dropdownMenuRef, isDropdownMenuFlipped } = useFlippedDropdownMenu(downshiftProps.isOpen);

		// effects
		React.useEffect(() => {
			if (downshiftProps.isOpen && paginationOptions && !paginationOptions.initialFetchCalled) {
				paginationOptions.initialFetch();
			}
		}, [downshiftProps.isOpen, paginationOptions]);

		React.useEffect(() => {
			if (!baseInputProps.disabled && baseInputProps.name) {
				setValue(baseInputProps.name, downshiftMultiSelectProps.selectedItems);
			}
		}, [downshiftMultiSelectProps.selectedItems, baseInputProps.name, baseInputProps.disabled, setValue]);

		React.useEffect(() => {
			const onBodyClick = (event: MouseEvent) => {
				const target = event.target as Node;
				if (dropdownMenuRef.current && !dropdownMenuRef.current.contains(target) && buttonRef.current && !buttonRef.current.contains(target)) {
					setIsDropdownOpen(false);
				}
			};

			if (isDropdownOpen) {
				document.addEventListener('mousedown', onBodyClick);
			}

			return () => {
				document.removeEventListener('mousedown', onBodyClick);
			};
		}, [buttonRef, dropdownMenuRef, isDropdownOpen]);

		return (
			<FormGroupValidation
				groupProps={groupProps}
				labelProps={labelProps}
				helpblockProps={helpblockProps}
				validation={validation}
				inputRef={inputRef}
				inline={selectProps.inline}
				requiredFieldMessage={requiredFieldMessage}
				suppressValidationMessage={suppressValidationMessage}>
				<div className={['form-select-wrapper', validation.isInvalid && 'is-invalid'].filter(Boolean).join(' ')} onBlur={onSelectWrapperBlur}>
					{!hideSelected && downshiftMultiSelectProps.selectedItems ? (
						<MultiSelectSelectedItems
							selectedItems={getSelectedItems()}
							onClick={(_e: React.MouseEvent<HTMLButtonElement>, item: IGroupedListOption) => downshiftProps.selectItem(item)}
						/>
					) : null}

					<span className={loading ? 'input-group input-group-embedded' : undefined}>
						<input {...inputProps} data-testid={dataTestId} type="button" disabled={toggleBtnDisabled || readOnly} />
						{loading && (
							<span className="input-group-text" data-testid="loading-spinner">
								<IconLoading />
							</span>
						)}
					</span>

					<input
						id={selectProps.id}
						data-testid="select-hidden-input"
						aria-required={ariaRequired}
						ref={inputRef}
						value={downshiftMultiSelectProps.selectedItems?.map(t => t).join(',')}
						onInput={event => onChange?.(event as ChangeEvent<HTMLInputElement>)}
						type="hidden"
						name={baseInputProps.name}
						disabled={baseInputProps.disabled}
					/>

					<div
						ref={dropdownMenuRef}
						data-testid="select-dropdown-menu"
						className={['dropdown-menu w-100 p-0', downshiftProps.isOpen && 'show', isDropdownMenuFlipped && 'dropdown-menu-end']
							.filter(Boolean)
							.join(' ')}>
						{isSearchVisible ? (
							<div className="dropdown-search-field w-100">
								<Search
									groupClassName=""
									ref={searchRef as React.MutableRefObject<HTMLInputElement>}
									value={searchValue}
									placeholder={searchPlaceholder}
									onChange={onSearchInputChange}
									aria-autocomplete="list"
									aria-controls={inputProps.id}
									autoComplete="off"
									onClear={onClearSearchInput}
								/>
							</div>
						) : null}

						{loading && filteredItemsToDisplay.length === 0 && <OptionsListLoader loadingPlaceholder={loadingPlaceholder} />}

						<div {...menuWrapperProps}>
							<OptionsList
								getItemProps={downshiftProps.getItemProps}
								getGroupItems={getGroupItems}
								allItems={filteredItems}
								visibleItems={filteredItemsToDisplay}
								hiddenGroups={hiddenGroups}
								highlightedIndex={downshiftProps.highlightedIndex}
								updateHighlightedIndex={updateHighlightedIndex}
								notFoundPlaceholder={notFoundPlaceholder}
								selectedItems={downshiftMultiSelectProps.selectedItems}
								menuProps={downshiftMenuProps}
								multiple={true}
								searchString={searchValue}
								onGroupCollapse={collapseGroup}
								onGroupToggle={toggleGroup}
								textTruncation={textTruncation}
								paginationOptions={paginationOptions}
								loading={loading}
								loadingPlaceholder={loadingPlaceholder}
							/>
						</div>
					</div>
				</div>
			</FormGroupValidation>
		);
	}
);
MultiSelect.defaultProps = {
	...inputDefaultProps
};
MultiSelect.displayName = 'MultiSelect';

export default MultiSelect as <TFieldValue>(props: MultiSelectProps<TFieldValue> & { ref?: ForwardedRef<HTMLFormElement> }) => JSX.Element;
