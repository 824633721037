import { createIcon } from '../utility';

export const IconPlay = createIcon('play');

export const IconPause = createIcon('pause');

export const IconStop = createIcon('stop');

export const IconReplay = createIcon('replay');

export const IconVolumeMute = createIcon('volume-mute');

export const IconVolumeLow = createIcon('volume-low');

export const IconVolume = createIcon('volume');

export const IconVolumeHigh = createIcon('volume-high');

export const IconFullScreen = createIcon('full-screen');

export const IconFullScreenExit = createIcon('full-screen-exit');

export const IconPictureInPicture = createIcon('picture-in-picture');

export const IconClosedCaptioning = createIcon('closed-captioning');

export const IconSubtitles = createIcon('subtitles');

export const IconTranscript = createIcon('transcript');

export const IconZoomIn = createIcon('zoom-in');

export const IconZoomOut = createIcon('zoom-out');

export const IconFont = createIcon('font');

export const IconMusic = createIcon('music');

export const IconCamera = createIcon('camera');

export const IconImage = createIcon('image');

export const IconFilm = createIcon('film');

export const IconRss = createIcon('rss');

export const IconLink = createIcon('link');
