import React from 'react';
import { ModalProps, ModalPropsFullscreen } from './types';

import { Modal as BootstrapModal } from 'react-bootstrap';

import ModalProvider from './ModalProvider';
import ModalHeader from './ModalHeader';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';

const defaultCloseLabel = 'Close';
const defaultContinueLabel = 'Continue';

/**
 * see [react-bootstrap - Modal](https://react-bootstrap.github.io/components/modal/) for complete documentation on the underlying implementation
 */
const Modal = ({
	keyboard = true,
	scrollable = true,
	closeLabel = defaultCloseLabel,
	continueLabel = defaultContinueLabel,
	icon,
	onContinue,
	onHide,
	children,
	type,
	fullscreen = 'sm-down',
	...props
}: ModalPropsFullscreen): JSX.Element => {
	const internalProps = props as ModalProps;
	const footers = React.Children.map(children, child => {
		if (child.type?.name === ModalFooter.name) {
			return child;
		}
	});
	const forcedFooter = (!footers || !footers[0]) && type !== 'success' ? <Modal.Footer /> : null;

	return (
		<ModalProvider icon={icon} onContinue={onContinue} continueLabel={continueLabel} closeLabel={closeLabel} onHide={onHide}>
			<BootstrapModal
				keyboard={keyboard}
				scrollable={scrollable}
				onEscapeKeyDown={onHide}
				backdrop="static"
				fullscreen={!fullscreen ? undefined : fullscreen}
				{...internalProps}>
				{children}
				{forcedFooter}
			</BootstrapModal>
		</ModalProvider>
	);
};

Modal.displayName = 'Modal';
Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;

Modal.actionLabel = defaultContinueLabel;
Modal.closeLabel = defaultCloseLabel;

export default Modal;
