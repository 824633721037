import PropTypes from 'prop-types';
import { fieldDefaultProps, fieldPropTypes, getStorybookFieldArgTypes } from './fieldProps';
import { defaultPropToString } from './storybookUtility';
import { ArgTypes } from '@storybook/types';
import { InputProps } from '../components';

export const inputDefaultProps = {
	...fieldDefaultProps,
	validators: [],
	groupClassName: 'mb-3',
	'aria-label': undefined
};

export const inputPropTypes = {
	...fieldPropTypes,
	'aria-label': PropTypes.string,
	validators: PropTypes.array,
	groupClassName: PropTypes.string,
	inline: PropTypes.bool
};

export const getStorybookInputArgTypes = (): ArgTypes<InputProps> => {
	return {
		...getStorybookFieldArgTypes(),
		validators: {
			description: 'react-hook-forms validation',
			table: {
				type: {
					summary: 'array'
				},
				defaultValue: { summary: defaultPropToString(inputDefaultProps.validators) }
			}
		},
		isInvalid: {
			description: 'Validity flag.',
			type: { name: 'boolean' },
			table: {
				type: {
					summary: 'boolean'
				}
			},
			control: { type: 'boolean' }
		},
		addOnRole: {
			description: 'Aria role for the input group.',
			type: { name: 'string' },
			table: {
				type: {
					summary: 'string'
				}
			},
			control: { type: 'string' }
		},
		addOnAppend: {
			description: 'Add add-on in end of input.',
			table: {
				type: {
					summary: 'Element'
				}
			}
		},
		addOnPrepend: {
			description: 'Add add-on in start of input.',
			table: {
				type: {
					summary: 'Element'
				}
			}
		},
		embedded: {
			description: "Add 'input-group-embedded' class to input component. Don't use with multiple addOn",
			type: { name: 'boolean' },
			table: {
				type: {
					summary: 'boolean'
				}
			}
		},
		'aria-label': {
			description: 'hidden input label.  required for accessibility if no label is present',
			type: { name: 'string' },
			table: {
				type: {
					summary: 'string'
				},
				defaultValue: { summary: inputDefaultProps['aria-label'] }
			},
			control: { type: 'text' }
		},
		groupClassName: {
			description: 'change the underlying group CSS class name(s). This is an escape hatch for working with heavily customized bootstrap css.',
			type: { name: 'string' },
			table: {
				type: {
					summary: 'string'
				},
				defaultValue: { summary: inputDefaultProps.groupClassName }
			},
			control: { type: 'text' }
		},
		inline: {
			description: 'indicates if the form label and input should display inline',
			type: { name: 'boolean' },
			table: {
				type: {
					summary: 'boolean'
				}
			},
			control: { type: 'boolean' }
		}
	};
};
