import React, { CSSProperties, MutableRefObject } from 'react';
import {
	Alignment,
	arrow,
	autoPlacement,
	autoUpdate,
	flip,
	offset,
	shift,
	useClick,
	useFloating,
	useFocus,
	useInteractions,
	useRole
} from '@floating-ui/react';
import { TooltipIndicator } from './TooltipIndicator';
import { TooltipPortal } from './TooltipPortal';
import { Placement, TooltipProps } from './types';
import { useCombinedRefs } from '../../helpers';

export default function Tooltip({
	as: Indicator = TooltipIndicator,
	id,
	children,
	placement: defaultPlacement = 'top',
	show = false,
	arrowProps = {},
	...props
}: TooltipProps): JSX.Element | null {
	if (!children) {
		return null;
	}

	const [open, setOpen] = React.useState(show);
	const [transition, setTransition] = React.useState(show);
	const mountedRef = React.useRef<boolean>(false);
	const timeoutRef = React.useRef<NodeJS.Timeout>(null) as MutableRefObject<NodeJS.Timeout>;
	const isAutoPlacement = defaultPlacement.startsWith('auto');

	const { ref: arrowRef, style: initialArrowStyle, ...remainingArrowProps } = arrowProps;
	const innerArrowRef = useCombinedRefs(React.useRef<HTMLDivElement>(null), arrowRef) as unknown as MutableRefObject<HTMLDivElement>;

	React.useEffect(() => {
		if (!mountedRef.current) {
			mountedRef.current = true;
		}

		// clear timeout when component gets unmounted
		return () => {
			mountedRef.current = false;
			clearTimeout(timeoutRef.current);
		};
	}, []);

	const { context, refs, floatingStyles, placement, middlewareData } = useFloating({
		open: open,
		onOpenChange: opened => {
			// css transitions are visible because of the timeouts below
			if (opened) {
				setOpen(true);
				timeoutRef.current = setTimeout(() => {
					if (mountedRef.current) {
						setTransition(true);
					}
				}, 50);
			} else {
				setTransition(false);
				timeoutRef.current = setTimeout(() => {
					if (mountedRef.current) {
						setOpen(false);
					}
				}, 100);
			}
		},
		placement: isAutoPlacement ? undefined : (defaultPlacement as Placement),
		// The order of middleware is important
		middleware: [
			offset(0),
			isAutoPlacement ? autoPlacement({ alignment: (defaultPlacement.replace('auto-', '') as Alignment) || undefined }) : flip(),
			shift(),
			arrow({ element: innerArrowRef })
		],
		whileElementsMounted: autoUpdate
	});
	const { getReferenceProps, getFloatingProps } = useInteractions([useClick(context), useFocus(context), useRole(context, { role: 'tooltip' })]);

	const arrowStyle: CSSProperties = initialArrowStyle || {};
	if (middlewareData.arrow) {
		if (middlewareData.arrow.x) {
			arrowStyle['left'] = middlewareData.arrow.x;
		}
		if (middlewareData.arrow.y) {
			arrowStyle['top'] = middlewareData.arrow.y;
		}
	}

	return (
		<>
			<Indicator
				{...getReferenceProps({
					ref: refs.setReference,
					'aria-describedby': context.open ? id : undefined,
					...props
				})}
			/>
			{open && (
				<TooltipPortal
					transition={transition}
					arrowProps={{
						ref: innerArrowRef,
						style: arrowStyle,
						...remainingArrowProps
					}}
					placement={placement}
					{...getFloatingProps({
						ref: refs.setFloating,
						id,
						style: floatingStyles
					})}>
					{children}
				</TooltipPortal>
			)}
		</>
	);
}
