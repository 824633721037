import { DrillDownInitialToggleLabel } from './DrillDownToggle';
import { DrillDownItem } from './types';

export const defaultUseDrillDownProps = {
	items: [],
	multiple: false,
	isOpen: false,
	className: 'drilldown',
	show: false,
	initialToggleLabel: DrillDownInitialToggleLabel
};

export const sort = (items: DrillDownItem[]) => {
	return [...items].sort((first, second) => {
		const firstHasChildren = typeof first !== 'string' && Array.isArray(first?.group);
		const secondHasChildren = typeof second !== 'string' && Array.isArray(second?.group);
		if (!firstHasChildren && secondHasChildren) {
			return -1;
		} else if (firstHasChildren && !secondHasChildren) {
			return 1;
		}

		return 0;
	});
};

export const search = (value: string, items: DrillDownItem[]): DrillDownItem[] => {
	return items.reduce<DrillDownItem[]>((accumulator, item) => {
		if (typeof item !== 'string' && Array.isArray(item?.group)) {
			const sortedItems = sort(item.group);
			const results = search(value, sortedItems);
			if (results.length) {
				const filteredItem = {
					...item,
					group: results
				};
				accumulator.push(filteredItem);
			}
		} else if (item.toString().toLowerCase().includes(value)) {
			accumulator.push(item);
		}
		return accumulator;
	}, []);
};

export const flattenItems = (items: DrillDownItem[] = []): DrillDownItem[] => {
	return items.reduce((accumulator, item) => {
		if (typeof item !== 'string') {
			if (item.group && item.content) {
				accumulator.push(...flattenItems(item.group));
			}
		} else {
			accumulator.push(item.toString());
		}
		return accumulator;
	}, [] as DrillDownItem[]);
};
