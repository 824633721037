import React, { ForwardedRef } from 'react';
import { Button } from '../../button';
import FormGroupValidation, { REQUIRED_FIELD_MESSAGE } from '../shared/FormGroupValidation';
import ListboxItemsList from './ListboxItemsList';
import { IListOption, ListboxProps } from './types';
import { useFieldsetValidation } from '../shared/hooks/useFieldsetValidation';
import { inputDefaultProps } from '../../../props/inputProps';
import { noop } from '../../../helpers';
import { comparatorTypes, itemToString } from '../combobox/utility';
import { useElementIds } from '../../../helpers/hooks/useElementIds';
import { useFormContext } from '../form-element';
import { useControlledFocusOnError } from '../shared/hooks/useControlledFocusOnError';

export const ListboxUnassignedLabel = 'Unassigned';
export const ListboxAssignedLabel = 'Assigned';
export const ListboxAllocateLabel = 'Allocate';
export const ListboxDeallocateLabel = 'Deallocate';
export const ListboxLoadingLabel = 'Loading...';

// https://www.w3.org/TR/2017/WD-wai-aria-practices-1.1-20170628/examples/listbox/listbox.html
const Listbox = React.forwardRef((props: ListboxProps, ref: ForwardedRef<HTMLInputElement>) => {
	const sortContentAscending = React.useCallback((first: string | undefined, second: string | undefined) => {
		if (first === undefined || second === undefined) {
			return 0;
		}

		const firstLowered = first?.toLowerCase();
		const secondLowered = second?.toLowerCase();
		if (firstLowered < secondLowered) {
			return -1;
		} else if (firstLowered > secondLowered) {
			return 1;
		}
		return 0;
	}, []);

	const {
		items = [],
		value,
		selectedItems,
		defaultValue = selectedItems,
		disabled,
		readOnly,
		required,
		loading = false,
		label,
		assignedLabel = ListboxAssignedLabel,
		unassignedLabel = ListboxUnassignedLabel,
		allocateLabel = ListboxAllocateLabel,
		deallocateLabel = ListboxDeallocateLabel,
		loadingLabel = ListboxLoadingLabel,
		exclusionFilter = true,
		sortFn = sortContentAscending,
		compareType = comparatorTypes.startsWith,
		compareBy,
		requiredFieldMessage = REQUIRED_FIELD_MESSAGE,
		suppressValidationMessage = false,
		type = 'responsive',
		'data-testid': testId,
		onAllocationChange,
		textTruncation = true
	} = props;

	const { id } = useElementIds({
		prefix: 'listbox',
		id: props.id
	});

	if (process.env.NODE_ENV === 'development') {
		if (selectedItems) {
			// eslint-disable-next-line no-console
			console.warn('Prop `selectedItems` is deprecated, use `defaultValue` instead');
		}

		if (value && defaultValue) {
			// eslint-disable-next-line no-console
			console.warn(
				[
					`You are using 'defaultValue' and 'value' at the same time.`,
					`That is not allowed, the component must be controlled or uncontrolled.`,
					`\`defaultValue\` will be ignored`
				].join(' ')
			);
		}
	}

	const isControlled = !!value;

	const [localValue, setLocalValue] = React.useState([...(value ?? defaultValue ?? [])]);

	const shouldOnAllocateFire = React.useRef(false); // to prevent fire on first render

	const { getGroupProps, getLabelProps, getHelpblockProps, inputRef, validation } = useFieldsetValidation<string, HTMLInputElement>({
		ref,
		label,
		required,
		...props
	});
	const formContext = useFormContext();
	const isDisabled = formContext?.formState?.isDisabled || disabled;

	const splitItems = React.useCallback(
		(items: IListOption[], unassigned: IListOption[], assigned: IListOption[]): [IListOption[], IListOption[]] => {
			const hashedValues = Object.fromEntries(localValue.map(item => [item, true]));
			items.forEach(item => {
				const isGroup = item.value === undefined && item.items !== undefined;
				if (isGroup) {
					const [unassignedSub, assignedSub] = splitItems(item.items || [], [], []);
					if (unassignedSub.length > 0) {
						unassigned.push({ ...item, items: unassignedSub });
					}
					if (assignedSub.length > 0) {
						assigned.push({ ...item, items: assignedSub });
					}
				} else {
					if (item?.value !== undefined && hashedValues[item.value]) {
						assigned.push(item);
					} else {
						unassigned.push(item);
					}
				}
			});
			return [unassigned.sort((a, b) => sortFn(a.label, b.label)), assigned.sort((a, b) => sortFn(a.label, b.label))];
		},
		[sortFn, localValue]
	);
	const [unassigned, assigned] = React.useMemo(() => {
		return splitItems(items ?? [], [], []);
	}, [splitItems, items]);

	const [unassignedSelected, setUnassignedSelected] = React.useState<(string | number)[]>([]);
	const [assignedSelected, setAssignedSelected] = React.useState<(string | number)[]>([]);

	const areControlsVisible = !loading && (unassignedSelected.length > 0 || assignedSelected.length > 0);

	function moveToAssigned() {
		if (unassignedSelected.length === 0) {
			return;
		}

		setLocalValue([...localValue, ...unassignedSelected]);
		setUnassignedSelected([]);
	}

	function moveToUnassigned() {
		if (assignedSelected.length === 0) {
			return;
		}

		const hashedValues = Object.fromEntries(assignedSelected.map(item => [item, true]));
		setLocalValue(localValue.filter(item => !hashedValues[item]));
		setAssignedSelected([]);
	}

	const isRequired = validation.isRequired;
	const setIsInvalid = validation.setIsInvalid;
	React.useEffect(() => {
		// preventing from running on first render
		if (shouldOnAllocateFire.current) {
			onAllocationChange?.({ deallocated: unassigned, allocated: assigned });
			setIsInvalid(isRequired && assigned.length === 0);
		} else {
			shouldOnAllocateFire.current = true;
		}
	}, [setIsInvalid, isRequired, onAllocationChange, assigned, unassigned]);

	function getStringifiedValue(): string | undefined {
		function getValues(items: IListOption[]): (string | number)[] {
			return items.reduce((acc: (string | number)[], item: IListOption) => {
				if (item.items !== undefined) {
					acc = [...acc, ...getValues(item.items)];
				} else {
					if (item.value !== undefined) {
						acc.push(item.value);
					}
				}
				return acc;
			}, []);
		}

		return isDisabled ? undefined : getValues(assigned).join('|');
	}

	// update form value on assign
	React.useEffect(() => {
		if (Object.prototype.hasOwnProperty.call(validation.formContext, 'setValue') && props.name && !isDisabled) {
			validation.formContext.setValue(props.name, assigned.map(selectedItem => itemToString(selectedItem, 'value')).join('|'));
		}
	}, [props.name, isDisabled, validation.formContext, assigned]);

	React.useEffect(() => {
		if (isControlled) {
			const lengthTheSame = localValue.length === value?.length;
			let itemsTheSame = false;
			if (lengthTheSame) {
				const hashedValuesLocal = Object.fromEntries(localValue.map(item => [item, true]));
				itemsTheSame = value?.every(item => hashedValuesLocal[item]);
			}

			if (!lengthTheSame || !itemsTheSame) {
				setLocalValue(value);
			}
		}
	}, [isControlled, localValue, value]);

	const legendProps = getLabelProps();

	const listboxClasses = ['listbox', type === 'responsive' && 'flex-column flex-md-row', type === 'vertical' && 'flex-column'].filter(Boolean).join(' ');

	const getControlsVisibilityClasses = (): string => {
		if (!areControlsVisible) {
			const visibilityByType = {
				vertical: 'd-none',
				horizontal: 'invisible',
				responsive: 'invisible d-none d-md-flex'
			};
			return visibilityByType[type];
		}
		return '';
	};

	const listboxControlsClasses = ['listbox-controls', 'd-flex gap-3 align-self-center', getControlsVisibilityClasses(), type].filter(Boolean).join(' ');
	const { ref: controlledRef } = useControlledFocusOnError<HTMLUListElement>({ name: props.name });

	return (
		<FormGroupValidation
			inputRef={inputRef}
			groupProps={getGroupProps({ controlId: undefined, disabled: loading || isDisabled || readOnly, 'data-testid': testId })}
			labelProps={legendProps}
			helpblockProps={getHelpblockProps()}
			validation={validation}
			suppressValidationMessage={validation.isInvalid}>
			<div className={listboxClasses}>
				<ListboxItemsList
					id={`${id}-unassigned`}
					label={unassignedLabel}
					parentLabelId={legendProps.id || undefined}
					items={unassigned}
					disabled={isDisabled}
					loading={loading}
					loadingLabel={loadingLabel}
					exclusionFilter={exclusionFilter}
					value={unassignedSelected}
					onChange={items => setUnassignedSelected(items.map(item => item.value ?? ''))}
					compareType={compareType}
					compareBy={compareBy}
					data-testid="unassigned-list"
					textTruncation={textTruncation}
				/>

				<div className={listboxControlsClasses}>
					<Button icon="next" variant="primary" data-testid="allocate-button" onClick={moveToAssigned} title={allocateLabel} />

					<Button icon="back" variant="neutral" data-testid="deallocate-button" onClick={moveToUnassigned} title={deallocateLabel} />
				</div>

				<ListboxItemsList
					ref={controlledRef}
					id={`${id}-assigned`}
					label={assignedLabel}
					parentLabelId={legendProps.id || undefined}
					items={assigned}
					disabled={isDisabled}
					loading={loading}
					loadingLabel={loadingLabel}
					exclusionFilter={exclusionFilter}
					value={assignedSelected}
					onChange={items => setAssignedSelected(items.map(item => item.value ?? ''))}
					compareType={compareType}
					compareBy={compareBy}
					invalid={validation.isInvalid}
					parentLabel={label}
					requiredFieldMessage={requiredFieldMessage}
					suppressValidationMessage={suppressValidationMessage}
					data-testid="assigned-list"
					textTruncation={textTruncation}
				/>
			</div>

			<input ref={inputRef} name={props.name} type="hidden" value={getStringifiedValue()} onChange={noop} disabled={isDisabled} />
		</FormGroupValidation>
	);
});
Listbox.displayName = 'Listbox';

Listbox.defaultProps = {
	...inputDefaultProps
};

export default Listbox;
