import * as React from 'react';
import { TCellProps } from './types';
import { useTableGrouping } from './hooks/useTableGrouping';
import { classList } from '../utility/utils';
import { useTable } from './hooks/useTable';

export const TCell = React.forwardRef<HTMLTableCellElement, TCellProps>(({ as: Cell = 'td', variant, active, className, scope, ...props }, ref) => {
	const { isPresentationTable } = useTable();
	const { type } = useTableGrouping();
	let derivedScope = undefined;
	if (!isPresentationTable) {
		derivedScope = scope;
		if (typeof derivedScope === 'undefined' && Cell === 'th') {
			if (type === 'body') {
				derivedScope = 'row';
			} else if (type === 'header') {
				derivedScope = 'col';
			}
		}
	}

	const classes = classList([variant && `table-${variant}`, active && 'table-active', className]);
	return <Cell ref={ref} className={classes} scope={derivedScope} {...props} />;
});
TCell.displayName = 'TCell';
