import React, { MouseEvent } from 'react';
import SelectedItemButton from '../utility/SelectedItemButton';
import { ButtonProps, ButtonVariant } from '../button';

export interface DrillDownSelectedItemsProps {
	/*
	 * List of items
	 */
	selectedItems?: string[];
	onClick: (e: MouseEvent<HTMLElement>, item: string) => void;
	variant?: ButtonVariant;
	size?: ButtonProps['size'];
}

const DrillDownSelectedItems = ({ selectedItems = [], variant = 'neutral', size = 'sm', onClick, ...props }: DrillDownSelectedItemsProps) => {
	return selectedItems && selectedItems.length > 0 ? (
		<div className="d-flex flex-wrap gap-1 mb-2">
			{selectedItems.map((item, index) => {
				return (
					<React.Fragment key={index}>
						<SelectedItemButton onClick={e => onClick(e, item)} variant={variant} size={size} {...props}>
							{item}
						</SelectedItemButton>
					</React.Fragment>
				);
			})}
		</div>
	) : null;
};
DrillDownSelectedItems.displayName = 'DrillDownSelectedItems';

export default DrillDownSelectedItems;
