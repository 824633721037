import { IGroupedListOption, IListOption, UseSearchableItemsListData, UseSearchableItemsListProps } from '../types';
import { comparatorTypes, compare, compareBasedOnCompareBy } from '../../combobox/utility';
import React, { ChangeEvent, useCallback } from 'react';
import { isChild, isInHiddenGroup, isSameGroupByParentsList } from '../utility';
import { ComparatorTypes } from '../../combobox/types';

export function calculatePlainItems(
	baseItems: IListOption[],
	acc: IGroupedListOption[] = [],
	level = 0,
	parents: string[] = []
): { plainItems: IGroupedListOption[]; count: number } {
	let childrenCount = 0;
	for (const item of baseItems) {
		const isGroup = item.value === undefined && item.items !== undefined;
		if (isGroup) {
			const group: IGroupedListOption = { ...item, value: undefined, label: item.label, isGroup, level, parents: [...parents] };
			acc.push(group);

			if (group.items) {
				const { count } = calculatePlainItems(group.items, acc, level + 1, [...parents, group.label]);
				childrenCount += count;
				group.childrenCount = count;
			}
		} else {
			acc.push({ ...item, parents, level, isGroup });
		}
	}
	return { plainItems: acc, count: baseItems.filter(item => !item.value === undefined || !item.items).length + childrenCount };
}

export function useSearchableItemsList(props: UseSearchableItemsListProps): UseSearchableItemsListData {
	const {
		items,
		value,
		defaultValue,
		compareType = comparatorTypes.startsWith,
		exclude = false,
		multiple = false,
		compareBy,
		searchable,
		paginationOptions
	} = props;

	const updatePlainItemsRef = React.useRef(false);
	const updateFilteredItemsRef = React.useRef(false);
	const [plainItems, setPlainItems] = React.useState<IGroupedListOption[]>(() => calculatePlainItems(items ?? []).plainItems);
	const [searchValue, setSearchValue] = React.useState('');
	const [filteredItems, setFilteredItems] = React.useState<IGroupedListOption[]>(plainItems);
	const [hiddenGroups, setHiddenGroups] = React.useState<string[][]>([]);

	const searchRef = React.useRef<HTMLInputElement>();

	function countItems(items: IGroupedListOption[]): number {
		return items.filter(item => !item.isGroup).length;
	}

	const itemMatchesSearch = useCallback(
		(searchValue: string, item: IGroupedListOption, compareType: ComparatorTypes): boolean => {
			// hide groups on search
			if (searchValue && item.isGroup) {
				return false;
			}

			return (
				item.value !== undefined &&
				(compareBy ? compareBasedOnCompareBy(compareBy, compareType, item, searchValue) : compare(compareType, item.label, searchValue))
			);
		},
		[compareBy]
	);

	function collapseGroup(group: IGroupedListOption): void {
		const hiddenGroupIndex = hiddenGroups.findIndex(groupParents => isSameGroupByParentsList(group, groupParents));

		if (hiddenGroupIndex >= 0) {
			const newHiddenGroups = [...hiddenGroups];
			newHiddenGroups.splice(hiddenGroupIndex, 1);
			setHiddenGroups(newHiddenGroups);
		} else {
			if (group.parents) {
				setHiddenGroups([...hiddenGroups, [...group.parents, group.label]]);
			}
		}
	}

	const initialSelectedValue = React.useMemo(() => {
		function isSinglePreselected(item: IListOption): boolean {
			const itemValueString = String(item.value);

			return itemValueString === String(value) || itemValueString === String(defaultValue);
		}

		function isMultiplePreselected(item: IListOption, valuesObject: Record<string, boolean>): boolean {
			const values = isValueArray ? value : String(value).split(',');
			const defaultValues = defaultValue ? String(defaultValue).split(',') : [];

			if (isValueArray) {
				return valuesObject[String(item.value)] || defaultValues.includes(String(item.value));
			}

			return values.includes(String(item.value)) || defaultValues.includes(String(item.value));
		}

		if (value === undefined && defaultValue === undefined) {
			return multiple ? [] : undefined;
		}

		const isValueArray = Array.isArray(value);
		const definedValuesOnly = plainItems.filter(item => item.value !== undefined);

		if (multiple) {
			let valuesObject: Record<string, boolean> = {};

			if (isValueArray && value.length === definedValuesOnly.length) {
				return value;
			}

			if (isValueArray && value.length > 0) {
				valuesObject = Object.fromEntries(value.map(v => [v, true]));
			}

			const values = definedValuesOnly.filter(item => isMultiplePreselected(item, valuesObject)).map(item => item.value) as Array<string | number>;

			return values || [];
		} else {
			const newValue = definedValuesOnly.find(item => isSinglePreselected(item))?.value;
			return newValue !== undefined ? newValue : undefined;
		}
	}, [multiple, plainItems, value, defaultValue]);

	const valuesMapped = React.useMemo(() => {
		const entries = plainItems?.filter(item => item.value !== undefined).map(item => [item.value, item]);
		return Object.fromEntries(entries);
	}, [plainItems]);

	const getItemByValue = React.useCallback(
		(value: string | number | undefined): IGroupedListOption | undefined => {
			if (value === undefined) {
				return undefined;
			}

			return valuesMapped[value];
		},
		[valuesMapped]
	);

	const getGroupItems = React.useCallback((group: IGroupedListOption, items: IGroupedListOption[]): IGroupedListOption[] => {
		return items.filter(item => !item.isGroup && isChild(item, group));
	}, []);

	function onSearchInputChange(event: ChangeEvent<HTMLInputElement>) {
		const searchString = event.target.value;
		setSearchValue(searchString);
		paginationOptions?.searchQuery(searchString.trim());
	}

	const onClearSearchInput = () => {
		setSearchValue('');
		paginationOptions?.searchQuery('');
	};

	// prepare plain items
	React.useEffect(() => {
		if (!updatePlainItemsRef.current) {
			updatePlainItemsRef.current = true;
			return;
		}

		if (items === undefined) {
			setPlainItems([]);
			return;
		}

		const { plainItems: calculatedItems } = calculatePlainItems(items ?? []);
		setPlainItems(calculatedItems);
	}, [updatePlainItemsRef, items, items?.length]);

	// perform search filtering
	React.useEffect(() => {
		if (!updateFilteredItemsRef.current) {
			updateFilteredItemsRef.current = true;
		}

		if (searchValue.trim() === '') {
			setFilteredItems(plainItems || []);
			return;
		}

		// find items that:
		// 1. is group (groups are not filtered by search)
		// OR
		// 2. matches search (taking in count the 'exclude' value)
		const allFilteredItems = plainItems.filter(item => item.isGroup || exclude !== itemMatchesSearch(searchValue, item, compareType)) || [];

		// remove groups with no visible items
		const newItems = allFilteredItems.filter(item => (item.isGroup ? getGroupItems(item, allFilteredItems).length > 0 : true));

		setFilteredItems(newItems);
	}, [updateFilteredItemsRef, getGroupItems, exclude, compareType, plainItems, searchValue, hiddenGroups, itemMatchesSearch]);

	const filteredItemsToDisplay = (filteredItems as IGroupedListOption[]).filter(item => !isInHiddenGroup(item, hiddenGroups));
	const isSearchVisible = typeof searchable === 'number' ? filteredItems.length >= searchable : searchable;

	return {
		filteredItems,
		filteredItemsToDisplay,
		searchValue,
		isSearchVisible,
		setSearchValue,
		searchRef,
		getGroupItems,
		initialSelectedValue,
		hiddenGroups,
		getItemByValue,
		collapseGroup,
		itemsNumber: countItems(plainItems),
		countItems,
		onSearchInputChange,
		onClearSearchInput
	};
}
