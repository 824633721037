import React, { CSSProperties, PropsWithChildren, MouseEvent, ChangeEventHandler, KeyboardEventHandler, Ref, KeyboardEvent, MouseEventHandler } from 'react';
import { Dropdown } from 'react-bootstrap';
import DrillDownBreadCrumbs from './DrillDownBreadCrumbs';
import { Search } from '../forms';
import { DrillDownGroupItem, DrillDownItem, DrillDownItemBreadCrumbs } from './types';

export type DrillDownItemClickHandler = (event: MouseEvent<HTMLElement>, item: DrillDownItem) => void;
export type DrillDownBreadcrumbClickHandler = (e: MouseEvent, item?: DrillDownGroupItem) => void;
export type DrillDownMouseMoveHandler = (event: MouseEvent<HTMLLIElement>, index: number) => void;
export type DrillDownKeyDownHandler = (event: KeyboardEvent<HTMLElement>, item?: DrillDownItem) => void;

export interface DrillDownMenuProps extends PropsWithChildren {
	ref?: Ref<HTMLDivElement>;
	inputRef?: Ref<HTMLInputElement>;
	onInputChange?: ChangeEventHandler<HTMLInputElement> | undefined;
	onClear?: () => void;
	onItemClicked?: DrillDownItemClickHandler;
	onInputKeyDown?: KeyboardEventHandler<HTMLElement>;
	onMouseMove?: DrillDownMouseMoveHandler;
	onKeyDown?: DrillDownKeyDownHandler;
	inputValue?: string;
	placeholder?: string;
	style?: CSSProperties;
	className?: string;
	'aria-label'?: string;
	ariaActivedescendant?: string;
	/**
	 * group items for bread crumb display
	 */
	breadCrumbs?: DrillDownItemBreadCrumbs;
	onBreadCrumbClick?: DrillDownBreadcrumbClickHandler;
	menuId?: string;
	multiple?: boolean;
	renderOnMount?: boolean;
}

export const DefaultCustomDropDownMenuAriaLabel = 'Search';

// https://react-bootstrap.github.io/components/dropdowns/#custom-dropdown-components
export const CustomDropDownMenu = React.forwardRef<HTMLDivElement, DrillDownMenuProps>(
	(
		{
			inputRef,
			onInputChange,
			onKeyDown,
			inputValue,
			placeholder = 'Type to filter...',
			style,
			className,
			'aria-label': ariaLabel = DefaultCustomDropDownMenuAriaLabel,
			ariaActivedescendant,
			breadCrumbs,
			onBreadCrumbClick,
			menuId,
			multiple,
			children,
			onClear
		},
		ref
	) => {
		return (
			<div ref={ref} data-testid={process.env.NODE_ENV === 'test' ? `${menuId}-dropdown` : undefined} style={style} className={className} tabIndex={-1}>
				<Search
					groupClassName="drilldown-search"
					ref={inputRef}
					aria-label={ariaLabel}
					placeholder={placeholder}
					onKeyDown={onKeyDown}
					onChange={onInputChange}
					value={inputValue}
					aria-autocomplete="list"
					aria-controls={menuId}
					aria-activedescendant={ariaActivedescendant}
					onClear={onClear}
				/>
				<DrillDownBreadCrumbs items={breadCrumbs} onBreadCrumbClick={onBreadCrumbClick} />
				<ul id={menuId} className="drilldown-menu" role="tree" aria-multiselectable={multiple}>
					{children}
				</ul>
			</div>
		);
	}
);
CustomDropDownMenu.displayName = 'CustomDropDownMenu';

const DrillDownMenu = React.forwardRef<HTMLDivElement, DrillDownMenuProps>(({ renderOnMount = true, onMouseMove, ...props }, ref) => {
	// force renderOnMount for accessibility
	return (
		<Dropdown.Menu ref={ref} as={CustomDropDownMenu} renderOnMount={renderOnMount} onMouseMove={onMouseMove as MouseEventHandler<HTMLElement>} {...props} />
	);
});
DrillDownMenu.displayName = 'DrillDownMenu';

export default DrillDownMenu;
