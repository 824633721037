import React from 'react';
import { Icon, IconLoading } from '@optic-delight/icons';
import { ButtonProps } from './types';
import { stringify } from '../../helpers';
import { useElementIds } from '../../helpers/hooks/useElementIds';

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	(
		{
			hiddenLabel,
			variant = 'default',
			block,
			active,
			children,
			icon: iconName = null,
			iconTestId,
			iconRole,
			iconDirection = 'prepend',
			className,
			disabled,
			size,
			loading = false,
			href,
			as: Component = href ? 'a' : 'button',
			type = Component !== 'button' ? undefined : 'button',
			'aria-label': ariaLabel,
			pill = false,
			id,
			...props
		},
		ref
	): JSX.Element => {
		const { id: innerId } = useElementIds({ prefix: 'button', id });

		let icon;
		if (loading) {
			icon = <IconLoading data-testid="icon-loading" role="status" />;
		} else {
			icon = iconName && <Icon icon={iconName} role={iconRole} data-testid={iconTestId} fixedWidth={true} />;
		}
		const isDisabled = disabled || loading;

		const classes = [
			className,
			`btn btn-${variant}`,
			icon && 'd-inline-flex gap-1 align-items-center',
			active && 'active',
			isDisabled && 'disabled',
			size && `btn-${size}`,
			pill && 'rounded-pill'
		]
			.filter(Boolean)
			.join(' ');

		if (hiddenLabel) {
			if (children && !ariaLabel) {
				ariaLabel = stringify(children);
			}
			children = null;
		}

		const button = (
			<Component
				href={href}
				aria-label={ariaLabel}
				className={classes}
				disabled={isDisabled}
				ref={ref}
				type={type}
				role={Component === 'a' ? 'button' : undefined}
				id={innerId}
				{...props}>
				{iconDirection === 'prepend' && icon}

				{children}

				{iconDirection === 'append' && icon}

				{loading && <div className="d-none">Loading...</div>}
			</Component>
		);

		return block ? <div className="d-grid">{button}</div> : button;
	}
);
Button.displayName = 'Button';

export default Button;
