import React, { FC, useEffect, useRef } from 'react';
import { prepareCustomer } from './utility/prepare';
import useTokenManager from '../rest-client/hooks/useTokenManager';
import { CustomerContextProps, CustomerProviderProps } from './types';

export const CustomerContext = React.createContext<CustomerContextProps>(undefined);
CustomerContext.displayName = 'CustomerContext';

const storageKeyPrefix = 'live-kinnect';

const CustomerProvider = ({ customer, children }: CustomerProviderProps) => {
	const { Provider } = CustomerContext;
	const customerRef = useRef(prepareCustomer(customer));
	const tokenManager = useTokenManager(`${storageKeyPrefix}-${customerRef.current.csrf}`);

	useEffect(() => {
		// remove preexisting localStorage items not belonging to this csrf token
		Object.keys(window.localStorage)
			.filter(key => key !== `${storageKeyPrefix}-${customerRef.current.csrf}` && key.startsWith(storageKeyPrefix))
			.forEach(key => window.localStorage.removeItem(key));
	}, [customerRef]);

	return (
		<Provider
			value={{
				customer: customerRef.current,
				tokenManager
			}}>
			{children}
		</Provider>
	);
};

export default CustomerProvider;

export const withCustomer = (Component: React.ElementType) => {
	const CustomerComponent: FC<CustomerProviderProps> = ({ customer, ...props }) => {
		return (
			<CustomerProvider customer={customer}>
				<Component {...props} />
			</CustomerProvider>
		);
	};

	return CustomerComponent;
};
