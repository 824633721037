import React from 'react';

import { IconEllipsis } from '@optic-delight/icons';
import { Dropdown as BootstrapDropdown } from 'react-bootstrap';
import ActionButtonDropdownItem from './ActionButtonDropdownItem';
import ActionButtonDropdownDivider from './ActionButtonDropdownDivider';
import { useElementIds } from '../../../helpers/hooks/useElementIds';
import { ActionButtonDropDownProps } from './types';

const ActionButtonDropdown = (props: ActionButtonDropDownProps): JSX.Element => {
	const { children, toggleIconTestId, align = 'end', id, toggleProps = {}, ...wrapperProps } = props;
	const { id: innerId } = useElementIds({ prefix: 'action-button-dropdown', id });

	return (
		<BootstrapDropdown id={innerId} className="dropdown-action-btn" {...wrapperProps}>
			<BootstrapDropdown.Toggle variant="default" size="sm" {...toggleProps} aria-label={toggleProps['aria-label'] ?? 'Actions'}>
				<IconEllipsis data-testid={toggleIconTestId} />
			</BootstrapDropdown.Toggle>
			<BootstrapDropdown.Menu align={align}>{children}</BootstrapDropdown.Menu>
		</BootstrapDropdown>
	);
};

ActionButtonDropdown.Item = ActionButtonDropdownItem;
ActionButtonDropdown.Divider = ActionButtonDropdownDivider;
ActionButtonDropdown.displayName = 'ActionButtonDropdown';

export default ActionButtonDropdown;
