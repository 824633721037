import { HttpResponse, http } from '@optic-delight/benefitsolver-services';
import { delay } from 'msw';

const duration = 500;

export const handlers = [
	// Handles a GET /users request
	http.get(`${process.env.REACT_APP_HCWEB_API_URL}/users`, async () => {
		await delay(duration);
		return HttpResponse.json(
			[
				{
					num: 1,
					name: 'Foo',
					email: 'foo@test.io',
					address1: '111 main st',
					address2: undefined,
					city: 'Des Moines',
					state: 'IA',
					zip: '50313',
					status: 'yes',
					date: '01/01/1970'
				},
				{
					num: 2,
					name: 'Bar',
					email: 'bar@test.io',
					address1: '222 main st',
					address2: undefined,
					city: 'Des Moines',
					state: 'IA',
					zip: '50313',
					status: 'yes',
					date: '02/01/1971'
				},
				{
					num: 3,
					name: 'Bin',
					email: 'bin@test.io',
					address1: '333 main st',
					address2: undefined,
					city: 'Des Moines',
					state: 'IA',
					zip: '50313',
					status: 'yes',
					date: '03/01/1972'
				},
				{
					num: 4,
					name: 'Baz',
					email: 'baz@test.io',
					address1: '444 main st',
					address2: undefined,
					city: 'Des Moines',
					state: 'IA',
					zip: '50313',
					status: 'yes',
					date: '04/01/1973'
				},
				{
					num: 5,
					name: 'Buzz',
					email: 'buzz@test.io',
					address1: '555 main st',
					address2: undefined,
					city: 'Des Moines',
					state: 'IA',
					zip: '50313',
					status: 'yes',
					date: '05/01/1974'
				}
			],
			{ status: 200 }
		);
	}),
	http.get(`${process.env.REACT_APP_HCWEB_API_URL}/companies`, async () => {
		await delay(duration);
		return HttpResponse.json([{ id: 0 }], { status: 200 });
	})
];
