import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { DrillDownItemBreadCrumbs } from './types';
import { DrillDownBreadcrumbClickHandler } from './DrillDownMenu';

export interface DrillDownBreadCrumbsProps {
	items?: DrillDownItemBreadCrumbs;
	onBreadCrumbClick?: DrillDownBreadcrumbClickHandler;
}

const DrillDownBreadCrumbs = ({ items = [], onBreadCrumbClick }: DrillDownBreadCrumbsProps) => {
	return items.length > 1 ? (
		<Breadcrumb>
			<Breadcrumb.Item onClick={onBreadCrumbClick} linkProps={{ tabIndex: -1, className: 'nav' }}>
				Root
			</Breadcrumb.Item>
			{items.map((item, index) => {
				if (index === 0) {
					// zero index is the root level items, do NOT display below
					return null;
				}

				const isActive = items.length === index + 1;
				const isStringItem = typeof item === 'string';
				const itemContent = isStringItem ? item : item.content;

				return (
					<Breadcrumb.Item
						key={`crumb_${index}`}
						active={isActive}
						title={!isActive ? itemContent : undefined}
						onClick={e => onBreadCrumbClick?.(e, item)}
						linkProps={{ tabIndex: -1, className: 'nav' }}>
						{isActive ? itemContent : '..'}
					</Breadcrumb.Item>
				);
			})}
		</Breadcrumb>
	) : null;
};
DrillDownBreadCrumbs.displayName = 'DrillDownBreadCrumbs';

export default DrillDownBreadCrumbs;
