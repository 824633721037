import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { Button, ButtonVariant } from '../button';

export const DrillDownInitialToggleLabel = 'Select...';

export interface DrillDownToggleProps {
	/**
	 * Toggle label text.  Set from `useDrillDown` `toggleLabel` | `initialToggleLabel`
	 */
	label?: string;
	/**
	 * button variants
	 */
	variant?: ButtonVariant;
	/**
	 * display as block button
	 */
	block?: boolean;
	/**
	 * switches 'DrillDown' component to loading state
	 */
	loading?: boolean;
}

const DrillDownToggle = ({ loading, label = DrillDownInitialToggleLabel, ...props }: DrillDownToggleProps) => {
	return loading ? (
		<Button iconDirection="append" disabled loading={loading}>
			{label}
		</Button>
	) : (
		<Dropdown.Toggle as={Button} aria-haspopup={true} {...props}>
			{label}
		</Dropdown.Toggle>
	);
};
DrillDownToggle.displayName = 'DrillDownToggle';

export default DrillDownToggle;
