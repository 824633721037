import React from 'react';
import { UseRowSelectionProps } from '../types';
import { CellProps, ColumnDef, ColumnHeaderProps, TableInstance } from '../../types';
import { Checkbox } from '@optic-delight/ui-framework';

export const rowSelectionClass = 'table-col-check';
export const useRowSelectionColumn = <TData = unknown, TValue = unknown>(
	props: Partial<UseRowSelectionProps<TData, TValue>> = {}
): ColumnDef<TData, TValue> => {
	const { id = 'selection', getHeaderProps, getCellProps, size } = props || {};

	const HeaderCheckbox = (headerProps: TableInstance<TData> & ColumnHeaderProps<TData, TValue>) => {
		if (props.disableSelectAll) return null;

		const { isAllRowsSelected, toggleAllRowsSelected } = headerProps;
		const notAllRowsSelected = headerProps.rows?.some(row => row.isSelected) && !isAllRowsSelected;

		const checkboxStyles: React.CSSProperties = {
			marginLeft: '0'
		};

		if (size === 'sm') {
			// when table size is `sm` bootstrap applies some styles to input which breaks checkbox style, and we reset them here
			checkboxStyles.marginTop = '0';
			checkboxStyles.minHeight = 'auto';
			checkboxStyles.padding = 'initial';
			checkboxStyles.fontSize = 'inherit';
		}

		return (
			<Checkbox
				id={`table-selector-${headerProps.header?.id ?? 'all'}`}
				className="p-0 mb-0"
				style={checkboxStyles}
				role="checkbox"
				checked={isAllRowsSelected}
				aria-checked={isAllRowsSelected}
				onChange={e => toggleAllRowsSelected(e.target.checked)}
				indeterminate={notAllRowsSelected}
				aria-label={`Toggle all current page rows ${isAllRowsSelected ? 'selected' : 'unselected'}`}
				// explicit removal of the title, as we are setting aria-label instead
				title={undefined}
				{...getHeaderProps?.(headerProps)}
			/>
		);
	};

	const CellCheckbox = (cellProps: CellProps<TData, TValue>) => {
		const { row } = cellProps;

		return (
			<Checkbox
				id={`table-row-selector-${cellProps.row.id ?? cellProps.row.index}`}
				className="p-0 mb-0"
				style={{ marginLeft: '0' }}
				role="checkbox"
				aria-checked={row.isSelected}
				{...row.getToggleRowSelectedProps(cellProps as CellProps<TData>)}
				aria-label={`Toggle row ${row.index + 1} ${row.isSelected ? 'selected' : 'unselected'}`}
				// explicit removal of the title, as we are setting aria-label instead
				title={undefined}
				{...getCellProps?.(cellProps)}
			/>
		);
	};

	return {
		id,
		Header: HeaderCheckbox,
		Cell: CellCheckbox,
		className: rowSelectionClass
	};
};
