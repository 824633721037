import React from 'react';
import { InputGroupProps } from './types';

export default function InputGroup({
	isInvalid,
	addOnRole,
	addOnPrepend: addOnPrependProp,
	addOnAppend: addOnAppendProp,
	size,
	className,
	embedded,
	...props
}: InputGroupProps) {
	const classes = ['input-group', isInvalid && 'is-invalid', size && `input-group-${size}`, embedded && 'input-group-embedded', className]
		.filter(Boolean)
		.join(' ');
	const addOnType = (addOnProp?: React.ReactNode) => {
		if (!addOnProp) {
			return;
		}

		const addOnArray: Array<React.ReactElement | string> = Array.isArray(addOnProp) ? addOnProp : [addOnProp];
		if (process.env.NODE_ENV === 'development' && embedded && addOnArray.length > 1) {
			// eslint-disable-next-line no-console
			console.error(`<${InputGroup.name}> does not support an array of addons when 'embedded=true'`);
		}

		return addOnArray
			.filter((_element, index) => (embedded && index === 0) || !embedded)
			.map((element, index) => {
				if (typeof element === 'string' || !element.props.children) {
					return (
						<span key={index} className="input-group-text">
							{element}
						</span>
					);
				} else if (embedded) {
					return React.cloneElement(element, { className: 'input-group-text', key: element.key || index });
				}
				return React.cloneElement(element, { key: element.key || index });
			});
	};

	const addOnPrepend = addOnType(addOnPrependProp);
	const addOnAppend = addOnType(addOnAppendProp);

	if (addOnAppend || addOnPrepend) {
		return (
			<div className={classes} role={addOnRole} {...props}>
				{addOnPrepend}

				{props.children}

				{addOnAppend}
			</div>
		);
	}

	return props.children;
}
