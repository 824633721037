export const isDeepEqual = <T>(first: T, second: T): boolean => {
	/*
	 * If first and second are the same type and have the same value
	 * Useful if strings or other primitive types are compared
	 */
	if (first === second) {
		return true;
	} else if ((first && !second) || (!first && second)) {
		return false;
	}

	// Try a quick compare by seeing if the length of properties are the same
	const firstProps = Object.getOwnPropertyNames(first);
	const secondProps = Object.getOwnPropertyNames(second);

	// Check different amount of properties
	if (firstProps.length !== secondProps.length) {
		return false;
	}

	// Go through properties of first object
	const firstRecord = first as Record<string, unknown>;
	const secondRecord = second as Record<string, unknown>;
	for (let i = 0; i < firstProps.length; i++) {
		const prop = firstProps[i];
		// Check the type of property to perform different comparisons
		switch (typeof firstRecord[prop]) {
			// If it is an object, descend for deep compare
			case 'object':
				if (!isDeepEqual(firstRecord[prop], secondRecord[prop])) {
					return false;
				}
				break;
			// @ts-expect-error intentional case fallthrough
			case 'number':
				// with JavaScript NaN != NaN so we need a special check
				if (isNaN(firstRecord[prop] as number) && isNaN(secondRecord[prop] as number)) {
					break;
				}
			// eslint-disable-next-line no-fallthrough
			default:
				if (firstRecord[prop] !== secondRecord[prop]) {
					return false;
				}
		}
	}

	return true;
};

export const envKeyEquals = (key: string, value: unknown) => {
	if (Object.prototype.hasOwnProperty.call(process.env, key)) {
		const envKeyValue = process.env[key];
		if (envKeyValue !== undefined) {
			const valueType = typeof value;
			switch (valueType) {
				case 'boolean':
					return envKeyValue === String(value);
				case 'number':
					try {
						const numericValue = parseFloat(envKeyValue);
						return numericValue.toString() === String(value);
					} catch (e) {
						return false;
					}
				case 'object':
					if (value === null) {
						return envKeyValue === 'null';
					} else if (value instanceof RegExp) {
						const lastSlash = envKeyValue.lastIndexOf('/');
						if (lastSlash > -1) {
							const pattern = envKeyValue.substring(1, lastSlash);
							const flags = envKeyValue.substring(lastSlash + 1, envKeyValue.length);
							const envKeyRegExp = new RegExp(pattern, flags);
							return envKeyRegExp.toString() === value.toString();
						}
						return false;
					}

					// assume JSON (works for Arrays as well), return false for anything else
					try {
						const parsedValue = JSON.parse(envKeyValue);
						if (Array.isArray(value) && Array.isArray(parsedValue)) {
							value.sort();
							parsedValue.sort();
						}
						return isDeepEqual(parsedValue, value);
					} catch (e) {
						return false;
					}
				case 'function':
					throw new Error('Unsupported equality match type "function"');
				case 'undefined':
					return envKeyValue === 'undefined' || !envKeyValue;
				default:
					return envKeyValue === value;
			}
		} else {
			return false;
		}
	}

	// key does not exist and value is undefined, then return true otherwise false
	return typeof value === 'undefined';
};

/*
 * This exists for the sole purpose of testing
 * To override the "current" date in tests, do something like below
 * ex) jest.spyOn(global.Date, 'now').mockImplementation(() => new Date('2020-01-15T11:01:58.135Z').valueOf());
 */
export const getCurrentDate = () => new Date(Date.now());

export const isFile = (data: Record<string, unknown>): boolean => {
	if (!data) {
		return false;
	}
	for (const key of Object.keys(data)) {
		const value = data[key];
		if ('File' in window && value instanceof File) {
			return true;
		}
	}
	return false;
};

export const generateUUID = (): string => {
	let date = new Date().getTime();
	const UUIDFormat = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';

	return UUIDFormat.replace(/[xy]/g, char => {
		const randomNum = (date + Math.random() * 16) % 16 | 0;
		date = Math.floor(date / 16);
		return (char === 'x' ? randomNum : (randomNum & 0x3) | 0x8).toString(16);
	});
};

export const getCookie = (cookieName: string): string => {
	const name = cookieName + '=';
	const cookies = window.document.cookie.split(';');
	for (let i = 0; i < cookies.length; i++) {
		let cookie = cookies[i];
		while (cookie.charAt(0) === ' ') {
			cookie = cookie.substring(1);
		}
		if (cookie.indexOf(name) === 0) {
			return cookie.substring(name.length, cookie.length);
		}
	}
	return '';
};

export const setCookie = (cookieName: string, cookieValue: string): void => {
	if (cookieName !== null && cookieValue !== null) {
		const date = new Date();
		date.setTime(date.getTime() + 60 * 60 * 1000);
		const expires = 'expires=' + date.toUTCString();
		window.document.cookie = cookieName + '=' + cookieValue + '; ' + expires + '; path=/';
	}
};
