import { useCallback, useEffect, useRef, useState } from 'react';
import { buildRegisterProps, callAll, fieldHasErrors, useCombinedRefs, Validation, Validator } from '../../../../helpers';
import { useLatestRef } from '../../../../helpers/hooks/useLatestRef';
import { inputDefaultProps } from '../../../../props/inputProps';
import { getInputModeProps } from '../../../../helpers/getInputModeProps';
import { useFormContext } from '../../form-element';
import { useElementIds } from '../../../../helpers/hooks/useElementIds';
import { GetGroupData, GetHelpblockData, GetLabelData, GetValidationData, UseFormGroupValidationProps } from '../types/hooks';
import { InputProps } from '../../types';

const useFormGroupValidation = <TFieldValue = string, URef extends HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement = HTMLInputElement>(
	props: UseFormGroupValidationProps<TFieldValue, URef>
) => {
	const {
		dirty,
		validators = [],
		helptext,
		tooltip,
		groupClassName = inputDefaultProps.groupClassName,
		id,
		label,
		'aria-label': ariaLabel,
		errorLabel,
		inline,
		ref,
		...inputProps
	} = props;
	const isRequired = inputProps.disabled || inputProps.readOnly ? false : inputProps.required;
	const formContext = useFormContext();
	inputProps.disabled = formContext?.formState?.isDisabled || inputProps.disabled;
	const { id: innerId } = useElementIds({ prefix: 'form-control', id });

	const getValidators = useCallback((): Validator<TFieldValue>[] | undefined => {
		if (!validators.length) {
			if (props.type === 'numeric') {
				return [Validation.integer] as Validator<TFieldValue>[];
			} else if (props.type === 'decimal') {
				return [Validation.numeric] as Validator<TFieldValue>[];
			}
		}
		return validators;
	}, [props.type, validators]);

	const latest = useLatestRef({
		dirty,
		validators: getValidators(),
		helptext,
		tooltip,
		groupClassName,
		id: innerId,
		label,
		ariaLabel,
		errorLabel,
		inline,
		ref,
		inputProps,
		isRequired,
		helpblockId: helptext ? innerId + '_helptext' : undefined,
		formContext,
		registerProps: buildRegisterProps(
			formContext.register,
			{
				name: inputProps.name,
				required: isRequired,
				disabled: inputProps.disabled,
				validators: getValidators()
			},
			inputProps
		)
	});
	const inputRef = useCombinedRefs<URef>(latest.current.registerProps.ref, ref);
	const [isInvalid, setIsInvalid] = useState(dirty && latest.current.isRequired);

	// triggers the invalid state
	const firstValidityUpdate = useRef(dirty);
	useEffect(() => {
		if (firstValidityUpdate.current) {
			firstValidityUpdate.current = false;
			return;
		}

		setIsInvalid(fieldHasErrors(latest.current.inputProps.name, formContext.formState?.errors));
	}, [formContext.formState, latest]);

	const getGroupProps = useCallback(
		(overrideProps: { [key: string]: unknown } = {}): GetGroupData => {
			const { className = latest.current.groupClassName, ...rest } = overrideProps;
			return {
				controlId: latest.current.id,
				className: `${latest.current.inline ? 'row gx-2 ' : ''}${className || ''}`,
				...rest
			};
		},
		[latest]
	);

	const getLabelProps = useCallback(
		(overrideProps = {}): GetLabelData => {
			return {
				id: latest.current.id && latest.current.label ? latest.current.id + '_label' : undefined,
				children: latest.current.label,
				required: latest.current.inputProps.required,
				tooltip: latest.current.tooltip,
				column: latest.current.inline,
				htmlFor: latest.current.id,
				...overrideProps
			};
		},
		[latest]
	);

	const getInputProps = useCallback(
		(overrideProps = {}): InputProps => {
			const { onChange, onBlur, ...mergedProps } = { ...latest.current.inputProps, ...overrideProps };
			return {
				id: latest.current.id,
				'aria-required': latest.current.inputProps.required,
				'aria-invalid': isInvalid,
				'aria-label': latest.current.label ? undefined : latest.current.ariaLabel || undefined,
				'aria-describedby': latest.current.helpblockId || undefined,
				onChange: callAll(onChange, latest.current.registerProps.onChange),
				onBlur: callAll(onBlur, latest.current.registerProps.onBlur),
				...mergedProps,
				...getInputModeProps(latest.current.inputProps.type || '', latest.current.inputProps.inputMode)
			};
		},
		[isInvalid, latest]
	);

	const getHelpblockProps = useCallback(
		(overrideProps = {}): GetHelpblockData => {
			return {
				id: latest.current.helpblockId,
				children: latest.current.helptext,
				...overrideProps
			};
		},
		[latest]
	);

	const getValidation = useCallback((): GetValidationData<TFieldValue> => {
		return {
			isRequired: latest.current.isRequired,
			isInvalid,
			setIsInvalid,
			formContext: latest.current.formContext,
			validators: latest.current.validators,
			inputName: latest.current.inputProps.name,
			displayLabel: latest.current.label || latest.current.ariaLabel || latest.current.errorLabel || ''
		};
	}, [isInvalid, latest]);

	return {
		getGroupProps,
		getLabelProps,
		getInputProps,
		getHelpblockProps,
		inputRef,
		validation: getValidation()
	};
};

export default useFormGroupValidation;
