import React, { ChangeEvent, ComponentProps, useState } from 'react';

export const toggleSwitchTestId = 'toggle-switch';

export type ToggleSize = 'sm' | 'lg';

export type ToggleVariant = 'primary' | 'secondary' | 'danger' | 'warning';

interface SwitchProps extends Omit<ComponentProps<'input'>, 'size'> {
	on?: boolean;
	defaultOn?: boolean;
	size?: ToggleSize;
	variant?: ToggleVariant;
}

const Switch = React.forwardRef<HTMLInputElement, SwitchProps>(
	({ on, defaultOn = false, onChange, className, 'aria-label': ariaLabel = 'Toggle', 'aria-pressed': ariaPressed, size, variant, ...props }, ref) => {
		const isControlled = on !== undefined;
		const [internalOn, setInternalOn] = useState(defaultOn);
		const checked = isControlled ? on : internalOn;

		const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
			if (!isControlled) {
				setInternalOn(e.target.checked);
			}

			onChange?.(e);
		};

		const btnClassName = ['btn-toggle', variant && `btn-toggle-${variant}`, size && `btn-toggle-${size}`, checked && 'active'].filter(Boolean).join(' ');

		return (
			<label aria-label={ariaLabel} className={className}>
				<input ref={ref} {...props} className="toggle-input" type="checkbox" checked={checked} onChange={handleChange} />
				<span className={btnClassName} aria-pressed={ariaPressed} data-testid={process.env.NODE_ENV === 'test' ? toggleSwitchTestId : undefined} />
			</label>
		);
	}
);

Switch.displayName = 'Switch';

export default Switch;
