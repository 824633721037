import React from 'react';
import useNavigation from './hooks/useNavigation';
import { NavigationItemProps } from './types';
import { useActiveItem } from './hooks/useActiveItem';
import { useElementIds } from '../../helpers/hooks/useElementIds';

export function NavigationItem<As extends React.ElementType>({
	as: Component = 'a',
	eventKey,
	children,
	onClick,
	href,
	id,
	...props
}: NavigationItemProps<As>): JSX.Element {
	const { onSelect, activeKey, setActiveContent } = useNavigation();
	const { isActive, getLinkProps, eventKey: itemKey } = useActiveItem({ eventKey, href, activeKey, setActiveContent });
	const { id: innerId } = useElementIds({ prefix: 'navigation-item', id });

	/*
	 * hack to allow react-router navigation, this prevents default show/hide/etc logic from working as expected.
	 * Implementing this way means the Navigation component must be completely re-mounted when navigation occurs to close the dropdown
	 */
	const isRouteNavigation = Object.prototype.hasOwnProperty.call(props, 'to');
	const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
		onSelect(isRouteNavigation ? undefined : itemKey, event);
		onClick?.(event);
	};

	const handleMouseDown = (event: React.MouseEvent<HTMLAnchorElement>) => {
		onSelect(isRouteNavigation ? undefined : itemKey, event);
	};

	const linkProps = getLinkProps({
		...(href ? {} : { role: 'button', tabIndex: 0 }),
		...props
	});

	const navLinkClasses = ['nav-link', !isRouteNavigation && isActive() && 'active'].filter(Boolean).join(' ');

	return (
		<li className="nav-item">
			<Component id={innerId} className={navLinkClasses} onMouseDown={handleMouseDown} onClick={handleClick} {...linkProps}>
				{children}
			</Component>
		</li>
	);
}
