import React from 'react';
import { isValueUnset, stringify, Validator } from '../../../helpers';
import {
	ComboboxItem,
	ComboboxItemContent,
	ComboboxItemObject,
	ComparatorTypes,
	FlatComboboxItem,
	FlatComboboxItemObject,
	GetItemsByContent,
	NormalizedItemsObject
} from './types';
import { CompareByProp, IGroupedListOption, IListOption } from '../select';

export const isNotNullObject = (obj: unknown): obj is Record<string, unknown> => {
	return typeof obj === 'object' && obj !== null;
};

// ItemObject Predicate
export const isItemObject = (obj: unknown): obj is ComboboxItemObject => {
	return typeof obj === 'object' && obj !== null;
};

// flattens combobox items into a single level array
export const flattenItems = (items: ComboboxItem[] = [], group: ComboboxItemContent | undefined = undefined): FlatComboboxItem[] => {
	return items.reduce((accumulator: FlatComboboxItem[], item): FlatComboboxItem[] => {
		if (isItemObject(item)) {
			if (item.group) {
				accumulator.push(...flattenItems(item.group, item.content));
			} else {
				const itemCopy: FlatComboboxItemObject = {
					content: item.content,
					value: item.value
				};
				if (group) {
					itemCopy['group'] = group;
				}
				accumulator.push(itemCopy);
			}
		} else if (typeof item === 'string' || typeof item === 'number') {
			accumulator.push(item.toString());
		}
		return accumulator;
	}, []);
};

// converts combobox items into a standardized format
export const normalizeItems = (items: ComboboxItem[] = []) => {
	return items.reduce((accumulator: NormalizedItemsObject[], item) => {
		if (item) {
			if (Object.prototype.hasOwnProperty.call(item, 'group') && isItemObject(item)) {
				// opt group - recursively normalize items within the group
				if (Array.isArray(item.group)) {
					accumulator.push({
						content: item.content || '',
						group: normalizeItems(item.group)
					});
				}
			} else if (isItemObject(item) && (item.content || item.value)) {
				const content = item.content || item.value || '';
				if (content) {
					accumulator.push({
						content,
						value: stringify(item.value ?? content)
					});
				}
			} else if (typeof item === 'string' || typeof item === 'number' || React.isValidElement(item)) {
				// string item - set text and value to the string
				accumulator.push({
					content: item,
					value: stringify(item)
				});
			}
		}
		return accumulator;
	}, []);
};

export const comparatorTypes = {
	exact: 'exact',
	inclusive: 'include',
	exclusive: 'exclude',
	startsWith: 'startsWith',
	notStartsWith: 'notStartsWith'
} as const;

export const compare = (compareType: ComparatorTypes, a?: string, b?: string) => {
	if (!a || !b) {
		return false;
	}

	switch (compareType) {
		case comparatorTypes.exact:
			return a.toLowerCase() === b.toLowerCase();
		case comparatorTypes.exclusive:
			return !a.toLowerCase().includes(b.toLowerCase());
		case comparatorTypes.inclusive:
			return a.toLowerCase().includes(b.toLowerCase());
		case comparatorTypes.notStartsWith:
			return !a.toLowerCase().startsWith(b.toLowerCase());
		default:
			return a.toLowerCase().startsWith(b.toLowerCase());
	}
};

export const compareBasedOnCompareBy = (compareBy: CompareByProp, compareType: ComparatorTypes, item: IGroupedListOption, searchValue: string) => {
	if (typeof compareBy === 'function') {
		return compareBy(item, searchValue);
	}

	const itemLabel = item.label;
	const itemValue = item.value?.toString() ?? '';

	switch (compareBy) {
		case 'value':
			return compare(compareType, itemValue, searchValue);
		case 'both':
			return compare(compareType, itemLabel, searchValue) || compare(compareType, itemValue, searchValue);
		case 'name':
		default:
			return compare(compareType, itemLabel, searchValue);
	}
};

// gets combobox items by string value
export const getItemsByContent: GetItemsByContent = (items = [], value = '', filterType = comparatorTypes.startsWith): ComboboxItem[] => {
	return items.reduce((accumulator: ComboboxItem[], item): ComboboxItem[] => {
		if (isItemObject(item)) {
			// account for normalized object array
			if (item.group) {
				const reducedGroupItems = getItemsByContent(item.group, value);
				if (reducedGroupItems.length > 0) {
					accumulator.push({
						...item,
						group: reducedGroupItems
					});
				}
			} else if (compare(filterType, stringify(item.content), value.toString())) {
				accumulator.push(item);
			}
		} else if ((typeof item === 'string' || typeof item === 'number') && compare(filterType, item.toString(), value.toString())) {
			// account for simple string or number array
			accumulator.push(item);
		}

		return accumulator;
	}, []);
};

export const itemToString = (
	item: string | number | Record<string, unknown> | IGroupedListOption | IListOption | null,
	property: string = 'content'
): string => {
	if (item === null) {
		return '';
	}

	return isNotNullObject(item) ? stringify(item[property]) : item?.toString() || '';
};

export const toDelimitedListValidation = (validators: Validator[], delimiter = '|') => {
	return validators.map(validator => {
		const originalValidateFn = validator.validate;
		return {
			...validator,
			validate: (value: string) => {
				return (
					isValueUnset(value) ||
					value.split(delimiter).reduce((accumulator, aValue) => {
						return accumulator && originalValidateFn(aValue);
					}, true)
				);
			}
		};
	});
};
