import { Dispatch, RefObject, SetStateAction } from 'react';
import { useSelect } from 'downshift';
import { IGroupedListOption, UseSearchableItemsListData, UseSelectPaginationData } from '../types';
import { selectInputReducerUtils, updateSelectReducerChanges } from '../utility';
import { GetValidationData } from '../../shared/types/hooks';

interface UseDownshiftSelectProps<TFieldValue extends string> {
	id?: string;
	isOpen: boolean;
	searchableListProps: UseSearchableItemsListData;
	downshiftMenuRef: RefObject<HTMLUListElement>;
	inputRef: RefObject<HTMLInputElement>;
	value: string | number | undefined;
	validation: GetValidationData<TFieldValue>;
	paginationOptions: UseSelectPaginationData | undefined;
	collapseGroup: (group: IGroupedListOption) => void;
	setIsDropdownOpen: Dispatch<SetStateAction<boolean>>;
	forceFocusSearch: () => void;
}

export const useDownshiftSelect = <TFieldValue extends string>(props: UseDownshiftSelectProps<TFieldValue>) => {
	const { downshiftMenuRef, validation, inputRef, value, isOpen, searchableListProps, paginationOptions, setIsDropdownOpen, forceFocusSearch } = props;
	const { filteredItemsToDisplay, filteredItems, setSearchValue, searchValue, collapseGroup, searchRef, initialSelectedValue } = searchableListProps;

	const downshiftProps = useSelect({
		id: props.id,
		items: filteredItemsToDisplay || [],
		isOpen: props.isOpen,
		[typeof value !== 'undefined' ? 'selectedItem' : 'initialSelectedItem']:
			filteredItems.find(item => String(item.value) === String(initialSelectedValue)) || null,
		stateReducer: (state, actionAndChanges) => {
			const { changes, type } = actionAndChanges;

			const checkHighlightAndGroupstatus = () => {
				const shouldHighlightElement = state.highlightedIndex === -1 && document.activeElement?.tagName !== 'BUTTON';
				const isGroup = changes.selectedItem?.isGroup || false;
				const isSelectedItemGroup = changes.selectedItem && isGroup;

				return { shouldHighlightElement, isGroup, isSelectedItemGroup };
			};

			const { shouldHighlightElement, isGroup, isSelectedItemGroup } = checkHighlightAndGroupstatus();

			switch (type) {
				case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
					// without this code downshift don't let us put spaces in our search input,
					// but we use it only if no one item is currently highlighted
					if (shouldHighlightElement) {
						forceFocusSearch();
						setSearchValue(searchValue + ' ');
						return updateSelectReducerChanges(changes, true, state.highlightedIndex);
					}

					if (isSelectedItemGroup) {
						collapseGroup(changes.selectedItem as IGroupedListOption);
						changes.selectedItem = state.selectedItem;
						return updateSelectReducerChanges(changes, true, state.highlightedIndex);
					}

					if (document.activeElement?.tagName === 'BUTTON') {
						downshiftMenuRef.current?.focus();
					}

					return updateSelectReducerChanges(changes, false, state.highlightedIndex);

				case useSelect.stateChangeTypes.MenuKeyDownEnter:
				case useSelect.stateChangeTypes.ItemClick:
					if (isSelectedItemGroup) {
						collapseGroup(changes.selectedItem as IGroupedListOption);
						changes.selectedItem = state.selectedItem;
					}

					// When the user clicks to button in the list options, the list of options will be focused to make keyboard navigation accessible.
					if (document.activeElement?.tagName === 'BUTTON') {
						downshiftMenuRef.current?.focus();
					} else {
						setIsDropdownOpen(false);
					}

					return updateSelectReducerChanges(changes, isGroup, state.highlightedIndex);

				case useSelect.stateChangeTypes.MenuKeyDownEscape:
					setIsDropdownOpen(false);

					return selectInputReducerUtils.menuKeydownEscape(changes, searchValue, setSearchValue);

				case useSelect.stateChangeTypes.MenuBlur: {
					if (paginationOptions) {
						// When Select has pagination, we don't want to clear searchValue
						return selectInputReducerUtils.menuBlur(changes, searchRef);
					}
					return selectInputReducerUtils.menuBlur(changes, searchRef, setSearchValue);
				}

				case useSelect.stateChangeTypes.ToggleButtonKeyDownCharacter:
					return selectInputReducerUtils.toggleButtonKeydownCharacter(changes, setSearchValue);

				case useSelect.stateChangeTypes.MenuKeyDownCharacter:
					return selectInputReducerUtils.menuKeydownCharacter(changes, searchRef);

				case useSelect.stateChangeTypes.ToggleButtonClick:
					setIsDropdownOpen(prevState => !prevState);
					break;

				case useSelect.stateChangeTypes.MenuKeyDownArrowUp:
				case useSelect.stateChangeTypes.MenuKeyDownArrowDown:
					if (document.activeElement?.tagName === 'BUTTON') {
						(document.activeElement as HTMLButtonElement).blur();
						downshiftMenuRef.current?.focus();
					}
					break;

				case useSelect.stateChangeTypes.ToggleButtonKeyDownArrowUp:
				case useSelect.stateChangeTypes.ToggleButtonKeyDownArrowDown:
					setIsDropdownOpen(true);
			}

			return changes;
		},
		onSelectedItemChange: ({ selectedItem: item }) => {
			if (!item || item.value === downshiftProps.selectedItem?.value) {
				return;
			}

			validation.setIsInvalid(validation.isRequired && item?.value === undefined);

			const element = inputRef?.current as unknown as HTMLInputElement;

			const setValueFn = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')?.set;
			setValueFn?.call(element, item.value);

			const event = new Event('input', { bubbles: true });
			element.dispatchEvent(event);

			if (isOpen) {
				setIsDropdownOpen(false);
			}
		}
	});

	return downshiftProps;
};
