import React, { useEffect } from 'react';
import { ActionButtonDropdown, Button } from '@optic-delight/ui-framework';
import { Datatable, Expander, Row } from '@optic-delight/ui-datatable';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { User } from './types';

function Actions({ row }: { row: Row<User> }) {
	const navigate = useNavigate();

	const editUser = (): void => {
		navigate(`/modify/${row.original.num}`, {
			state: row.original
		});
	};
	return (
		<ActionButtonDropdown id={`actions-${row.original.num}`} title={`${row.original.name} Actions`}>
			<ActionButtonDropdown.Item onClick={editUser} title={`Edit ${row.original.name}`}>
				Edit
			</ActionButtonDropdown.Item>
			<ActionButtonDropdown.Divider />
			<ActionButtonDropdown.Item className="text-danger" title={`Delete ${row.original.name}`}>
				Delete
			</ActionButtonDropdown.Item>
		</ActionButtonDropdown>
	);
}

function FormattedAddress({ row }: { row: Row<User> }) {
	return (
		<>
			{row.original.address1}
			{row.original.address2 ? (
				<>
					<br />
					{row.original.address2}
				</>
			) : null}
			<br />
			{`${row.original.city}, ${row.original.state} ${row.original.zip || ''}`}
		</>
	);
}

export default function Users(): JSX.Element {
	const navigate = useNavigate();
	const [users, setUsers] = React.useState<User[] | undefined>(undefined);

	useEffect(() => {
		axios.get(`${process.env.REACT_APP_HCWEB_API_URL}/users`).then(response => {
			if (!Array.isArray(response.data)) {
				throw new Error('Invalid content type');
			}
			setUsers(response.data);
		});
	}, []);

	const renderRowSubComponent = React.useCallback(
		({ row }: { row: Row<User> }) => (
			<div className="ms-5">
				<div>
					<strong>Student Status: </strong>
					{row.original.status}
				</div>
				<div>
					<strong>A Date: </strong>
					{row.original.date}
				</div>
			</div>
		),
		[]
	);

	const columns = React.useMemo(
		() => [
			{
				Header: () => null, // do not display header
				id: 'expander', // id is required
				Cell: Expander,
				className: 'col-check'
			},
			{
				Header: 'Name',
				accessor: 'name'
			},
			{
				Header: 'Email',
				accessor: 'email'
			},
			{
				Header: 'Address',
				Cell: FormattedAddress
			},
			{
				Header: 'Actions',
				Cell: Actions,
				className: 'table-col-actions'
			}
		],
		[]
	);

	return (
		<Datatable
			loading={typeof users === 'undefined'}
			caption="Current Users"
			columns={columns}
			data={users}
			responsive={false}
			renderRowSubComponent={renderRowSubComponent}
			actionButtons={() => (
				<Button variant="primary" onClick={() => navigate('/modify')}>
					Add User
				</Button>
			)}
		/>
	);
}
