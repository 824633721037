import React, { MutableRefObject } from 'react';
import Label from '../../utility/Label';
import Helpblock from '../../utility/Helpblock';
import { AttributeBasedValidation, AttributeBasedValidationKeys, fieldHasErrors, getFieldErrors, InlineHelper } from '../../../helpers';
import { FormControlFeedback } from '../form-control';
import FormGroup from '../form-group/FormGroup';
import { FormGroupValidationProps } from './types';

export const REQUIRED_FIELD_MESSAGE = 'is a required field';

const FormGroupValidation = <TFieldValue extends string | string[] | number[] = string | string[] | number[]>({
	groupProps,
	labelProps,
	helpblockProps,
	inputRef,
	validation,
	requiredFieldMessage = REQUIRED_FIELD_MESSAGE,
	suppressValidationMessage = false,
	inline,
	children
}: FormGroupValidationProps<TFieldValue>) => {
	const errors = validation.formContext?.formState?.errors;

	const getErrorMessage = (): Array<string | null> | string | null => {
		const errorsForInput = getFieldErrors(validation.inputName, errors);

		if (errorsForInput) {
			const validatorsMessage = validation.validators
				?.map(validator => {
					// return error message for the appropriate validator under error
					if (errorsForInput.type === validator.name || (errorsForInput.types as Record<string, unknown>)?.[validator.name]) {
						return validator.message;
					}

					return null;
				})
				.filter(Boolean);

			if (validatorsMessage && validatorsMessage.length > 0) {
				return validatorsMessage;
			} else if (AttributeBasedValidation[errorsForInput.type as AttributeBasedValidationKeys]) {
				return errorsForInput.message as string;
			}
		}

		if (validation.isRequired && (inputRef as MutableRefObject<HTMLInputElement | HTMLTextAreaElement>).current?.value === '') {
			return `${validation.displayLabel} ${requiredFieldMessage}`;
		}

		return null;
	};

	return (
		<FormGroup {...groupProps}>
			<Label {...labelProps} />
			<InlineHelper inline={inline}>
				{children}
				<Helpblock {...helpblockProps} />
				{!suppressValidationMessage && (validation.isRequired || fieldHasErrors(validation.inputName, errors)) ? (
					<FormControlFeedback type="invalid">{getErrorMessage()}</FormControlFeedback>
				) : null}
			</InlineHelper>
		</FormGroup>
	);
};

FormGroupValidation.displayName = 'FormGroupValidation';

export default FormGroupValidation;
