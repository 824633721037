import React, { ChangeEvent, DataHTMLAttributes, ForwardedRef, HTMLProps } from 'react';
import { IGroupedListOption, SelectProps } from './types';
import FormGroupValidation from '../shared/FormGroupValidation';
import useFormGroupValidation from '../shared/hooks/useFormGroupValidation';
import { inputDefaultProps } from '../../../props/inputProps';
import { noop } from '../../../helpers';
import OptionsList from './OptionsList';
import { calculatePlainItems, useSearchableItemsList } from './hooks';
import Search from '../search/Search';
import { getSelectMenuHeight, isInHiddenGroup } from './utility';
import { InputProps } from '../types';
import { IconLoading } from '@optic-delight/icons';
import { useControlledFocusOnError } from '../shared/hooks/useControlledFocusOnError';
import { useFlippedDropdownMenu } from '../../../helpers/hooks/useFlippedDropdownMenu';
import { OptionsListLoader } from './OptionsListLoader';
import { useDownshiftSelect } from './hooks/useDownshiftPropsForSelect';

interface CalculateInteractionsProps {
	filteredItems: IGroupedListOption[];
	hiddenGroups: string[][];
	searchable: number | boolean;
	disabled?: boolean;
	loading: boolean;
}

export const SelectPlaceholder = 'Select...';
export const SelectLoadingPlaceholder = 'Loading...';
export const SelectSearchDefaultPlaceholder = 'Search...';
export const SelectNotFoundPlaceholder = 'Nothing found';

const calculateInteractivity = ({ filteredItems, hiddenGroups, searchable, disabled, loading }: CalculateInteractionsProps) => {
	const filteredItemsToDisplay = (filteredItems as IGroupedListOption[]).filter(item => !isInHiddenGroup(item, hiddenGroups));
	const isSearchVisible = typeof searchable === 'number' ? filteredItems.length >= searchable : searchable;
	const isDisabled = disabled || loading;
	const isNothingFoundShown = isSearchVisible && filteredItemsToDisplay.length === 0 && !loading;

	return {
		filteredItemsToDisplay,
		isSearchVisible,
		isDisabled,
		isNothingFoundShown
	};
};

/**
 * Component implements a standard <select /> tag properties, excepts 'size' as it is replaced by
 * custom prop. Also, it has an amount of additional props derived from FieldProps
 */
const Select = React.forwardRef((props: SelectProps, ref: ForwardedRef<HTMLInputElement>): JSX.Element => {
	const {
		className,
		onChange,
		placeholder = SelectPlaceholder,
		loadingPlaceholder = SelectLoadingPlaceholder,
		searchPlaceholder = SelectSearchDefaultPlaceholder,
		notFoundPlaceholder = SelectNotFoundPlaceholder,
		compareType,
		compareBy,
		requiredFieldMessage,
		suppressValidationMessage,
		'data-testid': dataTestId,
		items = React.useMemo(() => [], []),
		value,
		disabled,
		readOnly,
		loading = false,
		defaultValue,
		size,
		searchable = false,
		textTruncation = true,
		paginationOptions,
		...selectProps
	} = props;
	const downshiftMenuRef = React.useRef<HTMLUListElement>(null);
	const [isDropdownOpen, setIsDropdownOpen] = React.useState<boolean>(false);

	const searchableItemsListData = useSearchableItemsList({
		items,
		value,
		defaultValue,
		compareType,
		searchable,
		paginationOptions,
		compareBy
	});
	const { filteredItems, searchValue, setSearchValue, searchRef, collapseGroup, hiddenGroups } = searchableItemsListData;

	const { filteredItemsToDisplay, isSearchVisible, isDisabled, isNothingFoundShown } = calculateInteractivity({
		filteredItems,
		hiddenGroups,
		searchable,
		disabled,
		loading
	});

	const { getGroupProps, getLabelProps, getHelpblockProps, getInputProps, validation, inputRef } = useFormGroupValidation<string, HTMLInputElement>({
		id: props.id,
		ref,
		disabled: isDisabled,
		readOnly,
		...selectProps
	});

	// group props
	const { controlId, ...groupProps } = getGroupProps();

	const downshiftProps = useDownshiftSelect({
		id: controlId,
		isOpen: isDropdownOpen,
		searchableListProps: searchableItemsListData,
		downshiftMenuRef,
		inputRef,
		validation,
		value,
		paginationOptions,
		collapseGroup,
		setIsDropdownOpen,
		forceFocusSearch
	});

	function onSearchInputChange(event: ChangeEvent<HTMLInputElement>) {
		const searchString = event.target.value;
		setSearchValue(searchString);
		paginationOptions?.searchQuery(searchString.trim());
	}

	const onClearSearchInput = () => {
		setSearchValue('');
		paginationOptions?.searchQuery('');
	};

	function forceFocusSearch() {
		if (searchRef.current !== document.activeElement) {
			searchRef.current?.focus();
		}
	}

	const updateHighlightedIndex = (index: number) => {
		downshiftProps.setHighlightedIndex(index);
	};

	const onSelectWrapperBlur = (event: React.FocusEvent<HTMLDivElement>) => {
		if (!event.relatedTarget) {
			// Focus is moving outside the document/container
			setIsDropdownOpen(false);
		}
	};

	const { ref: buttonRef } = useControlledFocusOnError<HTMLButtonElement>({ name: selectProps.name });
	const downshiftInputProps = downshiftProps.getToggleButtonProps({ ref: buttonRef });

	// label props
	const baseLabelProps = getLabelProps({ htmlFor: downshiftInputProps.id });
	const labelProps = { ...downshiftProps.getLabelProps(), ...baseLabelProps };

	// input props
	const getInputValue = () => {
		let inputValue = downshiftProps.selectedItem?.value !== undefined ? downshiftProps.selectedItem.label : placeholder;
		if (loading) {
			inputValue = loadingPlaceholder;
		}

		return inputValue;
	};

	const inputValue = getInputValue();

	const baseInputProps: InputProps = getInputProps({
		id: downshiftInputProps.id,
		value: inputValue,
		'aria-labelledby': labelProps.id,
		className: ['form-select', loading && 'loading', validation.isInvalid && 'is-invalid', size !== undefined && `form-select-${size}`, className]
			.filter(Boolean)
			.join(' ')
	});
	const { 'aria-required': ariaRequired = undefined, disabled: toggleBtnDisabled = undefined, ...inputProps } = { ...downshiftInputProps, ...baseInputProps };

	// helpblock props
	const helpblockProps = getHelpblockProps({ id: `${controlId}_helptext` });

	// menu props

	const downshiftMenuProps = downshiftProps.getMenuProps({
		ref: downshiftMenuRef,
		'aria-labelledby': labelProps.id,
		tabIndex: 0
	});

	const menuWrapperProps: HTMLProps<HTMLDivElement> & DataHTMLAttributes<HTMLDivElement> = {
		className: 'dropdown-menu-items',
		style: {
			height: getSelectMenuHeight(isNothingFoundShown || paginationOptions?.searching ? 1 : filteredItemsToDisplay.length)
		}
	};

	const { setValue = noop } = validation.formContext;
	const { dropdownMenuRef, isDropdownMenuFlipped } = useFlippedDropdownMenu(downshiftProps.isOpen);

	// effects
	React.useEffect(() => {
		if (downshiftProps.isOpen && paginationOptions && !paginationOptions.initialFetchCalled) {
			paginationOptions.initialFetch();
		}
	}, [downshiftProps.isOpen, paginationOptions]);

	React.useEffect(() => {
		if (!items) return;
		const { plainItems } = calculatePlainItems(items);
		const newItem = plainItems.find(item => item.value === downshiftProps.selectedItem?.value);
		if (!baseInputProps.disabled) {
			setValue(baseInputProps.name ?? '', newItem?.value ?? '');
		}
	}, [items, downshiftProps.selectedItem, baseInputProps.name, baseInputProps.disabled, setValue]);

	React.useEffect(() => {
		const onBodyClick = (event: MouseEvent) => {
			const target = event.target as Node;
			if (dropdownMenuRef.current && !dropdownMenuRef.current.contains(target) && buttonRef.current && !buttonRef.current.contains(target)) {
				setIsDropdownOpen(false);
			}
		};

		if (isDropdownOpen) {
			document.addEventListener('mousedown', onBodyClick);
		}

		return () => {
			document.removeEventListener('mousedown', onBodyClick);
		};
	}, [buttonRef, dropdownMenuRef, isDropdownOpen]);

	return (
		<FormGroupValidation
			groupProps={groupProps}
			labelProps={labelProps}
			helpblockProps={helpblockProps}
			validation={validation}
			inputRef={inputRef}
			inline={selectProps.inline}
			requiredFieldMessage={requiredFieldMessage}
			suppressValidationMessage={suppressValidationMessage}>
			<div className={['form-select-wrapper', validation.isInvalid && 'is-invalid'].filter(Boolean).join(' ')} onBlur={onSelectWrapperBlur}>
				<span className={loading ? 'input-group input-group-embedded' : undefined}>
					<input {...inputProps} data-testid={dataTestId} type="button" disabled={toggleBtnDisabled || readOnly} />
					{loading && (
						<span className="input-group-text" data-testid="loading-spinner">
							<IconLoading />
						</span>
					)}
				</span>

				<input
					id={selectProps.id}
					data-testid="select-hidden-input"
					aria-required={ariaRequired}
					ref={inputRef}
					value={downshiftProps.selectedItem?.value ?? ''}
					onInput={onChange}
					type="hidden"
					name={baseInputProps.name}
					disabled={baseInputProps.disabled}
				/>

				<div
					ref={dropdownMenuRef}
					data-testid="select-dropdown-menu"
					className={['dropdown-menu w-100 p-0', downshiftProps.isOpen && 'show', isDropdownMenuFlipped && 'dropdown-menu-end']
						.filter(Boolean)
						.join(' ')}>
					{isSearchVisible ? (
						<div className="dropdown-search-field w-100">
							<Search
								groupClassName=""
								ref={searchRef as React.MutableRefObject<HTMLInputElement>}
								placeholder={searchPlaceholder}
								value={searchValue}
								onChange={onSearchInputChange}
								aria-autocomplete="list"
								aria-controls={inputProps.id}
								onClear={onClearSearchInput}
								autoComplete="off"
							/>
						</div>
					) : null}

					{loading && filteredItemsToDisplay.length === 0 && <OptionsListLoader loadingPlaceholder={loadingPlaceholder} />}

					<div {...menuWrapperProps}>
						<OptionsList
							getItemProps={downshiftProps.getItemProps}
							allItems={filteredItems}
							visibleItems={filteredItemsToDisplay}
							hiddenGroups={hiddenGroups}
							highlightedIndex={downshiftProps.highlightedIndex}
							updateHighlightedIndex={updateHighlightedIndex}
							notFoundPlaceholder={notFoundPlaceholder}
							searchString={searchValue}
							onGroupCollapse={collapseGroup}
							selectedItems={downshiftProps.selectedItem ? [downshiftProps.selectedItem.value ?? ''] : []}
							textTruncation={textTruncation}
							menuProps={downshiftMenuProps}
							paginationOptions={paginationOptions}
							loading={loading}
							loadingPlaceholder={loadingPlaceholder}
						/>
					</div>
				</div>
			</div>
		</FormGroupValidation>
	);
});
Select.defaultProps = {
	...inputDefaultProps
};
Select.displayName = 'Select';

export default Select;
