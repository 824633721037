import React from 'react';
import { NavLinkProps } from './types';
import { classJoin } from '../../helpers';
import { useElementIds } from '../../helpers/hooks/useElementIds';

export const NavLink = <As extends React.ElementType>(props: NavLinkProps<As>) => {
	const { as: Component = 'a', eventKey, active, disabled, role = props.href ? 'link' : 'button', id, ...otherProps } = props;

	const { id: innerId } = useElementIds({ prefix: 'nav-link', id });

	const navLinkClasses = classJoin('nav-link', active && 'active', disabled && 'disabled');

	return (
		<Component id={innerId} {...otherProps} tabIndex={disabled ? undefined : 0} className={navLinkClasses} role={role} data-event-key={eventKey}>
			{props.children}
		</Component>
	);
};
