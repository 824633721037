import React from 'react';
import { getSelectMenuHeight } from './utility';
import { Spinner } from '../../spinner';
import { paginationLazyLoadTestId } from './constants';

export const OptionsListLoader = React.forwardRef<HTMLDivElement, { loadingPlaceholder: string }>(({ loadingPlaceholder }, ref) => {
	return (
		<div
			ref={ref}
			className="d-flex justify-content-center align-items-center"
			data-testid={paginationLazyLoadTestId}
			style={{ height: getSelectMenuHeight(1) }}>
			<Spinner inline size="sm" className="me-1">
				{loadingPlaceholder}
			</Spinner>
		</div>
	);
});

OptionsListLoader.displayName = 'OptionsListLoader';
