import * as React from 'react';
import { TableElementProps } from './types';
import { Caption } from './Caption';
import { classList } from '../utility/utils';
import { TableContext } from './TableContext';

export const TableElement = React.forwardRef<HTMLTableElement, TableElementProps>(
	({ bordered, borderless, hover, size, variant, responsive, caption, className, children, striped = true, ...props }, ref) => {
		const { Provider } = TableContext;
		const classes = classList([
			'table',
			bordered && 'table-bordered',
			borderless && 'table-borderless',
			hover && 'table-hover',
			striped && typeof striped === 'string' && `table-striped-${striped}`,
			striped && typeof striped === 'boolean' && 'table-striped',
			size && `table-${size}`,
			variant && `table-${variant}`,
			caption === 'top' && 'caption-top',
			className
		]);
		const table = (
			<Provider value={{ role: props.role }}>
				<table ref={ref} className={classes} {...props}>
					{caption && caption !== 'top' ? <Caption>{caption}</Caption> : undefined}
					{children}
				</table>
			</Provider>
		);

		if (responsive) {
			let responsiveClass = 'table-responsive';
			if (typeof responsive === 'string') {
				responsiveClass = `table-responsive-${responsive}`;
			}
			return (
				<div data-testid="responsive-table-wrapper" className={responsiveClass}>
					{table}
				</div>
			);
		}
		return table;
	}
);
TableElement.displayName = 'TableElement';
