import React from 'react';
import { noop } from '../../../helpers';
import { IconExpand, IconLoading } from '@optic-delight/icons';
import Button from '../../button/Button';
import { ComboboxInputProps } from './types';

const defaultRenderAddOn = (props?: Record<string, unknown>) => {
	return (
		<button type="button" className="input-group-append input-group-text" {...props} aria-label="toggle menu">
			<IconExpand />
		</button>
	);
};

const ComboboxInput = React.forwardRef<HTMLInputElement, ComboboxInputProps>(
	(
		{
			loading,
			placeholder = loading ? 'Loading...' : 'Select...',
			useComboboxOptions,
			clearFilteredItems = noop,
			isInvalid,
			renderAddOn = defaultRenderAddOn,
			suppressRef = false,
			...props
		},
		ref
	) => {
		const { isOpen, openMenu, getInputProps, getComboboxProps, getToggleButtonProps } = useComboboxOptions;
		const inputProps = getInputProps(
			{
				ref,
				...props,
				disabled: loading ? true : props.disabled,
				className: [isInvalid && 'is-invalid', 'form-control'].filter(Boolean).join(' '),
				onFocus: () => {
					// open listbox menu when the input receives focus
					!isOpen && openMenu();
				}
			},
			suppressRef ? { suppressRefError: true } : undefined
		);

		const toggleProps = getToggleButtonProps({
			disabled: inputProps.disabled,
			onClick: () => !isOpen && clearFilteredItems()
		});

		// hack to prevent auto focus on input for open combobox menus. nothing worked trying to remove "ref" from getInputProps otherwise
		if (suppressRef) {
			inputProps.ref = null;
		}

		const comboboxProps = getComboboxProps();

		return (
			<div
				className={`input-group input-group-embedded ${isInvalid ? 'is-invalid ' : ''}`}
				{...comboboxProps}
				aria-controls={inputProps['aria-controls']}>
				<input placeholder={placeholder} aria-invalid={isInvalid} {...inputProps} value={loading ? '' : inputProps.value} />
				{loading ? (
					<Button aria-label={placeholder} disabled className="input-group-text">
						<IconLoading data-testid="icon-loading" role="status" />
					</Button>
				) : (
					renderAddOn(toggleProps)
				)}
			</div>
		);
	}
);
ComboboxInput.displayName = 'ComboboxInput';

export default ComboboxInput;
