import { createAxiosInstance, CLIENT_TIMEOUT, CSRF_HEADER_NAME } from '../http-client';

export const REST_INSTANCE_DEFAULTS = {
	baseURL: `${window.location.origin}/benefits/rest`,
	timeout: CLIENT_TIMEOUT,
	xsrfHeaderName: CSRF_HEADER_NAME,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json'
	}
};

const restClient = createAxiosInstance(REST_INSTANCE_DEFAULTS);

export default restClient;
