import React from 'react';
import { FormSelect } from 'react-bootstrap';
import { useTranslatableText } from '../../translatable-text';
import { LengthMenuProps, TupleOfSameLengthArrays } from './types';
import { defaultMenuOptions } from '../utility/helpers';

export function LengthMenu({ totalItems, menuOptions = [...defaultMenuOptions], value = defaultMenuOptions[0][0], ...props }: LengthMenuProps): JSX.Element {
	const { lengthMenuShowText, lengthMenuOfText } = useTranslatableText();
	function lengthMenuOptions(menu: TupleOfSameLengthArrays, totalRows: number) {
		const pageSizes = menu[0];
		const descriptions = menu[1];

		return pageSizes
			.map((size, i) => {
				const isAllOption = isNaN(size) || size <= 0;

				if (isAllOption && totalItems >= 1_000) return null;

				return (
					<option key={size} value={isAllOption ? totalRows : size}>
						{descriptions[i]}
					</option>
				);
			})
			.filter(Boolean);
	}
	const smallestPageSize = menuOptions[0][0];

	return (
		<div className="d-flex justify-content-start align-items-center" data-testid="length-menu">
			<span className="me-2">{lengthMenuShowText}</span>
			{totalItems < smallestPageSize ? (
				<span className="me-2">{totalItems}</span>
			) : (
				<FormSelect className="me-2" value={value} aria-label={'Show ' + value + ' entries'} {...props}>
					{lengthMenuOptions(menuOptions, totalItems)}
				</FormSelect>
			)}
			<span className="me-2">{lengthMenuOfText}</span>
			<span className="me-2" data-testid="total-row-count">
				{totalItems}
			</span>
		</div>
	);
}
