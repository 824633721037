import * as React from 'react';
import { TableContext } from '../TableContext';

export const useTable = () => {
	const context = React.useContext(TableContext);

	return {
		isPresentationTable: context?.role === 'presentation'
	};
};
