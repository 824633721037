import React from 'react';
import OptionItem from './OptionItem';
import { IGroupedListOption, ListOptionProps, SelectOptionsListProps } from './types';
import { isSameGroupByParentsList } from './utility';
import VirtualListWrapper from '../../utility/VirtualListWrapper';
import { remToPx } from '../../../helpers';
import { OptionsListLoader } from './OptionsListLoader';

const OptionsList = (props: SelectOptionsListProps) => {
	const {
		hiddenGroups,
		menuProps,
		allItems,
		visibleItems = allItems,
		highlightedIndex,
		getItemProps,
		getGroupItems,
		notFoundPlaceholder = 'No items found',
		multiple = false,
		selectedItems = [],
		searchString,
		leftPaddingBase,
		onGroupCollapse,
		onGroupToggle,
		textTruncation = true,
		paginationOptions,
		loading,
		loadingPlaceholder,
		updateHighlightedIndex
	} = props;

	function downShiftClickHandler(event: React.MouseEvent<HTMLElement, MouseEvent>, item: IGroupedListOption, isGroupToggle: boolean) {
		if (!item.isGroup) return;

		// this issue says there is no type definition for `preventDownshiftDefault`
		// https://github.com/downshift-js/downshift/issues/734
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		event.preventDownshiftDefault = true;
		event.stopPropagation();

		if (!multiple || isGroupToggle) {
			onGroupCollapse?.(item);
		} else {
			onGroupToggle?.(item);
		}
	}

	if (!loading && searchString && paginationOptions?.searching && loadingPlaceholder) {
		return <OptionsListLoader loadingPlaceholder={loadingPlaceholder} />;
	}

	if (visibleItems.length === 0 && searchString && !loading && !paginationOptions?.searching) {
		return (
			<div className="w-100 position-relative">
				<OptionItem textTruncation={textTruncation} itemProps={{ role: 'option' }} className="dropdown-item pe-none" aria-disabled={true}>
					{notFoundPlaceholder}
				</OptionItem>
			</div>
		);
	}

	const hashedSelectedItems = Object.fromEntries(selectedItems.map(selectedItem => [selectedItem, true]));

	const estimateSize = (index: number): number => {
		const isFirstLevel = visibleItems[index].level === 0;
		const thisIsGroup = visibleItems[index].isGroup;
		return isFirstLevel && thisIsGroup ? remToPx(2.5) : remToPx(2);
	};

	return (
		<VirtualListWrapper
			count={visibleItems.length}
			estimateSize={estimateSize}
			menuProps={menuProps}
			textTruncation={textTruncation}
			paginationOptions={paginationOptions}
			loading={loading}
			loadingPlaceholder={loadingPlaceholder}
			highlightedIndex={highlightedIndex}
			updateHighlightedIndex={updateHighlightedIndex}
			itemRender={(virtualRow, optionItemRef) => {
				const index = virtualRow.index;
				const item = visibleItems[index];

				let optionProps: ListOptionProps = {
					item,
					itemProps: getItemProps({
						item,
						index
					}),
					highlighted: highlightedIndex === index,
					leftPaddingBase,
					style: {
						transform: `translateY(${virtualRow.start}px)`,
						cursor: 'pointer',
						position: 'absolute',
						left: 0,
						width: '100%'
					},
					'data-index': index,
					textTruncation
				};

				optionProps.selected = item.value !== undefined ? hashedSelectedItems[item.value] || false : false;

				if (multiple) {
					optionProps.checkbox = multiple;

					if (item.isGroup) {
						const children = getGroupItems?.(item, allItems) ?? [];
						const allChecked = children.length > 0 && children.every(child => hashedSelectedItems[child.value ?? '']);
						const someChecked = !allChecked && children.some(child => hashedSelectedItems[child.value ?? '']);

						optionProps.selected = allChecked;
						optionProps.indeterminate = someChecked;
					}
				}

				if (item.isGroup) {
					optionProps = {
						...optionProps,
						'data-testid': 'dropdown-group',
						isCollapsed: hiddenGroups.some(groupParents => isSameGroupByParentsList(item, groupParents))
					};
				}

				return <OptionItem {...optionProps} ref={optionItemRef} key={`item-${item.value}-${index}`} onGroupToggleClick={downShiftClickHandler} />;
			}}
		/>
	);
};
OptionsList.displayName = 'OptionsList';

export default OptionsList;
