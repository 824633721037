import React from 'react';
import { SpinnerDescriptionProps } from './types';

/**
 * secondary loading text for `Spinner`
 */
export function SpinnerDescription({ children, ...props }: SpinnerDescriptionProps) {
	return (
		<>
			<br />
			{typeof children === 'string' ? <p {...props}>{children}</p> : children}
		</>
	);
}
