import React from 'react';
import { InlineHelper } from './components';
import { ImageColumnProps } from './types';

const ImageColumn = (props: ImageColumnProps) => {
	return (
		<InlineHelper className="col-auto">
			<img alt={props.alt} {...props} />
		</InlineHelper>
	);
};

ImageColumn.displayName = 'ImageColumn';

export default ImageColumn;
