import React from 'react';
import { Button, ButtonProps } from '../button';

export default function SelectedItemButton({ children, ...props }: ButtonProps) {
	return (
		<Button className="selected-item-button" aria-label={`Remove item, ${children}`} {...props}>
			<span className="selected-item-button-text">{children}</span>
			<strong className="ms-2" aria-hidden={true}>
				x
			</strong>
		</Button>
	);
}
