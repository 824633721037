import React, { useRef } from 'react';
import { IconExpand } from '@optic-delight/icons';
import { Button } from '../button';
import IconPickerOverlay from './IconPickerOverlay';
import useIconPicker from './hooks/useIconPicker';
import { useCombinedRefs } from '../../helpers';
import { IconPickerProps } from './types';

const IconPicker = React.forwardRef<HTMLButtonElement, IconPickerProps>(({ 'aria-label': ariaLabel = 'Toggle Menu', children, ...props }, ref) => {
	const innerRef = useRef(null);
	const combinedRef = useCombinedRefs<HTMLButtonElement>(ref, innerRef);

	const { css, offset, reducer, ...rest } = props;
	const { toggleMenu, getOverlayProps } = useIconPicker({ css, offset, reducer });

	return (
		<>
			<Button ref={combinedRef} onClick={toggleMenu} aria-label={ariaLabel}>
				{children}
			</Button>
			<IconPickerOverlay
				{...getOverlayProps({
					targetRef: combinedRef,
					...rest
				})}
			/>
		</>
	);
});

IconPicker.displayName = 'IconPicker';

// We need to set defaultProps for children to avoid wrapping IconPicker, when it is rendered through `IconGroup`.
// if we set default value for children at the function level `(..., children = <Icon/>, ...) => {...}` `IconGroup` won't see default children, and it will wrap the component.
// https://gitlab.businessolver.com/optic-delight/ui-framework/-/blob/master/src/components/utility/InputGroup.tsx?ref_type=heads#L31-33
IconPicker.defaultProps = {
	children: <IconExpand />
};

export default IconPicker;
