import React from 'react';
import { DatatableProps } from './types';
import InternalDatatable from './InternalDatatable';
import { getCoreRowModel, getExpandedRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, TableOptions } from '@tanstack/react-table';
import { useColumns } from './hooks/useColumns';
import { UseTranslatableTextData } from '../translatable-text';
import { processGlobalFilterProps, processLengthMenuProps, processPaginationProps } from './utility/helpers';

// if datatable is in the loading state, it creates an empty array every time
// so that it does not create a new array we took it out of the component
const emptyArray: unknown[] = [];

/**
 * - Use Datatable when enhanced table functionality is needed
 * - Features
 *  - Table Filter
 *  - Column sort
 *  - Pagination
 */
export default function Datatable<TData = unknown, TValue = unknown>(datatableProps: DatatableProps<TData, TValue>) {
	const {
		className = '',
		columns = [],
		tableFilter = true,
		sortable = true,
		striped = false,
		paginate = true,
		responsive = true,
		rowSelection = false,
		rowSelectionOptions,
		type = 'vertical',
		loading = false,
		disableSelectAll = false,
		lengthMenu,
		...props
	} = datatableProps;

	const tableClassNames = [className, type === 'horizontal' && 'table-horizontal'].filter(Boolean).join(' ');
	const { enabled: globalFilterEnabled, props: globalFilterProps } = processGlobalFilterProps(tableFilter);

	const { subRow = props.renderRowSubComponent, column: expandableColumn, className: expandableColumnClassName } = props.rowExpansionOptions ?? {};

	const { innerRowSelection, formattedColumns } = useColumns<TData, TValue>({
		sortable,
		columns,
		rowSelection,
		rowSelectionOptions,
		expandableColumn,
		expandableColumnClassName: expandableColumnClassName ?? '',
		size: props.size,
		disableSelectAll
	});

	// useRowSelect requires usePagination hook to be in use.  Internally, we will suppress displaying paginated items
	const hiddenPagination = innerRowSelection && !paginate;
	const { menuOptions, pageSize, props: lengthMenuProps } = processLengthMenuProps(lengthMenu, hiddenPagination);
	const { enabled: paginationEnabled, props: paginationProps } = processPaginationProps(paginate);

	const tanstackTableProps: TableOptions<TData> = {
		data: loading || !props.data ? (emptyArray as TData[]) : props.data,
		columns: formattedColumns,

		initialState: {
			pagination: {
				pageIndex: 0,
				pageSize: paginate ? pageSize : 9999 // using -1 instead of 9999 here causes problems for horizontal table
			},
			columnFilters: props.initialFilteredColumns ?? []
		},

		enableGlobalFilter: globalFilterEnabled,
		enableFilters: globalFilterEnabled && Boolean(tableFilter),
		enableExpanding: !!subRow,
		enableRowSelection: innerRowSelection,
		enableSorting: sortable,

		// canExpand is true only when there are subrows, so we need to set it manually to true to use
		// custom component to render as a subrow
		getRowCanExpand: () => !!subRow,

		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getExpandedRowModel: getExpandedRowModel()
	};

	const translatableTextProps: UseTranslatableTextData = {
		globalFilterPlaceholder: props.globalFilterPlaceholder,
		filterResultsLabel: props.filterResultsLabel,
		loadingText: props.loadingText,
		noResultsText: props.noResultsText,
		searchResultsText: props.searchResultsText,
		filterResultsText: props.filterResultsText,
		lengthMenuShowText: props.lengthMenuShowText,
		lengthMenuOfText: props.lengthMenuOfText,
		clearAllText: props.clearAllText
	};

	return (
		<InternalDatatable<TData, TValue>
			className={tableClassNames}
			role={props.role}
			loading={loading}
			type={type}
			initialSort={props.initialSort}
			lengthMenu={menuOptions}
			translatableProps={translatableTextProps}
			caption={props.caption}
			useTableOptions={props.useTableOptions}
			getTableBodyProps={props.getTableBodyProps}
			actionButtons={props.actionButtons}
			subRow={subRow}
			customFilters={props.customFilters}
			filterLabelsMapping={props.filterLabelsMapping}
			onFilterClear={props.onFilterClear}
			paginate={paginate}
			globalFilterProps={globalFilterProps}
			lengthMenuProps={lengthMenuProps}
			striped={striped}
			size={props.size}
			responsive={responsive}
			bordered={props.bordered}
			borderless={props.borderless}
			data-testid={props['data-testid']}
			selectedRows={props.defaultSelectedRows ?? props.selectedRows}
			onSelectedRows={props.onSelectedRows}
			paginationEnabled={paginationEnabled}
			paginationProps={paginationProps}
			tanstackTableProps={tanstackTableProps}
		/>
	);
}
