import React, { Children } from 'react';
import NavProgressItem from './NavProgressItem';
import { getProgressDotVariantClasses } from '../progress/ProgressDots';
import { ProgressDotsVariant } from '../progress';
import { NavProgressProps } from './types';

const NavProgress = ({ defaultActiveKey, variant = 'primary', children, ...props }: NavProgressProps) => {
	let hasActiveChild = false;
	const childrenWithProps = Children.map(children, child => {
		if (React.isValidElement(child)) {
			if (!hasActiveChild && (child.props.href === defaultActiveKey || child.props.eventKey === defaultActiveKey)) {
				hasActiveChild = true;
				return React.cloneElement(child, { active: true, complete: false } as React.HTMLAttributes<HTMLElement>);
			} else if (!hasActiveChild) {
				return React.cloneElement(child, { active: false, complete: true } as React.HTMLAttributes<HTMLElement>);
			}
		}
		return child;
	});

	return (
		<nav className="d-block">
			<div {...props} className={getProgressDotVariantClasses(variant as ProgressDotsVariant)}>
				{childrenWithProps}
			</div>
		</nav>
	);
};

NavProgress.displayName = 'NavProgress';
NavProgress.Item = NavProgressItem;

export default NavProgress;
