import React, { DataHTMLAttributes, HTMLProps } from 'react';
import { useCombobox, UseComboboxState, UseComboboxStateChangeOptions } from 'downshift';
import ComboboxInput from './ComboboxInput';
import { getItemsByContent, itemToString, comparatorTypes, normalizeItems } from './utility';
import useFormGroupValidation from '../shared/hooks/useFormGroupValidation';
import FormGroupValidation from '../shared/FormGroupValidation';
import { inputDefaultProps } from '../../../props/inputProps';
import { useSearchableItemsList } from '../select/hooks';
import OptionsList from '../select/OptionsList';
import { getSelectMenuHeight, isInHiddenGroup } from '../select/utility';
import { isValueUnset, noop } from '../../../helpers';
import { useControlledFocusOnError } from '../shared/hooks/useControlledFocusOnError';
import { useFlippedDropdownMenu } from '../../../helpers/hooks/useFlippedDropdownMenu';
import { ComboboxItem, ComboboxProps, NormalizedItemsObject, SelectedItem } from './types';
import { IGroupedListOption, IListOption } from '../select';

const comboboxItemToListOption = (item: NormalizedItemsObject): IListOption => {
	return {
		label: String(item.content),
		value: item.value,
		items: item.group?.map(child => comboboxItemToListOption(child))
	};
};

const comboboxItemToGroupedListOption = (item: ComboboxItem): IGroupedListOption => {
	if (typeof item === 'string' || typeof item === 'number') {
		return {
			label: String(item),
			value: item,
			isGroup: false
		};
	}

	const option: IGroupedListOption = {
		label: String(item.content),
		value: item.value,
		isGroup: false
	};

	if (item.group) {
		option.isGroup = true;
		option.items = item.group.map(comboboxItemToGroupedListOption);
	}
	return option;
};

/**
 * Bootstrap UI wrapper for a `downshift-js` combobox
 *
 * It's important that we're using React.useMemo in the examples to ensure that our data isn't recreated on every render.
 * If we didn't use React.useMemo, the combobox would think it was receiving new data on every render and attempt
 * to recalculate a lot of logic every single time. Not cool!
 *
 * Documentation: https://www.downshift-js.com/use-combobox
 */
const Combobox = React.forwardRef<HTMLInputElement, ComboboxProps>((allProps, ref) => {
	const {
		loading = false,
		items,
		initialValue,
		selectedItem,
		onSelectedItemChange,
		strictMatch,
		compareType = comparatorTypes.startsWith,
		compareBy,
		textTruncation = true,
		...props
	} = allProps;

	const isControlled =
		Object.prototype.hasOwnProperty.call(allProps, 'selectedItem') && Object.prototype.hasOwnProperty.call(allProps, 'onSelectedItemChange');

	const initialRender = React.useRef(true);
	const [selectedItems, setSelectedItems] = React.useState<ComboboxItem[]>(() => {
		if (selectedItem || initialValue) {
			const item = getItemsByContent(items, selectedItem || initialValue, compareType);
			return item || [];
		}
		return [];
	});

	const { getGroupProps, getLabelProps, getInputProps, getHelpblockProps, validation, inputRef } = useFormGroupValidation({
		ref,
		...props
	});
	const { setValue = noop } = validation.formContext;
	const normalizedItems = React.useMemo(() => {
		return normalizeItems(items).map(item => comboboxItemToListOption(item));
	}, [items]);

	const { filteredItems, searchValue, setSearchValue, hiddenGroups, collapseGroup } = useSearchableItemsList({
		searchable: true,
		items: normalizedItems,
		value: selectedItem || initialValue || '',
		compareType,
		compareBy
	});
	const filteredItemsToDisplay = filteredItems.filter(item => !isInHiddenGroup(item, hiddenGroups));

	const { id: labelId, ...labelProps } = getLabelProps();
	const { id: inputId, name, disabled: toggleBtnDisabled = undefined, ...inputProps } = getInputProps();

	// downshift setup
	const useComboboxOptions = useCombobox<IGroupedListOption>({
		inputId: inputId,
		labelId: labelId,
		items: filteredItemsToDisplay,
		initialInputValue: initialValue ? initialValue.toString() : '',
		[isControlled ? 'selectedItem' : 'initialSelectedItem']: selectedItems[0] || '',
		initialHighlightedIndex: items.findIndex(item => itemToString(item, 'value') === itemToString(selectedItems[0], 'value')),
		itemToString,
		stateReducer: (state: UseComboboxState<IGroupedListOption | ComboboxItem>, actionAndChanges: UseComboboxStateChangeOptions<IGroupedListOption>) => {
			const { changes, type } = actionAndChanges;
			const isGroup = changes.selectedItem?.isGroup || false;

			switch (type) {
				case useCombobox.stateChangeTypes.InputBlur:
					if (strictMatch) {
						// resets the visible input value to the previously selected item content.
						const previouslySelectedItem = selectedItems[0] ? comboboxItemToGroupedListOption(selectedItems[0]) : null;
						return {
							...changes,
							inputValue: itemToString(previouslySelectedItem, 'label'),
							selectedItem: previouslySelectedItem
						};
					} else {
						setValue(changes.inputValue ?? '', undefined);
						return {
							...changes,
							selectedItem: comboboxItemToGroupedListOption(changes.inputValue || '')
						};
					}

				case useCombobox.stateChangeTypes.InputKeyDownEnter:
				case useCombobox.stateChangeTypes.ItemClick:
					if (isGroup) {
						changes.selectedItem && collapseGroup(changes.selectedItem);
						return {
							...changes,
							isOpen: true,
							highlightedIndex: state.highlightedIndex
						};
					}

					// sets the visible input value to the selected item content.
					return {
						...changes,
						inputValue: itemToString(changes.selectedItem ?? null, 'label')
					};

				default:
					return changes;
			}
		},
		onInputValueChange: ({ inputValue }) => {
			// reduce the visible list of items to those that match the input value
			setSearchValue(inputValue ?? '');
		},
		onSelectedItemChange: ({ selectedItem }) => {
			if (!selectedItem) {
				return;
			}

			const value = selectedItem.value ?? '';

			validation.setIsInvalid(validation.isRequired && isValueUnset(value.toString()));
			setSelectedItems([value]);
			onSelectedItemChange?.(value);
		}
	});

	const dropdownMenuProps = useComboboxOptions.getMenuProps();

	const menuWrapperProps: HTMLProps<HTMLDivElement> & DataHTMLAttributes<HTMLDivElement> = {
		className: 'dropdown-menu-items',
		style: {
			height: getSelectMenuHeight(filteredItemsToDisplay.length)
		}
	};

	// useCombobox could have generated an id if one was not provided, use actualId from here on out
	const { reset } = useComboboxOptions;
	const { id } = useComboboxOptions.getInputProps();
	const clearFilteredItems = () => {
		setSearchValue('');
	};

	// sets the hidden input value whenever the selected item changes
	React.useEffect(() => {
		if (!toggleBtnDisabled && name) {
			setValue(name, selectedItems.map(selectedItem => itemToString(selectedItem, 'value')).join('|'));
		}
	}, [selectedItems, name, setValue, toggleBtnDisabled]);

	/*
	 * reset the listbox items and selected item when items prop changes.
	 * this will reset downshift and trigger a useEffect which calls setValue() for react-hook-form
	 */
	React.useEffect(() => {
		// skip running on initial render, so initially selected items work properly
		if (initialRender.current) {
			initialRender.current = false;
			return;
		}

		setSearchValue('');
		setSelectedItems([]);
		reset();
	}, [setSearchValue, items, reset]);

	React.useEffect(() => {
		if (isControlled) {
			const currentItems = selectedItem ? getItemsByContent(items, selectedItem, compareType) : [];
			setSelectedItems(prevItems => {
				const prevItemValues = prevItems.map(item => itemToString(item, 'value'));
				const currItemValues = currentItems.map(item => itemToString(item, 'value'));
				if (prevItemValues === currItemValues) {
					return prevItems;
				}
				return currentItems;
			});
		}
	}, [isControlled, compareType, items, selectedItem]);

	const { ref: controlRef } = useControlledFocusOnError<HTMLInputElement>({ name });

	const { dropdownMenuRef, isDropdownMenuFlipped } = useFlippedDropdownMenu(useComboboxOptions.isOpen);

	return (
		<FormGroupValidation
			inputRef={inputRef}
			validation={validation}
			groupProps={getGroupProps({
				controlId: id,
				className: `form-select-wrapper ${props.groupClassName ?? ''}`
			})}
			labelProps={getLabelProps({
				...useComboboxOptions.getLabelProps({
					...labelProps,
					htmlFor: id
				})
			})}
			helpblockProps={getHelpblockProps()}
			inline={props.inline}>
			<input ref={inputRef} type="hidden" name={name} disabled={toggleBtnDisabled} />
			<div className={['position-relative', validation.isInvalid && 'is-invalid'].filter(Boolean).join(' ')}>
				<ComboboxInput
					ref={controlRef}
					loading={loading}
					useComboboxOptions={useComboboxOptions}
					clearFilteredItems={clearFilteredItems}
					isInvalid={validation.isInvalid}
					disabled={toggleBtnDisabled || inputProps.readOnly}
					{...inputProps}
				/>

				<div
					ref={dropdownMenuRef}
					data-testid="select-dropdown-menu"
					className={['dropdown-menu w-100 p-0', useComboboxOptions.isOpen && 'show', isDropdownMenuFlipped && 'dropdown-menu-end']
						.filter(Boolean)
						.join(' ')}>
					<div {...menuWrapperProps}>
						<OptionsList
							getItemProps={useComboboxOptions.getItemProps}
							allItems={filteredItems}
							visibleItems={filteredItemsToDisplay}
							hiddenGroups={hiddenGroups}
							highlightedIndex={useComboboxOptions.highlightedIndex}
							menuProps={dropdownMenuProps}
							notFoundPlaceholder=""
							searchString={searchValue}
							onGroupCollapse={collapseGroup}
							// selectedItems is type of SelectedItem[] here as onSelectedItemChange() sets [value]
							selectedItems={selectedItems as SelectedItem[]}
							textTruncation={textTruncation}
						/>
					</div>
				</div>
			</div>
		</FormGroupValidation>
	);
});
Combobox.defaultProps = {
	...inputDefaultProps,
	items: [],
	strictMatch: true
};
Combobox.displayName = 'Combobox';

export default Combobox;
