import { useContext } from 'react';
import { CheckGroupContext } from '../CheckGroup';
import { useElementIds } from '../../../../helpers/hooks/useElementIds';
import { ButtonCheckableProps, CheckableInputProps, CheckableProps, UseCheckableCombinedProps } from '../../types';

export default function useCheckable(props: ButtonCheckableProps | CheckableProps) {
	const groupContext = useContext(CheckGroupContext) || {};
	const { isGroup, activeSwitchItems, onGroupChange, ...groupProps } = groupContext;
	const { id: innerId } = useElementIds({ prefix: 'checkable', id: props.id });

	// remove undefined keys
	Object.keys(groupProps).forEach(key => groupProps[key as keyof typeof groupProps] === undefined && delete groupProps[key as keyof typeof groupProps]);

	const combinedProps: UseCheckableCombinedProps = {
		...props,
		id: innerId,
		...groupProps
	};

	return {
		getProps: (propOverrides?: CheckableInputProps) => ({ ...combinedProps, ...propOverrides }),
		isGroup,
		activeSwitchItems,
		checkGroup: {
			onChange: onGroupChange
		}
	};
}
