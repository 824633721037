import React from 'react';
import { SpinnerTitle } from './SpinnerTitle';
import { SpinnerDescription } from './SpinnerDescription';
import { SpinnerProps } from './types';

const Spinner = ({
	className,
	variant = 'primary',
	animation = 'border',
	size,
	inline = false,
	as: Component = inline ? 'span' : 'div',
	children,
	...props
}: SpinnerProps) => {
	const wrapperClasses = [!inline && 'spinner'].filter(Boolean).join(' ');

	const spinnerClasses = ['spinner-border', animation && `spinner-${animation}`, variant && `text-${variant}`, size && `spinner-border-${size}`, className]
		.filter(Boolean)
		.join(' ');

	return (
		<Component className={wrapperClasses} {...props}>
			<span className={spinnerClasses} role="status">
				<span className="visually-hidden">Loading...</span>
			</span>
			{children}
		</Component>
	);
};

Spinner.displayName = 'Spinner';
Spinner.Title = SpinnerTitle;
Spinner.Description = SpinnerDescription;

export default Spinner;
