import React from 'react';
import { createRoot } from 'react-dom/client';
import { mockServiceWorker, withErrorBoundary } from '@optic-delight/benefitsolver-services';
import App, { AppFallback } from './App';
import { handlers } from './__mocks__/handlers';

import '@optic-delight/bootstrap-theme-benefitsolver/build/theme.css';

if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_MOCK_BACKEND === 'true') {
	mockServiceWorker.register(handlers);
}

const domNode = document.getElementById('app-root');
const root = createRoot(domNode);
const Wrapped = withErrorBoundary(App, {
	FallbackComponent: AppFallback
});
root.render(<Wrapped />);
