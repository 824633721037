import React from 'react';
import { Col, Overlay, Popover, Row } from 'react-bootstrap';
import { Icon, IconName } from '@optic-delight/icons';
import { Search } from '../forms';
import { Button } from '../button';
import { IconPcikerOverlayProps } from './types';

export const popoverTestId = process.env.NODE_ENV === 'test' ? 'picker-popover' : undefined;
export const pickerIconsTestId = process.env.NODE_ENV === 'test' ? 'picker-icons' : undefined;

// Note: Cannot modify popperConfig offset due to react-bootstrap implementation.
const IconPickerOverlay = ({
	id,
	targetRef,
	show,
	icons,
	renderCustomHeader,
	title = 'Icons',
	nextButtonText = 'Next',
	previousButtonText = 'Previous',
	searchAriaLabel = 'Search Icons',
	searchPlaceholder = 'Search Icons',
	currentPageAriaLabel = 'Page [[current]] of [[total]]',
	selectedIcon,
	currentPage,
	totalPages,
	offset,
	onHide,
	onFilter,
	onPreviousPage,
	onNextPage,
	onSelectIcon,
	rootClose = true
}: IconPcikerOverlayProps) => {
	let pageInfoAriaLabel;
	if (currentPage && totalPages) {
		pageInfoAriaLabel = currentPageAriaLabel.replace('[[current]]', currentPage.toString()).replace('[[total]]', totalPages.toString());
	}

	return (
		<Overlay show={show} target={targetRef?.current} placement="bottom" rootClose={rootClose} onHide={onHide}>
			<Popover id={id} data-testid={popoverTestId}>
				{renderCustomHeader ? (
					renderCustomHeader({ currentPage, totalPages, onFilter, onPreviousPage, onNextPage })
				) : (
					<Popover.Header style={{ padding: '0.5rem 0.8rem' }}>
						<h4 className="mt-0 text-center">{title}</h4>
						<Search aria-label={searchAriaLabel} size="sm" placeholder={searchPlaceholder} onChange={onFilter} groupClassName={'mb-2'} />
						<div className="clearfix text-center">
							<div aria-label={pageInfoAriaLabel} style={{ display: 'inline-block' }}>{`${currentPage} / ${totalPages}`}</div>
							<Button className="float-end" size="sm" variant="primary" disabled={currentPage === totalPages} onClick={onNextPage}>
								{nextButtonText}
							</Button>
							<Button className="float-start" size="sm" disabled={currentPage === 1} onClick={onPreviousPage}>
								{previousButtonText}
							</Button>
						</div>
					</Popover.Header>
				)}
				<Popover.Body style={{ padding: '0.5rem' }}>
					<Row className="m-0" data-testid={pickerIconsTestId}>
						{icons
							.filter((_icon, index) => {
								const min = (currentPage - 1) * offset;
								const max = min + offset;
								return index >= min && index < max;
							})
							.map(icon => {
								return (
									<Col key={icon.name} xs={3} className="mb-1 px-1">
										<Button
											title={icon.name.replace('-', ' ')}
											onClick={e => onSelectIcon(e, icon)}
											active={selectedIcon?.iconClass === icon.iconClass}
											aria-pressed={selectedIcon?.iconClass === icon.iconClass ? true : undefined}
											className="w-100"
											style={{ height: '2.5rem' }}>
											<Icon icon={icon.name as IconName} fixedWidth theme="regular" />
										</Button>{' '}
									</Col>
								);
							})}
					</Row>
				</Popover.Body>
			</Popover>
		</Overlay>
	);
};

IconPickerOverlay.displayName = 'IconPickerOverlay';

export default IconPickerOverlay;
