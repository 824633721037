import * as React from 'react';
import { TBodyProps } from './types';
import { TableGroupingContext } from './TableContext';
import { classList } from '../utility/utils';

export const TBody = React.forwardRef<HTMLTableSectionElement, TBodyProps>(({ divider, className, ...props }, ref) => {
	const { Provider } = TableGroupingContext;
	const classes = classList([divider && 'table-group-divider', className]);
	return (
		<Provider value={{ type: 'body' }}>
			<tbody ref={ref} className={classes} {...props} />
		</Provider>
	);
});
TBody.displayName = 'TBody';
