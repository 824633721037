import React from 'react';
import { fieldHasErrors } from '../../../../helpers';
import { useFormContext } from '../../form-element';

interface UseControlledFocusOnErrorProps {
	name?: string;
}

interface UseControlledFocusOnErrorData<TElement> {
	ref: React.RefObject<TElement>;
}

export const useControlledFocusOnError = <TElement extends HTMLElement>({ name }: UseControlledFocusOnErrorProps): UseControlledFocusOnErrorData<TElement> => {
	const controlledRef = React.useRef<TElement>();

	const { formState: { errors = {}, isSubmitting = false } = {} } = useFormContext();
	React.useLayoutEffect(() => {
		if (fieldHasErrors(name, errors) && Object.keys(errors)[0] === name) {
			controlledRef?.current?.focus();
		}
		// isSubmitting is necessary to update the state when we submit a form
		// If remove it, the element doesn't have the focus
	}, [errors, name, isSubmitting]);

	return { ref: controlledRef as React.RefObject<TElement> };
};
