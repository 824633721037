import * as React from 'react';
import { THeadProps } from './types';
import { TableGroupingContext } from './TableContext';
import { classList } from '../utility/utils';

export const THead = React.forwardRef<HTMLTableSectionElement, THeadProps>(({ sticky, stickyAlignment = 'top', variant, className, ...props }, ref) => {
	const { Provider } = TableGroupingContext;
	const classes = classList([
		sticky && typeof sticky === 'string' && `sticky-${sticky}-${stickyAlignment}`,
		sticky && typeof sticky === 'boolean' && `sticky-${stickyAlignment}`,
		variant && `table-${variant}`,
		className
	]);
	return (
		<Provider value={{ type: 'header' }}>
			<thead ref={ref} className={classes || undefined} {...props} />
		</Provider>
	);
});
THead.displayName = 'THead';
