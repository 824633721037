import React, { useRef } from 'react';
import { useRowSelectionColumn } from '../row-selection/hooks/useRowSelectionColumn';
import { ColumnDef as TanStackColumnDef } from '@tanstack/table-core/build/lib/types';
import { CellContext, HeaderContext, Row as TanStackRow, RowModel } from '@tanstack/react-table';
import { ColumnDef, UseColumnsData, UseColumnsProps, Row } from '../types';
import { getCellProps, getColumnHeaderProps, getRowProps } from '../utility/tableEntitiesConverters';

export const useColumns = <TData, TValue>({
	sortable,
	columns,
	rowSelection,
	rowSelectionOptions,
	expandableColumn,
	expandableColumnClassName,
	disableSelectAll,
	size
}: UseColumnsProps<TData, TValue>): UseColumnsData<TData, TValue> => {
	const { rowSelection: innerRowSelection = rowSelection, ...innerRowSelectionOptions } = rowSelectionOptions ?? {};

	if (sortable !== undefined && !sortable) {
		columns?.map(column => (column.enableSorting = true));
	}

	const getHeaderDefinition = (col: ColumnDef<TData, TValue>) => {
		if (!col.Header) return undefined;
		if (typeof col.Header === 'string') return col.Header;
		return (ctx: HeaderContext<TData, TValue>) => (typeof col.Header === 'string' ? col.Header : col.Header?.(getColumnHeaderProps(ctx)));
	};

	const getFooterDefinition = (col: ColumnDef<TData, TValue>) => {
		if (!col.Footer) return undefined;
		if (typeof col.Footer === 'string') return col.Footer;
		return (ctx: HeaderContext<TData, TValue>) => (typeof col.Footer === 'string' ? col.Footer : col.Footer?.(getColumnHeaderProps(ctx)));
	};

	const rowsRef = useRef<Row<TData>[]>([]);
	const rowModelRef = useRef<RowModel<TData>>();
	const getRows = (info: CellContext<TData, unknown>) => {
		const rowModel = info.table.getRowModel();
		if (rowModelRef.current !== rowModel) {
			rowsRef.current = rowModel.rows.map(getRowProps<TData>);
			rowModelRef.current = rowModel;
		}
		return rowsRef.current;
	};

	const formattedColumns: TanStackColumnDef<TData, TValue>[] =
		columns?.map(col => {
			const columnDef: TanStackColumnDef<TData, TValue> = {
				id: col.id,
				accessorKey: col.accessor ?? '',
				header: getHeaderDefinition(col),
				cell: (info: CellContext<TData, TValue>) => {
					if (col.Cell) {
						const rows = getRows(info);
						const CellComponent = col.Cell;
						return <CellComponent {...getCellProps(info, rows)} />;
					}

					return info.getValue();
				},
				footer: getFooterDefinition(col),
				filterFn: col.filter
					? (row: TanStackRow<TData>, columnId, filterValue) => {
							return col.filter?.(getRowProps(row), columnId as keyof TData, filterValue) ?? true;
					  }
					: `includesString`,
				enableColumnFilter: !!col.Filter,
				enableGlobalFilter: !col.disableGlobalFilter,
				meta: {
					className: col.className,
					style: col.style,
					dataAttributes: col.dataAttributes,
					originalColumnDef: col
				},
				enableSorting: col.enableSorting
			};
			return columnDef;
		}) ?? [];

	const originalColumnDefs = [...(columns ?? [])];

	if (expandableColumn) {
		formattedColumns.unshift({
			...expandableColumn,
			meta: {
				...expandableColumn.meta,
				originalColumnDef: {
					id: expandableColumn.id,
					Header: () => <>{expandableColumn.header}</>,
					className: expandableColumnClassName
				}
			}
		});
	}

	if (innerRowSelection) {
		const selectionColumn = useRowSelectionColumn<TData, TValue>({ ...innerRowSelectionOptions, size, disableSelectAll });
		formattedColumns.unshift({
			id: selectionColumn.id,
			accessorKey: '',
			header: getHeaderDefinition(selectionColumn),
			cell: (info: CellContext<TData, TValue>) => {
				if (selectionColumn.Cell) {
					const rows = getRows(info);
					return selectionColumn.Cell(getCellProps(info, rows));
				}

				return info.getValue();
			},
			meta: {
				originalColumnDef: selectionColumn
			}
		});
	}

	return {
		innerRowSelection,
		formattedColumns,
		originalColumnDefs
	};
};
