import React, { PropsWithChildren } from 'react';
import FormText from 'react-bootstrap/FormText';

type HelpblockProps = PropsWithChildren<{
	id?: string;
}>;

export default function Helpblock(props: HelpblockProps) {
	return props.id && props.children ? <FormText as="div" className="text-muted" {...props} /> : null;
}
